import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    FormControl,
    Select,
    MenuItem,
    InputLabel
} from '@material-ui/core';

function ImprimeOrcamento(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Imprimir orçamento - Parâmetros</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="logotipo-label">Logotipo</InputLabel>
                            <Select
                                name="logotipo"
                                labelid="logotipo-label"
                                size="small"
                                value={props.data.logotipo}
                                fullWidth
                                onChange={props.handleChangeField}
                            >
                                <MenuItem value="STK">STK</MenuItem>
                                <MenuItem value="Topografia">Stockchneider Topografia</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="valores-itens-label">Mostra valores nos itens?</InputLabel>
                            <Select
                                name="valores_itens"
                                labelid="valores-itens-label"
                                size="small"
                                value={props.data.valores_itens}
                                fullWidth
                                onChange={props.handleChangeField}
                            >
                                <MenuItem value="Sim">Sim</MenuItem>
                                <MenuItem value="Não">Não</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={props.confirm} autoFocus>Gerar</Button>
                <Button onClick={props.cancel}>Cancelar</Button>
            </DialogActions>
        </Dialog >
    )
}

export default ImprimeOrcamento;