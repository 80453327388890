import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableHeaderCell,
    TableCell,
    TablePagination
} from './styles';

import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function DataTable(props) {
    return (
        <Paper>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow key="datatable-header">
                            {props.columns.map((column, index) => (
                                <TableHeaderCell key={column.id + '-' + index} align={column.align}>{column.label}</TableHeaderCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.docs?.length > 0 && props.docs?.map((doc, index) => (
                            <TableRow key={doc._id + "-" + index}>
                                {props.columns.map((column) => {
                                    let value;

                                    if (column.type === 'hybrid') {
                                        value = doc;
                                    } else {
                                        value = doc[column.id];
                                    }

                                    return (
                                        <TableCell key={doc._id + "-" + index + "-" + column.id} align={column.align} style={{ minWidth: column.width + 'px' }}>
                                            {column.format ? column.format(value, index) : value}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                        {(!props.docs || props.docs.length <= 0) && (
                            <TableRow>
                                <TableCell colSpan={props.columns.length} align="center">
                                    Não existem registros para serem exibidos
                                </TableCell>
                            </TableRow>
                        )}
                        {props.footer}
                    </TableBody>
                </Table>
            </TableContainer>
            {(props.count > 0 &&
                <TablePagination
                    rowsPerPageOptions={[30, 50, 100]}
                    component="div"
                    count={props.count}
                    rowsPerPage={props.size}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeSize}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                    labelRowsPerPage="Registros por página"
                    nextIconButtonText="Próxima página"
                    backIconButtonText="Página anterior"
                />
            )}
        </Paper>
    )
}

export default DataTable;