function CPF(value) {
    //primeiro dígito
    if (!value) return false;

    let mult = 10;
    let soma = 0;

    for (let i = 0; i < 9; i++) {
        let aux = value.substr(i, 1);
        soma += aux * mult;
        mult--;
    }

    let primeiro_digito = soma % 11;

    if (primeiro_digito < 2) {
        primeiro_digito = 0;
    } else {
        primeiro_digito = 11 - primeiro_digito;
    }

    //segundo dígito
    mult = 11;
    soma = 0;

    for (let i = 0; i < 10; i++) {
        let aux = value.substr(i, 1);
        soma += aux * mult;
        mult--;
    }

    let segundo_digito = soma % 11;

    if (segundo_digito < 2) {
        segundo_digito = 0;
    } else {
        segundo_digito = 11 - segundo_digito;
    }

    let digitos = primeiro_digito + "" + segundo_digito;

    return digitos === value.substr(9, 2);
}

function CNPJ(value) {

    if (!value) return false;

    //primeiro dígito
    let mult = 5;
    let soma = 0;

    for (let i = 0; i < 12; i++) {
        let aux = value.substr(i, 1);
        soma += aux * mult;
        mult--;

        if (mult < 2) mult = 9;
    }

    let primeiro_digito = soma % 11;

    if (primeiro_digito < 2) {
        primeiro_digito = 0;
    } else {
        primeiro_digito = 11 - primeiro_digito;
    }

    //segundo dígito
    mult = 6;
    soma = 0;

    for (let i = 0; i < 13; i++) {
        let aux = value.substr(i, 1);
        soma += aux * mult;
        mult--;

        if (mult < 2) mult = 9;
    }

    let segundo_digito = soma % 11;

    if (segundo_digito < 2) {
        segundo_digito = 0;
    } else {
        segundo_digito = 11 - segundo_digito;
    }

    let digitos = primeiro_digito + "" + segundo_digito;

    return digitos === value.substr(12, 2);
}

export { CPF, CNPJ }