import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Link,
} from '@material-ui/core';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

export default function Anexos(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Anexos</DialogTitle>
            <DialogContent align="center">
                <List>
                    {props.data && props.data.map((anexo, index) => (
                        <React.Fragment key={index}>
                            <ListItem>
                                <ListItemIcon style={{ maxWidth: '150px' }}>
                                    <DescriptionOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText align="left">
                                    <Link href={anexo.url} target="_blank">
                                        {anexo.descricao ? anexo.descricao : anexo.name}
                                    </Link>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.confirm} color="primary" autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}