import React from 'react';
// import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import NumberField from "../../components/NumberField";

import moment from 'moment';
import 'devbox-linq';

function ImprimeOrcamento(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"lg"}
            fullWidth
        >
            <DialogContent>
                <Grid container spacing={3} id="imprimeOrcamento" style={{ padding: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK" height="80" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '30px' }}>
                        <Typography variant="h6" align="center" style={{ fontSize: '0.9rem' }}>
                            <b>PROPOSTA PARA PRESTAÇÃO DE SERVIÇOS - OS Nº {props.data.numero}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '30px' }}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.9rem' }}>
                            <b>{props.data.cliente?.nome}</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p>Prezado senhor,</p>
                        <br />
                        <p>Apresentamos para apreciação de V.S. proposta para prestação de serviços no(s) imóvel(is) abaixo:</p>
                        <br />
                        <Table size="small" style={{ width: 'auto' }}>
                            <TableHead>
                                <TableRow key="datatable-header">
                                    <TableCell key="matricula" align="center"><b>Matrícula</b></TableCell>
                                    <TableCell key="endereco" align="left"><b>Endereço</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.imovel?.map((imovel, index) => (
                                    <TableRow key={'imovel-' + index}>
                                        <TableCell align="center">{imovel.matricula}</TableCell>
                                        <TableCell align="left">{imovel.endereco?.endereco}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.85rem' }}>
                            <b>1. Descrição dos Serviços</b>
                        </Typography>
                        <Table size="small" style={{ width: 'auto' }}>
                            <TableHead>
                                <TableRow key="datatable-header">
                                    <TableCell key="item" align="center"><b>Item</b></TableCell>
                                    <TableCell key="objeto" align="left"><b>Objeto</b></TableCell>
                                    {props?.params?.valores_itens === 'Sim' && <TableCell key="valor" align="right"><b>Valor</b></TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.servicos?.map((servico, index) => (
                                    <TableRow key={'servico-' + index}>
                                        <TableCell align="center">{servico.item}</TableCell>
                                        <TableCell align="left">{servico.nome}<br />{servico.etiqueta}</TableCell>
                                        {props?.params?.valores_itens === 'Sim' &&
                                            <TableCell align="right">
                                                {servico.id !== servico.grupo &&
                                                    <NumberField
                                                        value={servico.total}
                                                        displayType="text"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale={2}
                                                        prefix="R$ "
                                                    />
                                                }
                                                {servico.id === servico.grupo &&
                                                    <>
                                                        <b>
                                                            <NumberField
                                                                value={servico.total}
                                                                displayType="text"
                                                                thousandSeparator="."
                                                                decimalSeparator=","
                                                                decimalScale={2}
                                                                fixedDecimalScale={2}
                                                                prefix="R$ "
                                                            />
                                                        </b>
                                                    </>
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                                <TableRow key="datatable-footer">
                                    <TableCell align="right" colSpan={props?.params?.valores_itens === 'Sim' ? 2 : 1}><b>Total</b></TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.servicos?.filter(x => x.id !== x.grupo).sum(x => x.total)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.85rem' }}>
                            <b>2. Despesas</b>
                        </Typography>
                        <Table size="small" style={{ width: 'auto' }}>
                            <TableHead>
                                <TableRow key="datatable-header">
                                    <TableCell key="vencimento" align="center"><b>Vencimento</b></TableCell>
                                    <TableCell key="descricao" align="left"><b>Descrição</b></TableCell>
                                    <TableCell key="valor" align="right"><b>Valor</b></TableCell>
                                    <TableCell key="pago" align="right"><b>Pago</b></TableCell>
                                    <TableCell key="saldo" align="right"><b>Saldo</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.financeiro?.filter(x => x.tipoConta === 'Pagar').map((financeiro, index) => (
                                    <TableRow key={'financeiro-' + index}>
                                        <TableCell align="center">{moment(financeiro.vencimento).format("DD/MM/YYYY - HH:mm")}</TableCell>
                                        <TableCell align="left">{financeiro.descricao}</TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                value={financeiro.valor}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                prefix="R$ "
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                value={financeiro.valor - financeiro.saldo}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                prefix="R$ "
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                value={financeiro.saldo}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                prefix="R$ "
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key="datatable-footer">
                                    <TableCell align="right" colSpan={2}><b>Total</b></TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.financeiro?.filter(x => x.tipoConta === 'Pagar').sum(x => x.valor)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.financeiro?.filter(x => x.tipoConta === 'Pagar').sum(x => x.valor - x.saldo)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.financeiro?.filter(x => x.tipoConta === 'Pagar').sum(x => x.saldo)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.85rem' }}>
                            <b>3. Resumo Financeiro</b>
                        </Typography>
                        <Table size="small" style={{ width: 'auto' }}>
                            <TableHead>
                                <TableRow key="datatable-header">
                                    <TableCell key="vencimento" align="center"><b>Vencimento</b></TableCell>
                                    <TableCell key="descricao" align="left"><b>Descrição</b></TableCell>
                                    <TableCell key="valor" align="right"><b>Valor</b></TableCell>
                                    <TableCell key="pago" align="right"><b>Pago</b></TableCell>
                                    <TableCell key="saldo" align="right"><b>Saldo</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.financeiro?.filter(x => x.tipoConta === 'Receber').map((financeiro, index) => (
                                    <TableRow key={'financeiro-' + index}>
                                        <TableCell align="center">{moment(financeiro.vencimento).format("DD/MM/YYYY - HH:mm")}</TableCell>
                                        <TableCell align="left">{financeiro.descricao}</TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                value={financeiro.valor}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                prefix="R$ "
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                value={financeiro.valor - financeiro.saldo}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                prefix="R$ "
                                            />
                                        </TableCell>
                                        <TableCell align="right">
                                            <NumberField
                                                value={financeiro.saldo}
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                decimalScale={2}
                                                fixedDecimalScale={2}
                                                prefix="R$ "
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow key="datatable-footer">
                                    <TableCell align="right" colSpan={2}><b>Total</b></TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.financeiro?.filter(x => x.tipoConta === 'Receber').sum(x => x.valor)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.financeiro?.filter(x => x.tipoConta === 'Receber').sum(x => x.valor - x.saldo)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                    <TableCell align="right"><b>
                                        <NumberField
                                            value={props.data.financeiro?.filter(x => x.tipoConta === 'Receber').sum(x => x.saldo)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                            prefix="R$ "
                                        />
                                    </b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.85rem' }}>
                            <b>4. Saldo em aberto</b>
                        </Typography>
                        <br />
                        <Typography variant="h6" align="left" style={{ fontSize: '0.80rem' }}>
                            Serviços + Despesas - Pagamentos efetuados: &nbsp;
                            <b>
                                <NumberField
                                    value={props.data.servicos?.filter(x => x.id !== x.grupo).sum(x => x.total) + props.data.financeiro?.filter(x => x.tipoConta === 'Pagar').sum(x => x.valor) - props.data.financeiro?.filter(x => x.tipoConta === 'Receber').sum(x => x.valor - x.saldo)}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    prefix="R$ "
                                />
                            </b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.85rem' }}>
                            <b>5. Prazos e condições</b>
                        </Typography>
                        <br />
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li>{props.data.condicao?.descricao}</li>
                            {props.data.condicao?.texto && props.data.condicao?.texto !== '' && props.data.condicao.texto?.split('\n').filter(x => x !== '').map((linha, index) => (
                                <li key={'linha-' + index}>{linha}</li>
                            ))}
                        </ul>
                        {/* <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li>{props.data.condicao?.descricao} - {props.data.condicao?.metodoPagamento};</li>
                            <li>Entrada no valor de 10% do Serviço;</li>
                            <li>Pagamento na conclusão e entrega dos mapas, projetos e requerimentos;</li>
                            <li>Neste orçamento não estão inclusas, despesas com reconhecimento de firma, certidões,
                            taxas de
                                    aprovação em Prefeitura e Emolumentos de Cartório;</li>
                            <li>Validade da proposta: 60 dias</li>
                        </ul> */}
                    </Grid>
                    <Grid item xs={12}>
                        <p>São Bento do Sul, {moment().format("DD [de] MMMM [de] YYYY")}</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.confirm &&
                    <ReactToPrint
                        trigger={() => {
                            return <Button color="primary" autoFocus>Imprimir</Button>
                        }}
                        content={() => document.getElementById("imprimeOrcamento")}
                    />}
                {props.cancel && <Button onClick={props.cancel}>Cancelar</Button>}
            </DialogActions>
        </Dialog >
    )
}

export default ImprimeOrcamento;