import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import {
    TextField,
    Grid,
    Snackbar
} from "@material-ui/core";

import {
    Alert
} from "@material-ui/lab";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"

import { Container, Paper } from "./styles";

import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import Instituicoes from "../../services/Instituicoes";
import { useAuth } from '../../AuthContext';

const EditaInstituicao = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [instituicao, setInstituicao] = useState({});
    const [loading, setLoading] = useState(true);
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })

    const saveForm = () => {
        setLoading(true);

        if (id === 'new') {
            Instituicoes.create(instituicao).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro realizado com sucesso"
                });

                setInstituicao(response);
                setLoading(false);

                window.location.href = '/instituicoes/' + response._id;
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });
            });
        } else {
            Instituicoes.update(instituicao._id, instituicao).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                Instituicoes.retrieve(id).then((response) => {
                    setInstituicao(response);
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
            });
        }
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleChangeField = (event) => {
        let updateInstituicao = { ...instituicao };
        updateInstituicao[event.target.name] = event.target.value;
        setInstituicao(updateInstituicao);
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: instituicao.nome,
            id: instituicao._id
        })
    }

    const handleDeleteInstituicao = (event) => {
        Instituicoes.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/instituicoes';
        }).catch(error => {
            setLoading(false);

            setRemove({
                open: false,
                name: '',
                id: ''
            });

            setResponseStatus({
                open: true,
                severity: "error",
                message: (error.message ? error.message : "Ocorreu um problema ao excluir o cadastro. Tente novamente")
            });
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar instituição");
        if (id !== 'new') {
            Instituicoes.retrieve(id).then((response) => {
                setInstituicao(response);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setLoading(false);
        }
    }, [id]);

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button'
        }
    ]

    return !loading ? (
        <Container>
            <Paper
                square
                style={{
                    padding: '25px'
                }}
            >
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="nome"
                                label="Nome"
                                size="small"
                                defaultValue={instituicao.nome}
                                fullWidth
                                onChange={handleChangeField}
                                inputRef={register({ required: "*Campo obrigatório" })}
                                error={!!errors.nome}
                                helperText={errors.nome?.message}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                name="cidade"
                                label="Cidade"
                                size="small"
                                defaultValue={instituicao.cidade}
                                fullWidth
                                onChange={handleChangeField}
                                inputRef={register({ required: "*Campo obrigatório" })}
                                error={!!errors.cidade}
                                helperText={errors.cidade?.message}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="estado"
                                label="Estado"
                                size="small"
                                defaultValue={instituicao.estado}
                                fullWidth
                                onChange={handleChangeField}
                                inputRef={register({ required: "*Campo obrigatório" })}
                                error={!!errors.estado}
                                helperText={errors.estado?.message}
                            />
                        </Grid>
                    </Grid>
                    {(user?.permissoes["Instituições-alterar"] || (user?.permissoes["Instituições-incluir"] && id === 'new')) && (
                        <ActionMenu actions={menuActions} />
                    )}
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteInstituicao}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </Container>
    ) :
        (loading && (
            <Loading open={loading} />
        ))
};

export default EditaInstituicao;