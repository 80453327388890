import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
    TextField,
    Select,
    FormControl,
    InputLabel,
    Tabs,
    Tab,
    Box,
    Grid,
    MenuItem,
    Snackbar,
    IconButton
} from "@material-ui/core";

import {
    Alert
} from "@material-ui/lab";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"

import { Container, Paper } from "./styles";

import { CurrencyField, IntegerField } from "../../components/MaskedFields";
import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import { ServicosComboBox } from "../../components/DataComboBox";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import Servicos from "../../services/Servicos";
import { useAuth } from '../../AuthContext';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditaServico = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const [tab, setTab] = useState(0);
    const { register, handleSubmit, errors } = useForm();
    const [servico, setServico] = useState(null);
    const [loading, setLoading] = useState(true);
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })

    const saveForm = () => {
        setLoading(true);
        let saveServico = { ...servico };
        let saveAdicionais = [];

        for (let index = 0; index < servico.adicionais.length; index++) {
            const adicional = servico.adicionais[index];
            saveAdicionais.push(adicional._id);
        }

        if (saveServico.adicionais.length > 0) {
            saveServico.adicionais = saveAdicionais;
        } else {
            saveServico.adicionais = saveAdicionais;
        }

        if (id === 'new') {
            Servicos.create(saveServico).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                window.location.href = '/servicos/' + response._id
            }).catch((error) => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
                setLoading(false)
            });
        } else {
            Servicos.update(servico._id, saveServico).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                Servicos.retrieve(servico._id).then((response) => {
                    setServico(response);
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
                setLoading(false)
            });
        }
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleChangeField = (event) => {
        let updateServico = { ...servico };
        updateServico[event.target.name] = event.target.value;
        setServico(updateServico);
    }

    const handleChangeServico = (event, servicoAdicional) => {
        if (servicoAdicional) {
            let updateServico = { ...servico };
            updateServico.adicionais.push(servicoAdicional);

            updateServico.valor = 0;

            for (let index = 0; index < updateServico.adicionais.length; index++) {
                const adicional = updateServico.adicionais[index];

                updateServico.valor += adicional.valor;
            }

            setServico(updateServico);
        }
    }

    const handleClickExcluirServico = (event, value) => {
        let updateServico = { ...servico };
        updateServico.adicionais.splice(servico.adicionais.findIndex(x => x._id === value), 1);

        updateServico.valor = 0;

        for (let index = 0; index < updateServico.adicionais.length; index++) {
            const adicional = updateServico.adicionais[index];

            updateServico.valor += adicional.valor;
        }

        setServico(updateServico);
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: servico.nome,
            id: servico._id
        })
    }

    const handleDeleteServico = (event) => {
        setLoading(true);

        Servicos.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/servicos';
        }).catch(error => {
            setLoading(false);

            setRemove({
                open: false,
                name: '',
                id: ''
            });

            setResponseStatus({
                open: true,
                severity: "error",
                message: (error.message ? error.message : "Ocorreu um problema ao excluir o cadastro. Tente novamente")
            });
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar serviço");

        if (id !== 'new') {
            Servicos.retrieve(id).then((response) => {
                if (response.adicionais.any()) {
                    response.valor = 0;

                    for (let index = 0; index < response.adicionais.length; index++) {
                        const adicional = response.adicionais[index];

                        response.valor += adicional.valor;
                    }
                }
                setServico(response);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setServico({
                anexo: 'Não',
                adicionais: []
            })
            setLoading(false);
        }
    }, [id]);

    const columnsAdicionais = [
        {
            id: 'nome',
            label: 'Serviço adicional',
            align: 'left'
        },
        {
            id: 'valor',
            label: 'Valor',
            align: 'right',
            format: (value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        },
        {
            id: '_id',
            label: 'Ações',
            align: 'center',
            format: (value) => (
                <IconButton size="small" aria-label="Excluir" onClick={e => handleClickExcluirServico(e, value)}>
                    <DeleteIcon color="secondary" />
                </IconButton>
            )
        },
    ]

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button'
        }
    ]

    return !loading ? (
        <Container>
            <Paper square>
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar pessoa"
                    >
                        <Tab label="Cadastro" {...a11yProps(0)} />
                        <Tab label="Itens" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="nome"
                                    label="Nome"
                                    size="small"
                                    defaultValue={servico.nome}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.nome}
                                    helperText={errors.nome?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    name="descricao"
                                    label="Descrição"
                                    multiline
                                    rows={4}
                                    defaultValue={servico.descricao}
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.descricao}
                                    helperText={errors.descricao?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <IntegerField
                                    name="alta"
                                    label="Prioridade alta (dias)"
                                    size="small"
                                    defaultValue={servico.alta}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.alta}
                                    helperText={errors.alta?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <IntegerField
                                    name="media"
                                    label="Prioridade média (dias)"
                                    size="small"
                                    defaultValue={servico.media}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.media}
                                    helperText={errors.media?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <IntegerField
                                    name="baixa"
                                    label="Prioridade baixa (dias)"
                                    size="small"
                                    defaultValue={servico.baixa}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.baixa}
                                    helperText={errors.baixa?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <IntegerField
                                    name="duracao"
                                    label="Duração (horas)"
                                    size="small"
                                    defaultValue={servico.duracao}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.duracao}
                                    helperText={errors.duracao?.message}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="anexo-label">Exige anexo</InputLabel>
                                    <Select
                                        name="anexo"
                                        labelid="anexo-label"
                                        size="small"
                                        value={servico.anexo}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="Sim" value="Sim">Sim</MenuItem>
                                        <MenuItem key="Não" value="Não">Não</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CurrencyField
                                    name="valor"
                                    label="Valor"
                                    size="small"
                                    defaultValue={servico.valor}
                                    onChange={handleChangeField}
                                    fullWidth
                                    error={!!errors.valor}
                                    helperText={errors.valor?.message}
                                    readOnly={servico.adicionais?.length > 0}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <ServicosComboBox
                            parent={id}
                            onChange={handleChangeServico}
                            style={{
                                marginBottom: '20px'
                            }}
                        />
                        <DataTable
                            columns={columnsAdicionais}
                            docs={servico.adicionais}
                        />
                    </TabPanel>
                    {(user?.permissoes["Serviços-alterar"] || (user?.permissoes["Serviços-incluir"] && id === 'new')) && (
                        <ActionMenu actions={menuActions} />
                    )}
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={5000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteServico}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </Container>
    ) :
        (loading && (
            <Loading open={loading} />
        ))
};

export default EditaServico;