const user = JSON.parse(localStorage.getItem("@stk/user"));

const list = (page = 0, limit = 30, filters = '') => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });

    })
}

const retrieve = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas/' + id;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const create = (pessoa) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas';
        let options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(pessoa)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const update = (id, pessoa) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas/' + id;
        let options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(pessoa)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const authorize = (id) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas/' + id + '/autorizar';
        let options = {
            method: 'POST',
            headers: headers
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const remove = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/pessoas/' + id;
        let options = {
            method: 'DELETE',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const searchZipCode = (zipCode) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/cep/' + zipCode;
        let options = {
            method: 'GET',
            headers: headers
        };

        timeoutPromise(3000, fetch(url, options))
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const timeoutPromise = (ms, promise) => {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error("Não foi possível buscar o CEP no serviço dos correios. Tente novamente ou digite o endereço"))
        }, ms);
        promise.then(
            (res) => {
                clearTimeout(timeoutId);
                resolve(res);
            },
            (err) => {
                clearTimeout(timeoutId);
                reject(err);
            }
        );
    })
}

const obj = { list, retrieve, create, update, remove, searchZipCode, authorize };

export default obj;