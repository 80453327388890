import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Button,
    Box,
    Tabs,
    Tab,
    TextField,
    Grid,
    Snackbar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Link,
    Divider,
    IconButton
} from "@material-ui/core";

import {
    Alert
} from "@material-ui/lab";

import { useForm } from "react-hook-form";
import { DropzoneDialogBase } from 'material-ui-dropzone';

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { Container, Paper } from "./styles";

import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import { DateField } from '../../components/MaskedFields';
import { OrdensServicoComboBox, InstituicoesComboBox, PessoasComboBox, UsuariosComboBox } from '../../components/DataComboBox';

import Protocolos from "../../services/Protocolos";
import { useAuth } from '../../AuthContext';
import moment from "moment";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditaProtocolo = (props) => {
    const { user } = useAuth();
    const [tab, setTab] = useState(0);
    const { id } = useParams();
    const { register, handleSubmit } = useForm();
    const [protocolo, setProtocolo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
   
    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [filterOrdemServico, setFilterOrdemServico] = useState("");

    const saveForm = () => {
        setLoading(true);

        if (id === 'new') {
            Protocolos.create(protocolo).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro realizado com sucesso"
                });

                Protocolos.retrieve(response._id).then(response => {
                    response.filesToUpload = [];
                    setProtocolo(response);
                    setFilterOrdemServico("&solicitante[_id]=" + response.cliente._id)
                    setLoading(false);

                    window.location.href = '/protocolos/' + response._id;
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        } else {
            Protocolos.update(protocolo._id, protocolo).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                Protocolos.retrieve(response._id).then(response => {
                    response.filesToUpload = [];
                    setProtocolo(response);
                    setFilterOrdemServico("&solicitante[_id]=" + response.cliente._id)
                    setLoading(false);

                    window.location.href = '/protocolos/' + response._id;
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        }
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };


    const handleChangeField = (event) => {
        let updateProtocolo = { ...protocolo };
        updateProtocolo[event.target.name] = event.target.value;
        setProtocolo(updateProtocolo);
    }

    const handleChangeDate = (date, field) => {
        if (date) {
            let updateProtocolo = { ...protocolo };
            updateProtocolo[field] = date.toISOString();

            if (field === "dataProtocolo") {
                updateProtocolo["dataPrazo"] = date.add(30, "days").toISOString();
            }

            setProtocolo(updateProtocolo);
        }
    }

    const handleChangeInstituicao = (event, instituicao) => {
        if (instituicao) {
            let updateProtocolo = { ...protocolo };
            updateProtocolo.instituicao = instituicao._id;
            setProtocolo(updateProtocolo);
        }
    }

    const handleChangeResponsavel = (event, responsavel) => {
        if (responsavel) {
            let updateProtocolo = { ...protocolo };
            updateProtocolo.responsavel = responsavel._id;
            setProtocolo(updateProtocolo);
        }
    }

    const handleChangeCliente = (event, cliente) => {
        if (cliente) {
            let updateProtocolo = { ...protocolo };
            updateProtocolo.cliente = cliente._id;
            setProtocolo(updateProtocolo);
            setFilterOrdemServico("&solicitante[_id]=" + cliente._id)
        }
    }

    const handleChangeOrdemServico = (event, ordemServico) => {
        if (ordemServico) {
            let updateProtocolo = { ...protocolo };
            updateProtocolo.ordemServico = ordemServico._id;
            setProtocolo(updateProtocolo);
        }
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: protocolo.numero,
            id: protocolo._id
        })
    }

    const handleDeleteProtocolo = (event) => {
        Protocolos.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/protocolos';
        }).catch(error => {
            console.log(error);
        });
    }

    const handleUploadFiles = (newFiles) => {
        let updateProtocolo = { ...protocolo };
        updateProtocolo.filesToUpload = updateProtocolo.filesToUpload.concat(newFiles);
        setProtocolo(updateProtocolo);
    }

    const handleDeleteFile = (file) => {
        let updateProtocolo = { ...protocolo };
        updateProtocolo.filesToUpload.splice(updateProtocolo.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setProtocolo(updateProtocolo);
    }

    const handleClickDeleteFile = (event, index) => {
        let updateProtocolo = { ...protocolo };
        updateProtocolo.anexos.splice(index, 1);
        setProtocolo(updateProtocolo);
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }


    const handleMensagem = (event) => {
        const { value } = event.target;
      
        if (event.keyCode === 13 && event.target.value !== "") {
            
            const novaMensagemData = {
                _id: false,
                data: new Date(),
                mensagem: value,
                responsavel: user?.nome,
              };
          
              setProtocolo((prevState) => ({
                ...prevState,
                mensagens: [...(prevState.mensagens || []), novaMensagemData],
              }));
          
              event.target.value = '';
        }
      };
      const handleDeleteMensagem = (index) => {
        setProtocolo((prevState) => {
          const newMensagens = [...prevState.mensagens];
          newMensagens.splice(index, 1);
          return {
            ...prevState,
            mensagens: newMensagens,
          };
        });
      };
      
      
    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button',
            disabled: !user?.permissoes["Protocolos-excluir"]
        }
    ]

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar protocolo");
        if (id === 'new') {
            setProtocolo({
                dataSolicitacao: new Date().toISOString(),
                situacao: 'Aberto',
                filesToUpload: []
            })

            setLoading(false);
        } else {
            Protocolos.retrieve(id).then((response) => {
                response.filesToUpload = [];
                setProtocolo(response);

                if (response.cliente) {
                    setFilterOrdemServico("&solicitante[_id]=" + response.cliente._id);
                }

                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [id]);


    return !loading ? (
        <Container>
            <Paper square>
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar protocolo"
                    >
                        <Tab label="Protocolo" {...a11yProps(0)} />
                        <Tab label="Anexos" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    name="numero"
                                    label="Número"
                                    size="small"
                                    defaultValue={protocolo.numero}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: true })}
                                    error={protocolo.numero === ""}
                                    helperText={protocolo.numero === "" && "é obrigatório"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DateField
                                    name="dataProtocolo"
                                    label="Data Protocolo"
                                    value={protocolo.dataProtocolo}
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DateField
                                    name="dataPrazo"
                                    label="Prazo"
                                    value={protocolo.dataPrazo}
                                    onChange={handleChangeDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DateField
                                    name="dataConclusao"
                                    label="Data Conclusão"
                                    value={protocolo.dataConclusao}
                                    onChange={handleChangeDate}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PessoasComboBox
                                    name="cliente"
                                    onChange={handleChangeCliente}
                                    defaultValue={protocolo.cliente &&
                                    {
                                        _id: protocolo.cliente._id,
                                        nome: protocolo.cliente.nome
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <OrdensServicoComboBox
                                    name="ordemServico"
                                    onChange={handleChangeOrdemServico}
                                    filter={filterOrdemServico}
                                    defaultValue={protocolo.ordemServico &&
                                    {
                                        _id: protocolo.ordemServico._id,
                                        numero: protocolo.ordemServico.numero,
                                        descricao: protocolo.ordemServico.descricao
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InstituicoesComboBox
                                    name="instituicao"
                                    onChange={handleChangeInstituicao}
                                    defaultValue={protocolo.instituicao &&
                                    {
                                        _id: protocolo.instituicao._id,
                                        nome: protocolo.instituicao.nome
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UsuariosComboBox
                                    name="responsavel"
                                    onChange={handleChangeResponsavel}
                                    defaultValue={protocolo.responsavel &&
                                    {
                                        _id: protocolo.responsavel._id,
                                        nome: protocolo.responsavel.nome
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="resumo"
                                    label="Resumo"
                                    size="small"
                                    defaultValue={protocolo.resumo}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    onChange={handleChangeField}
                                    inputRef={register({ required: true })}
                                    error={protocolo.resumo === ""}
                                    helperText={protocolo.resumo === "" && "é obrigatório"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="mensagens"
                                    name="mensagens"
                                    label="Mensagem"
                                    onKeyDown={handleMensagem}
                                        
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                        { protocolo.mensagens && protocolo.mensagens.map((mensagens, index) => (
                            <Fragment key={"mensagens-" + index}>
                                <ListItem key={index}>
                                    <ListItemText align="left"
                                        primary={mensagens.mensagem}
                                        secondary={mensagens.responsavel + ' (' + moment(mensagens.data).format("DD/MM/YYYY - HH:mm") + ')'}
                                    />
                                    <ListItemIcon>
                                        <IconButton title="Exclui Mensagem" onClick={e => handleDeleteMensagem(e, index)}>
                                            <DeleteIcon color="secondary" />
                                        </IconButton>
                                    </ListItemIcon>
                                   
                                </ListItem>
                                <Divider />
                            </Fragment>
                        ))}
                    </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Button variant="contained" color="primary" onClick={() => setOpenFileUpload(true)} style={{ marginBottom: '20px' }}>
                            Adicionar anexos
                        </Button>
                        <List>
                            {protocolo.anexos && protocolo.anexos.map((anexo, index) => (
                                <Fragment key={"anexo-" + index}>
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <DescriptionOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText align="center">
                                            <Link href={anexo.url} target="_blank">
                                                {anexo.descricao}
                                            </Link>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <IconButton onClick={e => handleClickDeleteFile(e, index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </TabPanel>
                    {(user?.permissoes["Protocolos-alterar"] || (user?.permissoes["Protocolos-incluir"] && id === 'new')) &&
                        <ActionMenu actions={menuActions} />
                    }
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <DropzoneDialogBase
                filesLimit={10}
                fileObjects={protocolo.filesToUpload}
                cancelButtonText="Fechar"
                submitButtonText="Ok"
                dialogTitle="Enviar arquivos"
                dropzoneText="Clique ou arraste os arquivos aqui (max 10)"
                maxFileSize={200000000}
                open={openFileUpload}
                onAdd={handleUploadFiles}
                onDelete={handleDeleteFile}
                onClose={() => {
                    setOpenFileUpload(false);
                }}
                onSave={() => {
                    setOpenFileUpload(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewText="Arquivos selecionados"
            />
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteProtocolo}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </Container>
    ) :
        (loading && (
            <Loading open={loading} />
        ))
};

export default EditaProtocolo;