import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Divider
} from '@material-ui/core';

import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';

import moment from 'moment';

function ImprimeCampo(props) {
    return (
        <Grid item xs={props.col}>
            <Typography variant="h6" align="left" style={{ fontSize: '0.6rem' }}>
                <b>{props.label ? props.label : ''}</b>
            </Typography>
            <p>{props.value ? props.value : ''}</p>
        </Grid>
    )
}

function FormataCPFCNPJ(value) {
    if (value) {
        if (value.length === 11) {
            return value.substr(0, 3) + "." + value.substr(3, 3) + "." + value.substr(6, 3) + "-" + value.substr(9, 2);
        } else {
            return value.substr(0, 2) + "." + value.substr(2, 3) + "." + value.substr(5, 3) + "/" + value.substr(8, 4) + "-" + value.substr(12, 2);
        }
    } else {
        return "";
    }
}

function LinhasEmBranco(props) {
    const linhas = [];
    const colunas = [];

    for (let index = 0; index < props.linhas; index++) {
        linhas.push(index);
    }

    for (let index = 0; index < props.colunas; index++) {
        colunas.push(index);
    }

    return (
        <TableBody>
            {linhas.map((linha) => (
                <TableRow key={'linha-' + linha}>
                    {colunas.map((coluna) => (
                        <TableCell key={'coluna-' + coluna} style={{ fontSize: '0.7rem' }}>&nbsp;</TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    )
}

function getCoordinates(path) {
    var coordinates = [];

    for (var i = 0; i < path.length; i++) {
        coordinates.push({
            lat: path.getAt(i).lat(),
            lng: path.getAt(i).lng()
        });
    }
    return JSON.stringify(coordinates);
}

function ImprimeFichaServico(props) {
    const map = useRef();
    const coordinates = useRef(null);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"lg"}
            fullWidth
        >
            <DialogContent>
                <Grid container spacing={3} id="imprimeFichaServico" style={{ padding: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK" height="80" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '30px', marginBottom: '30px' }}>
                        <Typography variant="h6" align="center" style={{ fontSize: '0.9rem' }}>
                            <b>ORDEM DE SERVIÇO Nº {props.data.numero}</b>
                        </Typography>
                    </Grid>
                    <ImprimeCampo col={3} label="Situação" value={props.data.situacao} />
                    <ImprimeCampo col={3} label="Data Solicitação" value={moment(props.data.data).format("DD/MM/YYYY")} />
                    <ImprimeCampo col={3} label="Prioridade" value={props.data.prioridade} />
                    <ImprimeCampo col={3} label="Prazo" value={props.data.prazo ? moment(props.data.prazo).format("DD/MM/YYYY") : ''} />

                    <ImprimeCampo col={5} label="Solicitante" value={props.data.cliente?.nome} />
                    <ImprimeCampo col={4} label="Responsável" value={props.data.responsavel?.nome} />
                    <ImprimeCampo col={3} label="Condição de Pagamento" value={props.data.condicao?.descricao} />

                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.9rem' }}>
                            <b>CLIENTE</b>
                            <Divider />
                        </Typography>
                    </Grid>

                    <ImprimeCampo col={6} label="Nome Completo" value={props.data.cliente?.nome} />
                    <ImprimeCampo col={6} label="Apelido / Nome Fantasia" value={props.data.cliente?.apelido} />

                    <ImprimeCampo col={4} label="Tipo" value={"Pessoa " + props.data.cliente?.tipo} />
                    <ImprimeCampo col={4} label={props.data.cliente?.tipo === 'Física' ? 'CPF' : 'CNPJ'} value={FormataCPFCNPJ(props.data.cliente?.cpf)} />
                    <ImprimeCampo col={4} label={props.data.cliente?.tipo === 'Física' ? 'RG' : ''} value={props.data.cliente?.rg} />

                    <ImprimeCampo col={4} label="Profissão" value={props.data.cliente?.profissao} />
                    <ImprimeCampo col={4} label="Nacionalidade" value={props.data.cliente?.nacionalidade} />
                    <ImprimeCampo col={4} />

                    <ImprimeCampo col={4} label="Estado Civil" value={props.data.cliente?.estado_civil} />
                    <ImprimeCampo col={4} label="Regime Casamento" value={props.data.cliente?.regime_casamento} />
                    <ImprimeCampo col={4} label="Cônjuge" value={props.data.cliente?.conjuge} />

                    {/* <ImprimeCampo col={4} label="CEP" value={props.data.cliente?.endereco?.cep} />
                    <ImprimeCampo col={4} label="Endereço" value={props.data.cliente?.endereco?.endereco} />
                    <ImprimeCampo col={4} label="Número" value={props.data.cliente?.endereco?.numero} />

                    <ImprimeCampo col={3} label="Complemento" value={props.data.cliente?.endereco?.complemento} />
                    <ImprimeCampo col={3} label="Bairro" value={props.data.cliente?.endereco?.bairro} />
                    <ImprimeCampo col={3} label="Cidade" value={props.data.cliente?.endereco?.cidade} />
                    <ImprimeCampo col={3} label="Estado" value={props.data.cliente?.endereco?.estado} /> */}

                    <ImprimeCampo col={6} label="E-mail" value={props.data.cliente?.email} />
                    <ImprimeCampo col={6} />

                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.6rem' }}>
                            <b>Telefones</b>
                        </Typography>
                        <Table size="small" style={{ width: 'auto' }}>
                            <TableBody>
                                {props.data.cliente?.telefones?.map((telefone, index) => (
                                    <TableRow key={'telefone-' + index}>
                                        <TableCell align="left">{telefone.descricao}</TableCell>
                                        <TableCell align="center">{telefone.numero}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    {props.data.imovel && props.data.imovel.length > 0 && props.index >= 0 &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6" align="left" style={{ fontSize: '0.9rem' }}>
                                    <b>IMÓVEL</b>
                                    <Divider />
                                </Typography>
                            </Grid>

                            <ImprimeCampo col={6} label="Matrícula/Transcrição" value={props.data.imovel && props.data.imovel[props.index].matricula} />
                            <ImprimeCampo col={6} label="Comarca" value={props.data.imovel && props.data.imovel[props.index].comarca} />

                            <ImprimeCampo col={12} label="Descrição" value={props.data.imovel && props.data.imovel[props.index].descricao} />

                            <ImprimeCampo col={3} label="Zoneamento" value={props.data.imovel && props.data.imovel[props.index].zoneamento} />
                            <ImprimeCampo col={3} label="Tipo de Imóvel" value={props.data.imovel && props.data.imovel[props.index].tipo} />
                            <ImprimeCampo col={3} label="Categoria" value={props.data.imovel && props.data.imovel[props.index].categoria} />
                            <ImprimeCampo col={3} label="Metragem" value={props.data.imovel && (props.data.imovel[props.index].metragem + "m²")} />

                            <ImprimeCampo col={4} label="Inscrição Imobiliária" value={props.data.imovel && props.data.imovel[props.index].inscricao_imobiliaria} />
                            <ImprimeCampo col={4} label="Número ITR" value={props.data.imovel && props.data.imovel[props.index].numero_itr} />
                            <ImprimeCampo col={4} label="Número CCIR" value={props.data.imovel && props.data.imovel[props.index].numero_ccir} />

                            <ImprimeCampo col={4} label="CEP" value={props.data.imovel && props.data.imovel[props.index].endereco?.cep} />
                            <ImprimeCampo col={4} label="Endereço" value={props.data.imovel && props.data.imovel[props.index].endereco?.endereco} />
                            <ImprimeCampo col={4} label="Número" value={props.data.imovel && props.data.imovel[props.index].endereco?.numero} />

                            <ImprimeCampo col={3} label="Complemento" value={props.data.imovel && props.data.imovel[props.index].endereco?.complemento} />
                            <ImprimeCampo col={3} label="Bairro" value={props.data.imovel && props.data.imovel[props.index].endereco?.bairro} />
                            <ImprimeCampo col={3} label="Cidade" value={props.data.imovel && props.data.imovel[props.index].endereco?.cidade} />
                            <ImprimeCampo col={3} label="Estado" value={props.data.imovel && props.data.imovel[props.index].endereco?.estado} />
                        </>
                    }

                    <Grid item xs={12}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.9rem' }}>
                            <b>SERVIÇO</b>
                            <Divider />
                        </Typography>
                    </Grid>

                    <ImprimeCampo col={12} label="Descrição" value={props.data.descricao} />

                    <Grid item xs={12}>
                        <Table size="small" style={{ width: '100%' }}>
                            <TableHead>
                                <TableRow key="datatable-header">
                                    <TableCell key="servico" align="left" style={{ fontSize: '0.7rem' }}><b>Serviço</b></TableCell>
                                    <TableCell key="data" align="center" style={{ fontSize: '0.7rem' }}><b>Data</b></TableCell>
                                    <TableCell key="responsavel" align="left" style={{ fontSize: '0.7rem' }}><b>Responsável</b></TableCell>
                                    <TableCell key="prazo" align="center" style={{ fontSize: '0.7rem' }}><b>Prazo</b></TableCell>
                                    <TableCell key="conclusao" align="center" style={{ fontSize: '0.7rem' }}><b>Conclusão</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.servicos?.map((servico, index) => (
                                    <TableRow key={'servico-' + index}>
                                        <TableCell align="left" style={{ fontSize: '0.7rem' }}>{servico.item}. {servico.nome}<br/>{servico.etiqueta}</TableCell>
                                        <TableCell align="center" style={{ fontSize: '0.7rem' }}>{servico.data && moment(servico.data).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell align="left" style={{ fontSize: '0.7rem' }}>{servico.responsavelItem?.nome}</TableCell>
                                        <TableCell align="center" style={{ fontSize: '0.7rem' }}>{servico.prazo && moment(servico.prazo).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell align="center" style={{ fontSize: '0.7rem' }}>{servico.conclusao && moment(servico.conclusao).format("DD/MM/YYYY")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    {props.data.imovel && props.data.imovel.length > 0 && props.index >= 0 && props.data.imovel[props.index].mapa && props.data.imovel[props.index].mapa.marcador &&
                        <Grid item xs={12}>
                            <LoadScript
                                googleMapsApiKey="AIzaSyAymdqUYpofthT5ixv2Y9Bit58a2yb-Hy0"
                                libraries={['drawing', 'places']}
                            >
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '300px' }}
                                    center={JSON.parse(props.data.imovel[props.index].mapa.marcador)}
                                    zoom={16}
                                    onLoad={(ref) => {
                                        map.current = ref
                                    }}
                                >
                                    <Marker
                                        editable
                                        draggable
                                        position={JSON.parse(props.data.imovel[props.index].mapa.marcador)}
                                    />

                                    {props.data.imovel[props.index].mapa?.coordenadas && props.data.imovel[props.index].mapa?.coordenadas.length > 0 && (
                                        <Polygon
                                            paths={JSON.parse(props.data.imovel[props.index].mapa.coordenadas)}
                                            editable
                                            draggable
                                            onLoad={(polygon) => {
                                                polygon.getPaths().forEach(function (newPath, index) {
                                                    window.google.maps.event.addListener(newPath, 'insert_at', function () {
                                                        coordinates.current = getCoordinates(newPath);
                                                    });

                                                    window.google.maps.event.addListener(newPath, 'remove_at', function () {
                                                        coordinates.current = getCoordinates(newPath);
                                                    });

                                                    window.google.maps.event.addListener(newPath, 'set_at', function () {
                                                        coordinates.current = getCoordinates(newPath);
                                                    });
                                                });
                                            }}
                                        />
                                    )}
                                </GoogleMap>
                            </LoadScript>
                        </Grid>
                    }
                    <Grid item xs={12}>
                    <Table size="small" style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow key="datatable-header">
                                <TableCell key="data" align="center" style={{ fontSize: '0.7rem' }}><b>Data</b></TableCell>
                                <TableCell key="saida" align="center" style={{ fontSize: '0.7rem' }}><b>Hora Saída</b></TableCell>
                                <TableCell key="chegada" align="center" style={{ fontSize: '0.7rem' }}><b>Hora Chegada</b></TableCell>
                                <TableCell key="almoco" align="center" style={{ fontSize: '0.7rem' }}><b>Almoço</b></TableCell>
                                <TableCell key="servico" align="center" style={{ fontSize: '0.7rem' }}><b>Hora Término Serviço</b></TableCell>
                                <TableCell key="retorno" align="center" style={{ fontSize: '0.7rem' }}><b>Hora Retorno</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <LinhasEmBranco colunas={6} linhas={20} />
                    </Table>
                </Grid>
                <Grid item xs={12} style={{ height: '50px' }} />
                <Grid item xs={12}>
                    <Divider />
                    <Typography align="center">
                        Assinatura
                        </Typography>
                </Grid>
                </Grid>
            </DialogContent>
        <DialogActions>
            {props.confirm &&
                <ReactToPrint
                    trigger={() => {
                        return <Button color="primary" autoFocus>Imprimir</Button>
                    }}
                    content={() => document.getElementById("imprimeFichaServico")}
                />}
            {props.cancel && <Button onClick={props.cancel}>Cancelar</Button>}
        </DialogActions>
        </Dialog >
    )
}

export default ImprimeFichaServico;