import React, { useState, useEffect } from 'react';
import {
    IconButton,
    Button,
    Box,
    useTheme
} from '@material-ui/core';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ActionMenu from '../../components/ActionMenu';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import { InstituicoesComboBox } from '../../components/DataComboBox';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DotIcon from '@material-ui/icons/FiberManualRecord';

import { green } from '@material-ui/core/colors';

import Protocolos from '../../services/Protocolos';
import { useAuth } from '../../AuthContext';

const ListaProtocolos = (props) => {
    const theme = useTheme();
    const { user } = useAuth();
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: '&situacao[$in]=Aberto'
    });

    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        protocolDateFrom: null,
        protocolDateTo: null,
        finishDateFrom: null,
        finishDateTo: null,
        status: ['Aberto'],
        instituicao: null
    })
    
    const [toggleInstituicao, setToggleInstituicao] = useState(true);

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const [conclui, setConclui] = useState({
        open: false,
        name: '',
        id: ''
    });

    const Listar = (page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        Protocolos.list(page, size, filter).then(protocolos => {
            setData({
                isLoading: false,
                docs: protocolos.docs,
                page: protocolos.page - 1,
                size: protocolos.limit,
                count: protocolos.totalDocs,
                filters: filter
            });
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Protocolos");
        Listar(data.page, data.size, data.filters);
    }, [data.page, data.size, data.filters]);

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleClickConcluir = (event, id, name) => {
        setConclui({
            open: true,
            name: name,
            id: id
        })
    }

    const handleConcluiProtocolo = (event) => {
        Protocolos.update(conclui.id, {
            dataConclusao: new Date().toISOString(),
            situacao: 'Concluído'
        }).then(response => {
            setConclui({
                open: false,
                name: '',
                id: ''
            });

            Listar(data.page, data.size, data.filters);
        });
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeleteProtocolo = (event) => {
        Protocolos.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            Listar(data.page, data.size, data.filters);
        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'cliente',
            label: 'Cliente',
            align: 'left',
            format: (cliente) => cliente?.nome
        },
        {
            id: 'dataSolicitacao',
            label: 'Data Solicitação',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'dataProtocolo',
            label: 'Data Protocolo',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'dataPrazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'dataConclusao',
            label: 'Data Conclusão',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (doc.dataConclusao ?
                new Date(doc.dataConclusao).toLocaleDateString('pt-BR')
                :
                <Button
                    size="small"
                    variant="contained"
                    onClick={e => handleClickConcluir(e, doc._id, doc.resumo)}
                    style={{ color: 'white', backgroundColor: green[700] }}
                    disabled={!user?.permissoes["Protocolos-alterar"]}
                >
                    Concluir
                </Button>
            )
        },
        {
            id: 'resumo',
            label: 'Resumo',
            align: 'left'
        },
        {
            id: 'situacao',
            label: 'Situação',
            align: 'left',
            width: 150,
            format: (value) => (
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    {value === "Aberto" && <DotIcon color="secondary" />}
                    {value === "Concluído" && <DotIcon style={{ color: theme.palette.success.main }} />}
                    <span>{value}</span>
                </Box>
            )
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <div style={{ minWidth: '100px' }}>
                    <IconButton aria-label="Editar" href={'/protocolos/' + doc._id} color="primary">
                        {user?.permissoes["Protocolos-alterar"] ? (
                            <EditIcon color="primary" />
                        ) : (
                            <VisibilityIcon color="primary" />
                        )}
                    </IconButton>
                    {user?.permissoes["Protocolos-excluir"] && (
                        <IconButton aria-label="Excluir" onClick={e => handleClickDelete(e, doc._id, doc.resumo)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            )
        }
    ];

    const handleKeyDownSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][cliente.nome][$regex]=" + filters.search;
            qs += "&$or[0][cliente.nome][$options]=gi";
            qs += "&$or[1][numero][$regex]=" + filters.search;
            qs += "&$or[1][numero][$options]=gi";
        }

        if (filters.dateFrom) qs += '&dataSolicitacao[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&dataSolicitacao[$lte]=' + filters.dateTo;
        if (filters.protocolDateFrom) qs += '&dataProtocolo[$gte]=' + filters.protocolDateFrom;
        if (filters.protocolDateTo) qs += '&dataProtocolo[$lte]=' + filters.protocolDateTo;
        if (filters.dueDateFrom) qs += '&dataPrazo[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&dataPrazo[$lte]=' + filters.dueDateTo;
        if (filters.finishDateFrom) qs += '&dataConclusao[$gte]=' + filters.finishDateFrom;
        if (filters.finishDateTo) qs += '&dataConclusao[$lte]=' + filters.finishDateTo;
        if (filters.status.length > 0) qs += "&situacao[$in]=" + filters.status.join();
        if (filters.instituicao) qs += "&instituicao=" + filters.instituicao;

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            protocolDateFrom: null,
            protocolDateTo: null,
            finishDateFrom: null,
            finishDateTo: null,
            status: ['Aberto'],
            instituicao: null
        });
        
        setToggleInstituicao(!toggleInstituicao);
        
        setData({ ...data, filters: '&situacao[$in]=Aberto' })
    }

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyDownSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data Solicitação de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data Solicitação até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'protocolDateFrom',
            name: 'protocolDateFrom',
            label: 'Data Protocolo de',
            value: filters.protocolDateFrom,
            onChange: (value) => { setFilters({ ...filters, protocolDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'protocolDateTo',
            name: 'protocolDateTo',
            label: 'Data Protocolo até',
            value: filters.protocolDateTo,
            onChange: (value) => { setFilters({ ...filters, protocolDateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Prazo de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Prazo até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'date',
            id: 'finishDateFrom',
            name: 'finishDateFrom',
            label: 'Data Conclusão de',
            value: filters.finishDateFrom,
            onChange: (value) => { setFilters({ ...filters, finishDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'finishDateTo',
            name: 'finishDateTo',
            label: 'Data Conclusão até',
            value: filters.finishDateTo,
            onChange: (value) => { setFilters({ ...filters, finishDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'select',
            id: 'status',
            name: 'status',
            label: 'Situação',
            value: filters.status,
            options: ['Aberto', 'Concluído'],
            onChange: (e) => { setFilters({ ...filters, status: e.target.value }) }
        },
        {
            type: 'component',
            id: 'instituicao',
            name: 'instituicao',
            label: 'Instituição',
            component: <InstituicoesComboBox key={toggleInstituicao} onChange={(event, instituicao) => { setFilters({ ...filters, instituicao: instituicao?._id }) }}/>
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            {!isLoading && docs ? (
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />) :
                isLoading && <Loading open={isLoading} />
            }
            {user?.permissoes["Protocolos-incluir"] && (
                <ActionMenu
                    actions={[
                        {
                            icon: <IconButton href="/protocolos/new"><DescriptionOutlinedIcon /></IconButton>,
                            title: 'Incluir',
                            type: 'button'
                        }
                    ]}
                />
            )}
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteProtocolo}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
            <ConfirmationDialog
                title="Deseja concluir o protocolo?"
                confirmTitle="Sim"
                confirm={handleConcluiProtocolo}
                cancelTitle="Não"
                cancel={() => {
                    setConclui({
                        ...conclui,
                        open: false
                    })
                }}
                open={conclui.open}
                onClose={() => {
                    setConclui({
                        ...remove,
                        open: false
                    })
                }}
            >{conclui.name}</ConfirmationDialog>
        </>

    )
}

export default ListaProtocolos;