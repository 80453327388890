import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ListaPessoas from "./pages/ListaPessoas";
import EditaPessoa from "./pages/EditaPessoa";
import ListaImoveis from "./pages/ListaImoveis";
import EditaImovel from "./pages/EditaImovel";
import ListaServicos from "./pages/ListaServicos";
import EditaServico from "./pages/EditaServico";
import ListaCondicoes from "./pages/ListaCondicoes";
import EditaCondicao from "./pages/EditaCondicao";
import ListaInstituicoes from "./pages/ListaInstituicoes";
import EditaInstituicao from "./pages/EditaInstituicao";
import ListaProtocolos from "./pages/ListaProtocolos";
import EditaProtocolo from "./pages/EditaProtocolo";
import ListaOrdensServico from "./pages/ListaOrdensServico";
import EditaOrdemServico from "./pages/EditaOrdemServico";
import ListaContasReceber from "./pages/ListaContasReceber";
import EditaContasReceber from "./pages/EditaContasReceber";
import ListaContasPagar from "./pages/ListaContasPagar";
import EditaContasPagar from "./pages/EditaContasPagar";
import ListaUsuarios from "./pages/ListaUsuarios";
import EditaUsuario from "./pages/EditaUsuario";
import ListaTarefas from "./pages/ListaTarefas";
import ListaSolicitacoes from "./pages/ListaSolicitacoes";
import RelatorioAtividadesExecutadas from "./pages/RelatorioAtividadesExecutadas";
import RelatorioUltimasMensagens from "./pages/RelatorioUltimasMensagens";
import RelatorioTodasMensagens from "./pages/RelatorioTodasMensagens";
import RelatorioConclusaoOs from "./pages/RelatorioConclusaoOs";
import RelatorioDespesas from "./pages/RelatorioDespesas";
import FluxoCaixa from "./pages/FluxoCaixa";
import MainContainer from './components/MainContainer';
import { useAuth } from "./AuthContext";

const Routes = () => {
	const { authenticated } = useAuth()

	return (
		<BrowserRouter>
			<Switch>
				<Route exact path="/pessoas">
					{authenticated === true && <MainContainer><ListaPessoas /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/pessoas/:id">
					{authenticated === true && <MainContainer><EditaPessoa /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/imoveis">
					{authenticated === true && <MainContainer><ListaImoveis /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/imoveis/:id">
					{authenticated === true && <MainContainer><EditaImovel /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/servicos">
					{authenticated === true && <MainContainer><ListaServicos /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/servicos/:id">
					{authenticated === true && <MainContainer><EditaServico /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/condicoes">
					{authenticated === true && <MainContainer><ListaCondicoes /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/condicoes/:id">
					{authenticated === true && <MainContainer><EditaCondicao /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/instituicoes">
					{authenticated === true && <MainContainer><ListaInstituicoes /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/instituicoes/:id">
					{authenticated === true && <MainContainer><EditaInstituicao /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/os">
					{authenticated === true && <MainContainer><ListaOrdensServico /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/os/:id">
					{authenticated === true && <MainContainer><EditaOrdemServico /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/protocolos">
					{authenticated === true && <MainContainer><ListaProtocolos /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/protocolos/:id">
					{authenticated === true && <MainContainer><EditaProtocolo /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/receber">
					{authenticated === true && <MainContainer><ListaContasReceber /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/receber/:id">
					{authenticated === true && <MainContainer><EditaContasReceber /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/pagar">
					{authenticated === true && <MainContainer><ListaContasPagar /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/pagar/:id">
					{authenticated === true && <MainContainer><EditaContasPagar /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/usuarios">
					{authenticated === true && <MainContainer><ListaUsuarios /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/usuarios/:id">
					{authenticated === true && <MainContainer><EditaUsuario /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/tarefas">
					{authenticated === true && <MainContainer><ListaTarefas /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/solicitacoes">
					{authenticated === true && <MainContainer><ListaSolicitacoes /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/relatorios/atividades-periodo">
					{authenticated === true && <MainContainer><RelatorioAtividadesExecutadas /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/relatorios/ultimas-mensagens-os">
					{authenticated === true && <MainContainer><RelatorioUltimasMensagens /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/relatorios/todas-mensagens-os">
					{authenticated === true && <MainContainer><RelatorioTodasMensagens /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/relatorios/conclusao-os">
					{authenticated === true && <MainContainer><RelatorioConclusaoOs /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/relatorios/despesas">
					{authenticated === true && <MainContainer><RelatorioDespesas /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/financeiro/fluxo-de-caixa">
					{authenticated === true && <MainContainer><FluxoCaixa /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route exact path="/">
					{authenticated === true && <MainContainer><Dashboard /></MainContainer>}
					{authenticated === false && <Login />}
				</Route>
				<Route path="*">
					<Redirect to="/" />
				</Route>
			</Switch>
		</BrowserRouter>
	)
};
export default Routes;