import React from 'react';
import { Container, Accordion, Menu, Link } from './styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function MenuItem(props) {
    return (
        props.hasChildren ? (
        <Accordion>
            <Menu
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {props.title}
            </Menu>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
        ) : (
            <Container>
                <Link href={props.to}>{props.title}</Link>
            </Container>
        )
    )
}