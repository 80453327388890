import React, { useEffect, useState, useCallback } from 'react';
import MenuItem from '../MenuItem';
import { Menu, SubMenu, SubMenuItem, Link } from './styles';

import Menus from '../../services/Menus';
import { useAuth } from '../../AuthContext';

export default function Drawer(props) {
    const { user } = useAuth()
    const [menus, setMenus] = useState(null);

    const listaMenus = useCallback(() => {
        Menus.list("?ativo=true").then(result => {
            setMenus(result.docs);
        }).catch(error => {
            console.log(error);
        })
    },[])

    useEffect(() => {
        listaMenus()
    }, [listaMenus])

    const subMenu = (pai) => (
        <SubMenu>
            {menus?.filter(x => x.pai === pai && user?.permissoes[x.titulo.replace(/[" "]/gi, "-")+'-visualizar'] === true).map((menu, index) => (
                <SubMenuItem key={"submenu-" + index}>
                    <Link href={menu.caminho}>{menu.titulo}</Link>
                </SubMenuItem>
            ))}
        </SubMenu>
    )

    return (
        <Menu>
            {menus?.filter(x => x.pai === null && user?.permissoes[x.titulo] === true).map((menu, index) => (
                <MenuItem to={menu.caminho} title={menu.titulo} hasChildren={menu.caminho === null} key={"menu-" + index}>
                    {subMenu(menu._id)}
                </MenuItem>
            ))}
        </Menu>
    )
}