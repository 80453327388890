import React, { Fragment } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    Divider,
    Link
} from '@material-ui/core';
import moment from "moment";
import { DropzoneAreaBase } from 'material-ui-dropzone';

import DeleteIcon from "@material-ui/icons/Delete"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { DateField } from '../../components/MaskedFields';
import { InstituicoesComboBox, UsuariosComboBox } from '../../components/DataComboBox';

function EditaProtocoloOS(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Editar protocolo - {props.data.resumo}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            name="numero"
                            label="Número"
                            defaultValue={props.data.numero}
                            onChange={props.handleChangeField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateField
                            name="dataProtocolo"
                            label="Data Protocolo"
                            value={props.data.dataProtocolo}
                            onChange={props.handleChangeDateField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateField
                            name="dataPrazo"
                            label="Prazo"
                            value={props.data.dataPrazo}
                            onChange={props.handleChangeDateField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InstituicoesComboBox
                            name="instituicao"
                            onChange={props.handleChangeInstituicao}
                            defaultValue={props.data.instituicao &&
                            {
                                _id: props.data.instituicao._id,
                                nome: props.data.instituicao.nome
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <UsuariosComboBox
                            name="responsavel"
                            onChange={props.handleChangeResponsavel}
                            defaultValue={props.data.responsavel &&
                            {
                                _id: props.data.responsavel._id,
                                nome: props.data.responsavel.nome
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="resumo"
                            label="Resumo"
                            size="small"
                            defaultValue={props.data.resumo}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                            onChange={props.handleChangeField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="mensagens"
                            name="mensagens"
                            label="Mensagem"
                            onKeyDown={props.handleKeymessagem}
                            fullWidth
                         />
                    </Grid>
                            <Grid item xs={12}>
                        {props.data.mensagens && props.data.mensagens.map((mensagem, index) => (
                            <Fragment key={"mensagem-" + index}>
                                <ListItem key={index}>
                                    <ListItemText align="left"
                                        primary={mensagem.mensagem}
                                        secondary={mensagem.responsavel + ' (' + moment(mensagem.data).format("DD/MM/YYYY - HH:mm") + ')'}
                                    />
                                   
                                    <ListItemIcon>
                                        <IconButton title="Exclui Mensagem" onClick={e => props.handleDeleteMensagem(e, index)}>
                                            <DeleteIcon color="secondary" />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <DropzoneAreaBase
                            filesLimit={10}
                            fileObjects={props.data.filesToUpload}
                            dropzoneText="Clique ou arraste os arquivos aqui (max 10)"
                            maxFileSize={200000000}
                            onAdd={props.handleUploadFile}
                            onDelete={props.handleDeleteFile}
                            showPreviews={false}
                            showFileNamesInPreview={false}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewText="Arquivos selecionados"
                        />
                    </Grid>
                  
                    <Grid item xs={12}>
                        <List>

                            {props.data.anexos && props.data.anexos.map((anexo, index) => (
                                <Fragment key={"anexo-" + index}>
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <DescriptionOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText align="center">
                                            <Link href={anexo.url} target="_blank">
                                                {anexo.descricao}
                                            </Link>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <IconButton onClick={e => props.handleClickDeleteFile(e, index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.confirm} color="primary" autoFocus>Ok</Button>
                <Button onClick={props.cancel}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditaProtocoloOS;