import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import {
    TextField,
    Tabs,
    Tab,
    Box,
    Grid,
    MenuItem,
    Snackbar,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemAvatar,
    Avatar,
    Divider,
    Button,
    Link
} from "@material-ui/core";

import {
    Alert,
    Autocomplete
} from "@material-ui/lab";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { useForm } from "react-hook-form";
import { DropzoneDialogBase } from 'material-ui-dropzone';

import { Container, Paper } from "./styles";

import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { MaskedField } from '../../components/MaskedFields';

import { CPF, CNPJ } from '../../Validations';

import Pessoas from "../../services/Pessoas";
import { useAuth } from "../../AuthContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditaPessoa = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const [tab, setTab] = useState(0);
    const [pessoa, setPessoa] = useState({});
    const [loading, setLoading] = useState(true);
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [listaResponsaveis, setListaResponsaveis] = useState({
        open: false,
        options: [],
        loading: false
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [openFileUpload, setOpenFileUpload] = useState(false);

    const { register, handleSubmit, setValue, setError, clearErrors, errors } = useForm();

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleListResponsaveis = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][nome][$regex]=" + event.target.value;
            filters += "&$or[0][nome][$options]=gi";
        }

        setListaResponsaveis({ ...listaResponsaveis, loading: true });

        Pessoas.list(filters).then((response) => {
            let responsaveis = [];

            for (let index = 0; index < response.docs.length; index++) {
                const responsavel = response.docs[index];
                responsaveis.push(responsavel);
            }

            setListaResponsaveis({
                open: true,
                options: responsaveis,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleClickRandomPasscode = (event) => {
        setLoading(true);
        Pessoas.authorize(id).then(response => {
            setResponseStatus({
                open: true,
                severity: "success",
                message: "Acesso liberado ao cliente"
            });

            response.filesToUpload = [];
            setPessoa(response);
            setLoading(false);
        }).catch((error) => {
            setResponseStatus({
                open: true,
                severity: "error",
                message: (error.message ? error.message : "Ocorreu um problema ao liberar o acesso ao cliente. Tente novamente")
            });
            setLoading(false);
        });
    }

    const saveForm = () => {
        setLoading(true);

        if (id === 'new') {
            Pessoas.create(pessoa).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro realizado com sucesso"
                });

                window.location.href = '/pessoas/' + response._id;
            }).catch((error) => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        } else {
            Pessoas.update(pessoa._id, pessoa).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });
                response.filesToUpload = [];
                setPessoa(response);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
            });
        }
    }

    const handleChangeField = (event, aux, obj, index) => {
        let updatePessoa = { ...pessoa };

        if (obj) {
            let field = event.target.name.split('-')[event.target.name.split('-').length - 1];

            if (index >= 0) {
                updatePessoa[obj][index][field] = event.target.value;
            } else {
                updatePessoa[obj][field] = event.target.value;
            }
        } else {
            let field = event.target.name;

            if (field === 'tipo') {
                updatePessoa.cpf = '';
            }

            updatePessoa[field] = event.target.value;
        }

        setPessoa(updatePessoa);
    }

    const handleChangeResponsavel = (event, responsavel) => {
        if (responsavel) {
            let updatePessoa = { ...pessoa };
            updatePessoa.responsaveis.push({
                id: responsavel._id,
                nome: responsavel.nome,
                documento: responsavel.cpf,
                relacao: ''
            });

            setPessoa(updatePessoa);

            document.getElementById("adiciona-responsavel").value = ''
        }
    }

    const handleClickAddContact = (event) => {
        let updatePessoa = { ...pessoa };
        updatePessoa.telefones.push({
            descricao: document.getElementById("contato-descricao").value,
            numero: document.getElementById("contato-numero").value
        })

        setPessoa(updatePessoa);

        document.getElementById("contato-descricao").value = '';
        document.getElementById("contato-numero").value = '';
    }

    const handleClickDelContact = (event, index) => {
        let updatePessoa = { ...pessoa };
        let newTelefones = pessoa.telefones.filter(x => x.descricao !== pessoa.telefones[index].descricao);

        updatePessoa.telefones = newTelefones;

        setPessoa(updatePessoa);

        for (let n = 0; n < newTelefones.length; n++) {
            const telefone = newTelefones[n];
            document.getElementById("telefone[" + n + "]-descricao").value = telefone.descricao;
            document.getElementById("telefone[" + n + "]-numero").value = telefone.numero;
        }
    }

    const handleClickAddNote = (event) => {
        let updatePessoa = { ...pessoa };
        updatePessoa.observacoes.push({
            data: new Date().toISOString(),
            observacao: document.getElementById("adicionar-observacao").value,
            responsavel: user?.nome
        })

        setPessoa(updatePessoa);

        document.getElementById("adicionar-observacao").value = '';
    }

    const handleUploadFiles = (newFiles) => {
        let updatePessoa = { ...pessoa };
        updatePessoa.filesToUpload = updatePessoa.filesToUpload.concat(newFiles);
        setPessoa(updatePessoa);
    }

    const handleDeleteFile = (file) => {
        let updatePessoa = { ...pessoa };
        updatePessoa.filesToUpload.splice(updatePessoa.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setPessoa(updatePessoa);
    }

    const handleClickDeleteFile = (event, index) => {
        let updatePessoa = { ...pessoa };
        updatePessoa.documentos.splice(index, 1);
        setPessoa(updatePessoa);
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: pessoa.nome,
            id: pessoa._id
        })
    }

    const handleDeletePessoa = (event) => {
        Pessoas.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/pessoas';
        }).catch(error => {
            console.log(error);
        });
    }

    const validaCPFCNPJ = useCallback((value) => {
        clearErrors('cpf');

        if (value.length <= 11) {
            let ret = CPF(value);

            if (!ret) {
                setError("cpf", {
                    type: "manual",
                    message: "CPF Inválido"
                })
            }

            return ret;
        } else if (value.length > 11) {
            let ret = CNPJ(value);

            if (!ret) {
                setError("cpf", {
                    type: "manual",
                    message: "CNPJ Inválido"
                })
            }

            return ret;
        }
    }, [clearErrors, setError]);

    const buscaCEP = () => {
        let updatePessoa = { ...pessoa };
        setLoading(true);
        if (pessoa.endereco.cep && pessoa.endereco.cep !== '') {
            Pessoas.searchZipCode(pessoa.endereco.cep).then(response => {
                updatePessoa.endereco = {
                    cep: response.cep,
                    endereco: response.end,
                    complemento: '',
                    bairro: response.bairro,
                    cidade: response.cidade,
                    estado: response.uf
                }

                setPessoa(updatePessoa);
                setLoading(false);
                document.getElementById("endereco-numero").focus();
            }).catch(error => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao buscar o CEP. Tente novamente")
                });
                setLoading(false);
                document.getElementById("endereco-endereco").focus();
            })
        }
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar pessoa");

        register({ name: 'cpf' }, {
            required: "*Campo obrigatório",
            validate: value => validaCPFCNPJ(value) || "Documento inválido"
        });

        if (id !== 'new') {
            Pessoas.retrieve(id).then((response) => {
                response.filesToUpload = [];
                setValue('cpf', response.cpf);
                setPessoa(response);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setPessoa({
                tipo: 'Física',
                endereco: {},
                filesToUpload: []
            })
            setLoading(false);
        }
    }, [id, register, validaCPFCNPJ, setValue]);

    const columnsResponsaveis = [
        {
            id: 'nome',
            label: 'Nome',
            align: 'left'
        },
        {
            id: 'documento',
            label: 'CPF',
            align: 'center'
        },
        {
            id: 'relacao',
            label: 'Relação',
            align: 'center',
            format: (value, index) => (
                <TextField
                    key={"responsaveis[" + index + "]-relacao"}
                    id={"responsaveis[" + index + "]-relacao"}
                    name={"responsaveis[" + index + "]-relacao"}
                    label="Relação"
                    size="small"
                    defaultValue={value}
                    fullWidth
                    onChange={e => handleChangeField(e, null, 'responsaveis', index)}
                />
            )
        },
        {
            id: 'id',
            label: 'Excluir',
            align: 'center',
            format: (value, index) => (
                <IconButton key={"responsaveis[" + index + "]-excluir"} size="small" aria-label="Excluir" onClick={() => { }}>
                    <DeleteIcon color="secondary" />
                </IconButton>
            )
        },
    ]

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button'
        }
    ]

    return (!loading ?
        <Container>
            <Paper square>
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar pessoa"
                    >
                        <Tab label="Cadastro" {...a11yProps(0)} />
                        <Tab label="Contatos" {...a11yProps(1)} />
                        <Tab label="Responsáveis" {...a11yProps(2)} disabled={pessoa.tipo === "Física"} />
                        <Tab label="Documentos" {...a11yProps(3)} />
                        <Tab label="Observações" {...a11yProps(4)} />
                        <Tab label="Acesso" {...a11yProps(5)} disabled={id === 'new'} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="tipo-label">Pessoa</InputLabel>
                                    <Select
                                        name="tipo"
                                        labelid="tipo-label"
                                        size="small"
                                        value={pessoa.tipo}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="F" value="Física">Pessoa Física</MenuItem>
                                        <MenuItem key="J" value="Jurídica">Pessoa Jurídica</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MaskedField
                                    name="cpf"
                                    label={pessoa.tipo === 'Física' ? 'CPF' : 'CNPJ'}
                                    size="small"
                                    defaultValue={pessoa.cpf}
                                    type={pessoa.tipo === 'Física' ? 'CPF' : 'CNPJ'}
                                    fullWidth
                                    onChange={e => {
                                        handleChangeField(e);
                                        validaCPFCNPJ(e.target.value);
                                        setValue('cpf', e.target.value);
                                    }}
                                    error={!!errors.cpf}
                                    helperText={errors.cpf ? errors.cpf.message : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nome"
                                    label="Nome"
                                    size="small"
                                    defaultValue={pessoa.nome}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.nome}
                                    helperText={errors.nome ? errors.nome.message : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="apelido"
                                    label="Apelido"
                                    size="small"
                                    defaultValue={pessoa.apelido}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MaskedField
                                    name="endereco-cep"
                                    label="CEP"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.cep : ''}
                                    type="CEP"
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                    onBlur={buscaCEP}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} />
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="endereco-endereco"
                                    name="endereco-endereco"
                                    label="Endereço"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.endereco : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="endereco-numero"
                                    name="endereco-numero"
                                    label="Número"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.numero : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="endereco-complemento"
                                    label="Complemento"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.complemento : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="endereco-bairro"
                                    label="Bairro"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.bairro : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="endereco-cidade"
                                    label="Cidade"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.cidade : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="endereco-estado"
                                    label="Estado"
                                    size="small"
                                    defaultValue={pessoa.endereco ? pessoa.endereco.estado : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="email"
                                    label="E-Mail"
                                    size="small"
                                    defaultValue={pessoa.email}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Grid container spacing={3}>
                            {pessoa.telefones && pessoa.telefones.map((telefone, index) => (
                                <Fragment key={index}>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={"telefone[" + index + "]-descricao"}
                                            name={"telefone-descricao"}
                                            label="Descrição"
                                            size="small"
                                            defaultValue={telefone.descricao}
                                            fullWidth
                                            onChange={e => handleChangeField(e, null, 'telefones', index)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            id={"telefone[" + index + "]-numero"}
                                            name={"telefone[" + index + "]-numero"}
                                            label="Telefone"
                                            size="small"
                                            defaultValue={telefone.numero}
                                            fullWidth
                                            onChange={e => handleChangeField(e, null, 'telefones', index)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton aria-label="Excluir" onClick={e => handleClickDelContact(e, index)}>
                                            <DeleteIcon color="secondary" />
                                        </IconButton>
                                    </Grid>
                                </Fragment>
                            ))}
                            <Grid item xs={6}>
                                <TextField
                                    id="contato-descricao"
                                    label="Descrição"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="contato-numero"
                                    label="Telefone"
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton aria-label="Incluir contato" variant="filled" color="primary" onClick={handleClickAddContact}>
                                    <AddIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Autocomplete
                            id="adiciona-responsavel"
                            style={{
                                marginBottom: '30px'
                            }}
                            size="small"
                            fullWidth
                            open={listaResponsaveis.open}
                            onOpen={handleListResponsaveis}
                            onClose={() => {
                                setListaResponsaveis({ ...listaResponsaveis, open: false });
                            }}
                            onChange={handleChangeResponsavel}
                            onKeyUp={handleListResponsaveis}
                            getOptionSelected={(option, value) => option.nome === value.nome}
                            getOptionLabel={(option) => option.nome}
                            options={listaResponsaveis.options}
                            loading={listaResponsaveis.loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Incluir responsável"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {listaResponsaveis.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <DataTable
                            columns={columnsResponsaveis}
                            docs={pessoa.responsaveis ? pessoa.responsaveis : []}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <Button variant="contained" color="primary" onClick={() => setOpenFileUpload(true)} style={{ marginBottom: '20px' }}>
                            Adicionar documentos
                        </Button>
                        <List>
                            {pessoa.documentos && pessoa.documentos.map((documento, index) => (
                                <Fragment key={"documento-" + index}>
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <DescriptionOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText align="center">
                                            <Link href={documento.url} target="_blank">
                                                {documento.descricao}
                                            </Link>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <IconButton onClick={e => handleClickDeleteFile(e, index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <List>
                            <ListItem key="adicionar-observacao-key">
                                <ListItemText align="left">
                                    <TextField
                                        id="adicionar-observacao"
                                        label="Incluir observação"
                                        size="small"
                                        fullWidth
                                    />
                                </ListItemText>
                                <ListItemIcon align="center">
                                    <IconButton aria-label="Excluir" onClick={handleClickAddNote}>
                                        <AddCircleIcon color="primary" fontSize="large" />
                                    </IconButton>
                                </ListItemIcon>
                            </ListItem>
                            {pessoa.observacoes && pessoa.observacoes.map((observacao, index) => (
                                <Fragment key={"observacao-" + index}>
                                    <ListItem key={index}>
                                        <ListItemAvatar align="center" style={{ width: '100px' }}>
                                            <Avatar alt={observacao.responsavel} src="/broken-image.jpg" title={observacao.responsavel}></Avatar>
                                        </ListItemAvatar>
                                        <ListItemText align="center" style={{ maxWidth: '200px' }}>
                                            {new Date(observacao.data).toLocaleDateString('pt-BR')}
                                            <br />
                                            {new Date(observacao.data).toLocaleTimeString('pt-BR')}
                                        </ListItemText>
                                        <ListItemText align="left">{observacao.observacao}</ListItemText>
                                    </ListItem>
                                    <Divider component="li" />
                                </Fragment>
                            ))}
                        </List>
                    </TabPanel>
                    <TabPanel value={tab} index={5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="senha"
                                    label="Senha de acesso"
                                    size="small"
                                    defaultValue={pessoa.senha}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained" color="primary" onClick={handleClickRandomPasscode}>
                                    Gerar senha aleatória
                                </Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {(user?.permissoes["Pessoas-alterar"] || (user?.permissoes["Pessoas-incluir"] && id === 'new')) &&
                        <ActionMenu actions={menuActions} />
                    }
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <DropzoneDialogBase
                filesLimit={10}
                fileObjects={pessoa.filesToUpload}
                cancelButtonText="Fechar"
                submitButtonText="Ok"
                dialogTitle="Enviar arquivos"
                dropzoneText="Clique ou arraste os arquivos aqui (max 10)"
                maxFileSize={200000000}
                open={openFileUpload}
                onAdd={handleUploadFiles}
                onDelete={handleDeleteFile}
                onClose={() => {
                    setOpenFileUpload(false);
                }}
                onSave={() => {
                    setOpenFileUpload(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewText="Arquivos selecionados"
            />
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeletePessoa}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </Container>
        :
        (loading && (
            <Loading open={loading} />
        ))
    )
};

export default EditaPessoa;