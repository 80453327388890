import React, { useState } from "react";
import {
    Button,
    TextField,
    Link,
    Grid,
    Box,
    Typography,
    Container,
    CircularProgress
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import useStyles from "../styles/Login";

import { useAuth } from '../AuthContext';

function Login() {
    const { loading, login } = useAuth();
    const classes = useStyles();

    const [formData, setFormData] = useState({
        usuario: null,
        senha: null,
    });

    const [error, setError] = useState(null);

    const handleLogin = (event) => {
        login(formData).then(() => {
            window.location.reload()
        }).catch(error => setError(error));
    };

    const inputHandler = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        setError(null);
        setFormData({
            ...formData,
            [nam]: val,
        });
    };

    return (
        <Container maxWidth="xs" className={classes.root}>
            <div className={classes.paper}>
                <img
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    width={150}
                    alt="logo"
                />
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="usuario"
                        label="Usuário"
                        name="usuario"
                        autoComplete="on"
                        autoFocus
                        onChange={inputHandler}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="senha"
                        label="Senha"
                        type="password"
                        id="senha"
                        autoComplete="current-password"
                        onChange={inputHandler}
                    />
                    {error && <Alert severity="error">{error}</Alert>}
                    {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Mantenha-me conectado"
          /> */}
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                        disabled={loading}
                        onClick={handleLogin}
                    >
                        Entrar
                        {loading && <CircularProgress size={24} />}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            {/* <Link href="#" variant="body2">
                Esqueceu a senha?
              </Link> */}
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"" /* Cadastre-se */}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box pb={5}>
                <Typography variant="body2" align="center">
                    {"Copyright © "}
                    <Link href="https://www.compassio.com.br/" target="_blank">
                        Compassio
                    </Link>{" "}
                    {new Date().getFullYear()}
                </Typography>
            </Box>
        </Container>
    )
};

export default Login;