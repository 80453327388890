import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";
import {
    TextField,
    Tabs,
    Tab,
    Box,
    Grid,
    Snackbar,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Button,
    Link,
    GridList,
    GridListTile,
    Dialog,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

import {
    Alert
} from "@material-ui/lab";

import { useForm } from "react-hook-form";
import { DropzoneDialogBase } from 'material-ui-dropzone';
import { GoogleMap, LoadScript, Marker, Polygon, DrawingManager, StandaloneSearchBox } from '@react-google-maps/api';

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { Container, Paper } from "./styles";

import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { ComarcasComboBox, PessoasComboBox } from '../../components/DataComboBox';
import { MaskedField, FloatField } from '../../components/MaskedFields';

import Imoveis from "../../services/Imoveis";
import Pessoas from "../../services/Pessoas";
import { useAuth } from "../../AuthContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        style: {
            'minWidth': '120px'
        }
    };
}

function getCoordinates(path) {
    var coordinates = [];

    for (var i = 0; i < path.length; i++) {
        coordinates.push({
            lat: path.getAt(i).lat(),
            lng: path.getAt(i).lng()
        });
    }
    return JSON.stringify(coordinates);
}

const EditaImovel = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const [tab, setTab] = useState(0);
    const [imovel, setImovel] = useState({});
    const [edificacao, setEdificacao] = useState({
        tipo: "",
        area: "",
        descricao: "",
        observacao: ""
    });
    const [loading, setLoading] = useState(true);
    const [openFileUpload, setOpenFileUpload] = useState(false);
    const [openImageUpload, setOpenImageUpload] = useState(false);
    const [image, setImage] = useState({
        open: false,
        image: ''
    })
    const { register, handleSubmit, setError, clearErrors, errors } = useForm();

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const searchBox = useRef();
    const map = useRef();
    const coordinates = useRef(null);
    const marker = useRef(null);

    const saveForm = () => {
        setLoading(true);

        if (coordinates.current && coordinates.current !== '') {
            let updateImovel = { ...imovel };
            if (!updateImovel.mapa) updateImovel.mapa = {};
            updateImovel.mapa.coordenadas = coordinates.current
            setImovel(updateImovel);
        }

        if (marker.current && marker.current !== '') {
            let updateImovel = { ...imovel };
            if (!updateImovel.mapa) updateImovel.mapa = {};
            updateImovel.mapa.marcador = marker.current
            setImovel(updateImovel);
        }

        if (imovel.proprietarios.length <= 0) {
            setResponseStatus({
                open: true,
                severity: "error",
                message: "É necessário informar ao menos um proprietário para o imóvel"
            });

            setLoading(false);
        } else {
            if (id === 'new') {
                Imoveis.create(imovel).then(response => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Cadastro realizado com sucesso"
                    });

                    response.imagesToUpload = [];
                    response.filesToUpload = [];
                    setImovel(response);
                    setLoading(false);

                    window.location.href = '/imoveis/' + response._id;

                }).catch((error) => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                    });

                    setLoading(false);
                });
            } else {
                Imoveis.update(imovel._id, imovel).then(response => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Cadastro atualizado com sucesso"
                    });

                    Imoveis.retrieve(id).then((response) => {
                        response.imagesToUpload = [];
                        response.filesToUpload = [];
                        setImovel(response);
                        setLoading(false);
                    }).catch((error) => {
                        console.log(error);
                    });
                }).catch((error) => {
                    console.log(error);
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                    });
                });
            }
        }
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleChangeField = (event, aux, obj, index) => {
        let updateImovel = { ...imovel };

        if (obj) {
            let field = event.target.name.split('-')[event.target.name.split('-').length - 1];

            if (index >= 0) {
                if (!updateImovel[obj]) updateImovel[obj] = [];
                updateImovel[obj][index][field] = event.target.value;
            } else {
                if (!updateImovel[obj]) updateImovel[obj] = {};
                updateImovel[obj][field] = event.target.value;
            }
        } else {
            updateImovel[event.target.name] = event.target.value;
        }

        setImovel(updateImovel);
    }

    const handleChangeComarca = (event, comarca) => {
        if (comarca) {
            let updateImovel = { ...imovel };
            updateImovel.comarca = comarca._id;
            setImovel(updateImovel);
        }
    }

    const handleChangePessoa = (event, pessoa) => {
        if (pessoa) {
            let updateImovel = { ...imovel };

            updateImovel.proprietarios.push({
                id: pessoa._id,
                nome: pessoa.nome,
                documento: pessoa.cpf,
                relacao: ''
            });

            setImovel(updateImovel);

            document.getElementById("adiciona-proprietario").value = ''
        }
    }

    const handleDeleteProprietario = (event, index) => {
        let updateImovel = { ...imovel };
        updateImovel.proprietarios.splice(index, 1);

        setImovel(updateImovel);
    }

    const handleChangeEdificacao = (event) => {
        let updateEdificacao = { ...edificacao };
        updateEdificacao[event.target.name] = event.target.value;
        setEdificacao(updateEdificacao);
    }

    const handleClickAddEdificacao = (event) => {
        let updateImovel = { ...imovel };
        let atualiza = true;

        clearErrors(['edificacao-tipo', 'edificacao-area', 'edificacao-descricao']);

        if (edificacao.tipo === '') {
            setError("edificacao-tipo", {
                type: "required",
                message: "*Campo obrigatório"
            })
            atualiza = false;
        }

        if (edificacao.area === '') {
            setError("edificacao-area", {
                type: "required",
                message: "*Campo obrigatório"
            })
            atualiza = false;
        }

        if (edificacao.descricao === '') {
            setError("edificacao-descricao", {
                type: "required",
                message: "*Campo obrigatório"
            })
            atualiza = false;
        }

        if (atualiza) {
            updateImovel.edificacoes.push({
                tipo: edificacao.tipo,
                area: edificacao.area,
                descricao: edificacao.descricao,
                observacao: edificacao.observacao,
            });

            setEdificacao({
                tipo: "",
                area: "",
                descricao: "",
                observacao: ""
            });

            setImovel(updateImovel);
        }
    }

    const handleDeleteEdificacao = (event, index) => {
        let updateImovel = { ...imovel };
        updateImovel.edificacoes.splice(index, 1);
        setImovel(updateImovel);
    }

    const handleUploadImages = (newFiles) => {
        let updateImovel = { ...imovel };
        updateImovel.imagesToUpload = updateImovel.filesToUpload.concat(newFiles);
        setImovel(updateImovel);
    }

    const handleDeleteImage = (file) => {
        let updateImovel = { ...imovel };
        updateImovel.imagesToUpload.splice(updateImovel.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setImovel(updateImovel);
    }

    const handleUploadFiles = (newFiles) => {
        let updateImovel = { ...imovel };
        updateImovel.filesToUpload = updateImovel.filesToUpload.concat(newFiles);
        setImovel(updateImovel);
    }

    const handleDeleteFile = (file) => {
        let updateImovel = { ...imovel };
        updateImovel.filesToUpload.splice(updateImovel.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setImovel(updateImovel);
    }

    const handleClickDeleteFile = (event, index) => {
        let updateImovel = { ...imovel };
        updateImovel.documentos.splice(index, 1);
        setImovel(updateImovel);
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: imovel.nome,
            id: imovel._id
        })
    }

    const handleDeleteImovel = (event) => {
        Imoveis.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/imoveis';
        }).catch(error => {
            console.log(error);
        });
    }

    const handlePolygonComplete = (polygon) => {
        coordinates.current = JSON.stringify(polygon.getPath().i);
    }

    const handleLoadPolygon = (polygon) => {
        polygon.getPaths().forEach(function (newPath, index) {
            window.google.maps.event.addListener(newPath, 'insert_at', function () {
                coordinates.current = getCoordinates(newPath);
            });

            window.google.maps.event.addListener(newPath, 'remove_at', function () {
                coordinates.current = getCoordinates(newPath);
            });

            window.google.maps.event.addListener(newPath, 'set_at', function () {
                coordinates.current = getCoordinates(newPath);
            });
        });
    }

    const handleChangeSearchBox = (event) => {
        let places = searchBox.current.getPlaces();

        if (places.length === 0) {
            return;
        }

        // For each place, get the icon, name and location.
        let bounds = new window.google.maps.LatLngBounds();

        places.forEach(function (place) {

            if (!place.geometry) {
                console.log("Returned place contains no geometry");
                return;
            }

            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
        });

        map.current.fitBounds(bounds);
    }

    const handleMarkerComplete = (pinnedMarker) => {
        marker.current = JSON.stringify({ lat: pinnedMarker.getPosition().lat(), lng: pinnedMarker.getPosition().lng() })
    }

    const handleDragEndMarker = (pinnedMarker) => {
        marker.current = JSON.stringify({ lat: pinnedMarker.latLng.lat(), lng: pinnedMarker.latLng.lng() })
    }

    const buscaCEP = () => {
        let updateImovel = { ...imovel };
        setLoading(true);
        if (imovel.endereco.cep && imovel.endereco.cep !== '') {
            Pessoas.searchZipCode(imovel.endereco.cep).then(response => {
                updateImovel.endereco = {
                    cep: response.cep,
                    endereco: response.end,
                    complemento: '',
                    bairro: response.bairro,
                    cidade: response.cidade,
                    estado: response.uf
                }

                setImovel(updateImovel);
                setLoading(false);
                document.getElementById("endereco-numero").focus();
            }).catch(error => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao buscar o CEP. Tente novamente")
                });
                setLoading(false);
                document.getElementById("endereco-endereco").focus();
            })
        }
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar imóvel");
        if (id !== 'new') {
            Imoveis.retrieve(id).then((response) => {
                response.imagesToUpload = [];
                response.filesToUpload = [];
                setImovel(response);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setImovel({
                tipo: 'Terreno',
                categoria: 'Urbano',
                imagesToUpload: [],
                filesToUpload: [],
                proprietarios: [],
                edificacoes: []
            });
            setLoading(false);
        }
    }, [id]);

    const columnsProprietarios = [
        {
            id: 'nome',
            label: 'Nome',
            align: 'left'
        },
        {
            id: 'id',
            label: 'Excluir',
            align: 'center',
            format: (value, index) => (
                <IconButton size="small" aria-label="Excluir" onClick={e => handleDeleteProprietario(e, index)}>
                    <DeleteIcon color="secondary" />
                </IconButton>
            )
        },
    ]

    const columnsEdificacoes = [
        {
            id: 'tipo',
            label: 'Tipo',
            align: 'center'
        },
        {
            id: 'area',
            label: 'Área',
            align: 'center',
            format: (value) => <NumberFormat
                value={value} displayType={'text'}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                isNumericString />
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'observacao',
            label: 'Observação',
            align: 'left'
        },
        {
            id: 'id',
            label: 'Excluir',
            align: 'center',
            format: (value, index) => (
                <IconButton size="small" aria-label="Excluir" onClick={e => handleDeleteEdificacao(e, index)}>
                    <DeleteIcon color="secondary" />
                </IconButton>
            )
        },
    ]

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button'
        }
    ]

    return (!loading ?
        <Container>
            <Paper square>
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar imovel"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Cadastro" {...a11yProps(0)} />
                        <Tab label="Proprietários" {...a11yProps(1)} />
                        <Tab label="Edificações" {...a11yProps(2)} />
                        <Tab label="Mapa" {...a11yProps(3)} />
                        <Tab label="Fotos" {...a11yProps(4)} />
                        <Tab label="Documentos" {...a11yProps(5)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <ComarcasComboBox
                                    name="comarca"
                                    onChange={handleChangeComarca}
                                    defaultValue={imovel.comarca &&
                                    {
                                        _id: imovel.comarca._id,
                                        nome: imovel.comarca.nome
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} />
                            <Grid item xs={12} sm={4}>
                                <NumberFormat
                                    format="##.###"
                                    customInput={TextField}
                                    name="matricula"
                                    label="Matrícula"
                                    size="small"
                                    value={imovel.matricula}
                                    onValueChange={values => {
                                        handleChangeField({
                                            target: {
                                                name: 'matricula',
                                                value: values.value
                                            }
                                        })
                                    }}
                                    fullWidth
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.matricula}
                                    helperText={errors.matricula?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <NumberFormat
                                    customInput={TextField}
                                    name="folha"
                                    label="Folha"
                                    size="small"
                                    defaultValue={imovel.folha}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                    decimalScale={0}
                                    fixedDecimalScale
                                    isNumericString
                                    allowEmptyFormatting
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="livro"
                                    label="Livro"
                                    size="small"
                                    defaultValue={imovel.livro}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="descricao"
                                    label="Descrição"
                                    size="small"
                                    defaultValue={imovel.descricao}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.descricao}
                                    helperText={errors.descricao?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="tipo-label">Tipo</InputLabel>
                                    <Select
                                        name="tipo"
                                        labelid="tipo-label"
                                        size="small"
                                        value={imovel.tipo}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="Terreno" value="Terreno">Terreno</MenuItem>
                                        <MenuItem key="Casa" value="Casa">Casa</MenuItem>
                                        <MenuItem key="Apartamento" value="Apartamento">Apartamento</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="categoria-label">Categoria</InputLabel>
                                    <Select
                                        name="categoria"
                                        labelid="categoria-label"
                                        size="small"
                                        value={imovel.categoria}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="Urbano" value="Urbano">Urbano</MenuItem>
                                        <MenuItem key="Rural" value="Rural">Rural</MenuItem>
                                        <MenuItem key="Parcialmente Urbano" value="Parcialmente Urbano">Parcialmente Urbano</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="zoneamento"
                                    label="Zoneamento"
                                    size="small"
                                    defaultValue={imovel.zoneamento}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FloatField
                                    name="metragem"
                                    label="Metragem"
                                    size="small"
                                    defaultValue={imovel.metragem}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="inscricao_imobiliaria"
                                    label="Inscrição Imobiliária"
                                    size="small"
                                    defaultValue={imovel.inscricao_imobiliaria}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="numero_itr"
                                    label="Número ITR"
                                    size="small"
                                    defaultValue={imovel.numero_itr}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    name="numero_ccir"
                                    label="Número CCIR"
                                    size="small"
                                    defaultValue={imovel.numero_ccir}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <MaskedField
                                    name="cep"
                                    label="CEP"
                                    size="small"
                                    defaultValue={imovel.endereco?.cep}
                                    type="CEP"
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, null, 'endereco')}
                                    onBlur={buscaCEP}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} />
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="endereco-endereco"
                                    name="endereco"
                                    label="Endereço"
                                    size="small"
                                    defaultValue={imovel.endereco ? imovel.endereco.endereco : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="endereco-numero"
                                    name="numero"
                                    label="Número"
                                    size="small"
                                    defaultValue={imovel.endereco ? imovel.endereco.numero : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="complemento"
                                    label="Complemento"
                                    size="small"
                                    defaultValue={imovel.endereco ? imovel.endereco.complemento : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="bairro"
                                    label="Bairro"
                                    size="small"
                                    defaultValue={imovel.endereco ? imovel.endereco.bairro : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="cidade"
                                    label="Cidade"
                                    size="small"
                                    defaultValue={imovel.endereco ? imovel.endereco.cidade : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="estado"
                                    label="Estado"
                                    size="small"
                                    defaultValue={imovel.endereco ? imovel.endereco.estado : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'endereco')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="avaliacao-valor"
                                    label="Valor Avaliação"
                                    size="small"
                                    defaultValue={imovel.avaliacao ? imovel.avaliacao.valor : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'avaliacao')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="avaliacao-data"
                                    label="Data Avaliação"
                                    size="small"
                                    defaultValue={imovel.avaliacao ? imovel.avaliacao.data : ''}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={e => handleChangeField(e, '', 'avaliacao')}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <PessoasComboBox
                            id="adiciona-proprietario"
                            onChange={handleChangePessoa}
                            style={{
                                marginBottom: '20px'
                            }}
                        />
                        <DataTable
                            columns={columnsProprietarios}
                            docs={imovel.proprietarios}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                        <Grid container spacing={3} style={{ marginBottom: '30px' }}>
                            <Grid item xs={2}>
                                <TextField
                                    name="tipo"
                                    label="Tipo"
                                    size="small"
                                    fullWidth
                                    value={edificacao.tipo}
                                    onChange={handleChangeEdificacao}
                                    error={!!errors["edificacao-tipo"]}
                                    helperText={errors["edificacao-tipo"]?.message}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FloatField
                                    name="area"
                                    label="Área"
                                    size="small"
                                    fullWidth
                                    value={edificacao.area}
                                    onChange={handleChangeEdificacao}
                                    error={!!errors["edificacao-area"]}
                                    helperText={errors["edificacao-area"]?.message}

                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    name="descricao"
                                    label="Descrição"
                                    size="small"
                                    fullWidth
                                    value={edificacao.descricao}
                                    onChange={handleChangeEdificacao}
                                    error={!!errors["edificacao-descricao"]}
                                    helperText={errors["edificacao-descricao"]?.message}

                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    name="observacao"
                                    label="Observação"
                                    size="small"
                                    value={edificacao.observacao}
                                    onChange={handleChangeEdificacao}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={handleClickAddEdificacao}>
                                    <AddCircleIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <DataTable
                            columns={columnsEdificacoes}
                            docs={imovel.edificacoes}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                        <Button
                            id="limpar-mapa"
                            variant="contained"
                            style={{
                                background: 'white',
                                padding: '8px 15px',
                                marginBottom: '10px'
                            }}
                            onClick={() => { console.log("limpar") }}
                        >Limpar</Button>
                        <LoadScript
                            googleMapsApiKey="AIzaSyAymdqUYpofthT5ixv2Y9Bit58a2yb-Hy0"
                            libraries={['drawing', 'places']}
                        >
                            <GoogleMap
                                mapContainerStyle={{ width: 'calc(100vw - 340px)', height: 'calc(100vh - 260px)' }}
                                center={imovel.mapa && imovel.mapa.marcador ? JSON.parse(imovel.mapa.marcador) : { lat: -26.2508609, lng: -49.3820848 }}
                                zoom={18}
                                onLoad={(ref) => {
                                    map.current = ref
                                }}
                            //onBoundsChanged={() => searchBox.current.setBounds(map.current.getBounds())}
                            >
                                <StandaloneSearchBox
                                    onLoad={(ref) => {
                                        searchBox.current = ref
                                        let button = document.getElementById('limpar-mapa');
                                        map.current.controls[window.google.maps.ControlPosition.BOTTOM_RIGHT].push(button);
                                    }}
                                    onPlacesChanged={handleChangeSearchBox}
                                >
                                    <TextField
                                        name="edificacao"
                                        size="small"
                                        variant="outlined"
                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        style={{
                                            boxSizing: 'border-box',
                                            width: '300px',
                                            position: 'absolute',
                                            marginTop: '10px',
                                            marginLeft: '355px',
                                            background: 'white',
                                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                            fontSize: '12px'
                                        }}
                                    />
                                </StandaloneSearchBox>
                                <DrawingManager
                                    onPolygonComplete={handlePolygonComplete}
                                    onMarkerComplete={handleMarkerComplete}
                                />
                                {imovel.mapa && imovel.mapa.marcador && (
                                    <Marker
                                        editable
                                        draggable
                                        position={JSON.parse(imovel.mapa.marcador)}
                                        onDragEnd={handleDragEndMarker}
                                        onClick={(e) => console.log(e)}
                                    />
                                )}
                                {imovel.mapa && imovel.mapa.coordenadas && imovel.mapa.coordenadas.length > 0 && (
                                    <Polygon
                                        paths={JSON.parse(imovel.mapa.coordenadas)}
                                        editable
                                        draggable
                                        onLoad={handleLoadPolygon}
                                    />
                                )}
                            </GoogleMap>
                        </LoadScript>
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                        <Button variant="contained" color="primary" onClick={() => setOpenImageUpload(true)} style={{ marginBottom: '20px' }}>
                            Adicionar fotos
                        </Button>
                        <GridList cols={4}>
                            {imovel.fotos && imovel.fotos.map((foto, index) => (
                                <GridListTile key={"image-" + index} cols={1}>
                                    <img src={foto.url} alt={foto.descricao} onClick={(e) => {
                                        setImage({
                                            open: true,
                                            image: e.target.src
                                        })
                                    }} style={{ cursor: 'pointer' }} />
                                </GridListTile>
                            ))}
                        </GridList>
                    </TabPanel>
                    <TabPanel value={tab} index={5}>
                        <Button variant="contained" color="primary" onClick={() => setOpenFileUpload(true)} style={{ marginBottom: '20px' }}>
                            Adicionar documentos
                        </Button>
                        {imovel.documentos && imovel.documentos.map((documento, index) => (
                            <List key={"documento-" + index}>
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <DescriptionOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText align="center">
                                        <Link href={documento.url} target="_blank">
                                            {documento.descricao}
                                        </Link>
                                    </ListItemText>
                                    <ListItemIcon>
                                        <IconButton onClick={e => handleClickDeleteFile(e, index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItem>
                                <Divider />
                            </List>
                        ))}
                    </TabPanel>
                    {(user?.permissoes["Imóveis-alterar"] || (user?.permissoes["Imóveis-incluir"] && id === 'new')) && (
                        <ActionMenu actions={menuActions} />
                    )}
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <DropzoneDialogBase
                acceptedFiles={['image/*']}
                filesLimit={10}
                fileObjects={imovel.imagesToUpload}
                cancelButtonText="Fechar"
                submitButtonText="Ok"
                dialogTitle="Enviar arquivos"
                dropzoneText="Clique ou arraste as imagens aqui (máx 10)"
                maxFileSize={200000000}
                open={openImageUpload}
                onAdd={handleUploadImages}
                onDelete={handleDeleteImage}
                onClose={() => {
                    setOpenImageUpload(false);
                }}
                onSave={() => {
                    setOpenImageUpload(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewText="Arquivos selecionados"
            />
            <DropzoneDialogBase
                filesLimit={10}
                fileObjects={imovel.filesToUpload}
                cancelButtonText="Fechar"
                submitButtonText="Ok"
                dialogTitle="Enviar arquivos"
                dropzoneText="Clique ou arraste os arquivos aqui (máx 10)"
                maxFileSize={200000000}
                open={openFileUpload}
                onAdd={handleUploadFiles}
                onDelete={handleDeleteFile}
                onClose={() => {
                    setOpenFileUpload(false);
                }}
                onSave={() => {
                    setOpenFileUpload(false);
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewText="Arquivos selecionados"
            />
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteImovel}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
            <Dialog
                maxWidth="lg"
                open={image.open}
                onClose={() => {
                    setImage({
                        ...image,
                        open: false
                    });
                }}
            >
                <img src={image.image} alt={image.image} height="500" />
            </Dialog>
        </Container>
        :
        (loading && (
            <Loading open={loading} />
        ))
    )
};

export default EditaImovel;