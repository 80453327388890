import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

import { useForm } from "react-hook-form";
import { DateField, CurrencyField } from '../../components/MaskedFields';

function EditaDespesaOS(props) {
    const { register, handleSubmit, errors } = useForm();
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Editar despesa - {props.data.nome}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DateField
                            name="vencimento"
                            label="Vencimento"
                            value={props.data.vencimento}
                            onChange={props.handleChangeDateField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.vencimento}
                            helperText={errors.vencimento?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="descricao"
                            label="Descrição"
                            defaultValue={props.data.descricao}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.descricao}
                            helperText={errors.descricao?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="forma-label">Forma Pagamento</InputLabel>
                            <Select
                                name="metodoPagamento"
                                labelid="forma-label"
                                size="small"
                                value={props.data.metodoPagamento ? props.data.metodoPagamento : ''}
                                onChange={props.handleChangeField}
                                fullWidth
                            >
                                <MenuItem key="Boleto" value="Boleto">Boleto</MenuItem>
                                <MenuItem key="Cartão Crédito" value="Cartão Crédito">Cartão Crédito</MenuItem>
                                <MenuItem key="Cartão Débito" value="Cartão Débito">Cartão Débito</MenuItem>
                                <MenuItem key="Cheque" value="Cheque">Cheque</MenuItem>
                                <MenuItem key="Dinheiro" value="Dinheiro">Dinheiro</MenuItem>
                                <MenuItem key="Transferência Bancária" value="Transferência Bancária">Transferência Bancária</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyField
                            name="valor"
                            label="Valor"
                            value={props.data.valor}
                            defaultValue={props.data.valor}
                            onChange={props.handleChangeField}
                            fullWidth
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.valor}
                            helperText={errors.valor?.message}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(props.confirm)} color="primary" autoFocus>Ok</Button>
                <Button onClick={props.cancel}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditaDespesaOS;