import React, { useState } from 'react';
import {
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon
} from '@material-ui/lab';

// import { Container } from './styles';

function ActionMenu(props) {
    
    const [open, setOpen] = useState(false);

    return (
        <SpeedDial
            ariaLabel="Menu de ações"
            icon={<SpeedDialIcon />}
            onClose={() => {
                setOpen(false)
            }}
            onOpen={() => {
                setOpen(true)
            }}
            open={open}
            direction="up"
            style={{
                position: 'fixed',
                right: '20px',
                bottom: '50px'
            }}
        >
            {props.actions.map((action, index) => (
                !action.disabled && (
                <SpeedDialAction
                    key={index}
                    icon={action.icon}
                    tooltipTitle={action.title}
                    onClick={action.action}
                    FabProps={{
                        type: action.type
                    }}
                />
                )
            ))}
        </SpeedDial>
    )
}

export default ActionMenu;