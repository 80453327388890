import React from 'react';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../AuthContext';

function NumberField(props) {
    const { user } = useAuth();
    const mostraValores = user?.permissoes["Mostrar-valores"];
    let number = <NumberFormat {...props} />;

    switch (mostraValores) {
        case 0: number = <NumberFormat {...props} />; break;
        case 1: props.responsavelOS === user._id ? number = <NumberFormat {...props} /> : number = '********'; break;
        case 2: props.responsavel === user._id ? number = <NumberFormat {...props} /> : number = '********'; break;
        case 3: number = '********'; break;
        default: number = '********'; break;
    }

    return number;
}

export default NumberField;