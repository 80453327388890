import React, { useState, useEffect, useCallback } from 'react';
import {
    IconButton,
    Chip,
    Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { green, red } from '@material-ui/core/colors';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';

import moment from 'moment';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';

import EditaItemOrdemServico from "../EditaOrdemServico/item";
import ConcluiTarefa from '../EditaOrdemServico/concluiTarefa';

import OrdensServico from '../../services/OrdensServico';
import { useAuth } from '../../AuthContext'

const ListaTarefas = () => {
    const { user } = useAuth();
    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        status: ['Pendente']
    });
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: "&status=Pendente"
    });
    const [dialogVerItem, setDialogVerItem] = useState({
        data: {},
        open: false
    });
    const [dialogConcluir, setDialogConcluir] = useState({
        data: {},
        open: false
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    });

    const Listar = useCallback((page, size, filters) => {

        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })
        
        if (user && !!!user.permissoes["Visualizar-Tarefas-de-Todos-os-Usuários"] && !filters?.includes("responsavel=")) {
            filters += "&responsavel=" + user._id
        }

        OrdensServico.listaTarefas(page, size, filters).then(tarefas => {
            setData({
                isLoading: false,
                docs: tarefas.docs,
                page: tarefas.page - 1,
                size: tarefas.limit,
                count: tarefas.totalDocs,
                filters: filters
            });
        }).catch(error => {
            console.log(error);
        });
    }, [user])

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const applyFilters = () => {
        let qs = "";

        if (filters.search !== "") {
            qs += "&$or[0][nome][$regex]=" + filters.search;
            qs += "&$or[0][nome][$options]=gi";
            qs += "&$or[1][responsavelItem.nome][$regex]=" + filters.search;
            qs += "&$or[1][responsavelItem.nome][$options]=gi";
        }

        if (filters.dateFrom) qs += '&data[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&data[$lte]=' + filters.dateTo;
        if (filters.dueDateFrom) qs += '&prazo[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&prazo[$lte]=' + filters.dueDateTo;
        if (filters.status.length > 0) {
            qs += "&status[$in]=" + filters.status.join();
        } else {
            qs += "&status=Pendente";
        }

        setFilters({
            ...filters,
            qs: qs
        })

        setData({
            ...data,
            filters: qs
        })
    }

    const handleKeyUpSearch = (event) => {
        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            applyFilters();
        }
    }

    const handleReopenTask = (event) => {
        OrdensServico.retrieve(dialogVerItem.data.ordemServico).then(ordemServico => {
            let index = ordemServico.servicos.findIndex(x => x.id === dialogVerItem.data.id && x.item === dialogVerItem.data.item);
            if (index >= 0) {
                ordemServico.servicos[index].status = 'Pendente';
                ordemServico.servicos[index].conclusao = null;
                if(index < ordemServico.servicos.length ){
                    ordemServico.servicos[index].reabretask = true;
                    ordemServico.servicos[index].enviaEmail = false;
                }
                setData({
                    ...data,
                    isLoading: true
                });

                OrdensServico.update(ordemServico._id, ordemServico).then(success => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Tarefa reaberta com sucesso"
                    });
                    applyFilters();
                }).catch(error => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao reabrir a tarefa. Tente novamente")
                    });
                    applyFilters();
                });
            }
        }).catch(error => {
            console.log(error);
        })

        setDialogVerItem({
            data: {},
            open: false,
            readOnly: true
        })
    }

    const handleConcluirTarefa = (event) => {
        OrdensServico.retrieve(dialogConcluir.data.ordemServico).then(ordemServico => {
            let index = ordemServico.servicos.findIndex(x => x.id === dialogConcluir.data.id && x.item === dialogConcluir.data.item);
            if (index >= 0) {
               
                ordemServico.servicos[index].status = 'Concluído';
                ordemServico.servicos[index].conclusao = moment().toISOString();
                ordemServico.servicos[index].filesToUpload = dialogConcluir.data.filesToUpload;
                if(index < ordemServico.servicos.length ){
                    ordemServico.servicos[index].enviaEmail = true;
                    ordemServico.servicos[index].reabretask = false;
                }
                setData({
                    ...data,
                    isLoading: true
                });

                OrdensServico.update(ordemServico._id, ordemServico).then(success => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Tarefa concluída com sucesso"
                    });
                    applyFilters();
                }).catch(error => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao concluir a tarefa. Tente novamente")
                    });
                    applyFilters();
                });
            }
        }).catch(error => {
            console.log(error);
        })

        setDialogConcluir({
            data: {},
            open: false
        })
    }

    const handleUploadFileConcluirTarefa = (newFiles) => {
        let updateConcluir = { ...dialogConcluir };
        updateConcluir.data.filesToUpload = updateConcluir.data.filesToUpload ? updateConcluir.data.filesToUpload.concat(newFiles) : newFiles;
        setDialogConcluir(updateConcluir);
    }

    const handleDeleteFileConcluirTarefa = (file) => {
        let updateConcluir = { ...dialogConcluir };
        updateConcluir.data.filesToUpload.splice(updateConcluir.data.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setDialogConcluir(updateConcluir);
    }

    const handleApplyFilters = () => {
        applyFilters();
    }

    const handleDismissFilters = () => {
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            status: ['Pendente']
        });

        setData({
            ...data,
            filters: "&status=Pendente"
        })

        document.getElementById('search').value = '';
    }

    const handleKeyUpMensagemItem = (event) => {
        if (event.keyCode === 13 && event.target.value !== "") {
            const updateItem = { ...dialogVerItem };

            if (!updateItem.data.mensagens) {
                updateItem.data.mensagens = [];
            }

            updateItem.data.mensagens.push({
                exibeCliente: false,
                data: moment().toISOString(),
                mensagem: event.target.value,
                responsavel: user?.nome
            });

            OrdensServico.retrieve(dialogVerItem.data.ordemServico).then(ordemServico => {
                const index = ordemServico.servicos.findIndex(x => x.id === dialogVerItem.data.id && x.item === dialogVerItem.data.item);
                if (index >= 0) {
                    ordemServico.servicos[index].mensagens = updateItem.data.mensagens;
    
                    OrdensServico.update(ordemServico._id, ordemServico).then(success => {
                        setResponseStatus({
                            open: true,
                            severity: "success",
                            message: "Mensagem incluída com sucesso"
                        });
                        setDialogVerItem(updateItem);
                        document.getElementById("adiciona-mensagem").value = '';
                    }).catch(error => {
                        setResponseStatus({
                            open: true,
                            severity: "error",
                            message: (error.message ? error.message : "Ocorreu um problema ao incluir a mensagem. Tente novamente")
                        });
                    });
                }
            }).catch(error => {
                console.log(error);
            })
        }

    }

    const handleClickDeleteMensagemItem = (event, index) => {
        const updateItem = { ...dialogVerItem };
        updateItem.data.mensagens.splice(index, 1);

        OrdensServico.retrieve(dialogVerItem.data.ordemServico).then(ordemServico => {
            const index = ordemServico.servicos.findIndex(x => x.id === dialogVerItem.data.id && x.item === dialogVerItem.data.item);
            if (index >= 0) {
                ordemServico.servicos[index].mensagens = updateItem.data.mensagens;

                OrdensServico.update(ordemServico._id, ordemServico).then(success => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Mensagem excluída com sucesso"
                    });
                    setDialogVerItem(updateItem);
                }).catch(error => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao excluir a mensagem. Tente novamente")
                    });
                });
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleUploadFileItem = (newFiles) => {
        const updateItem = { ...dialogVerItem };
        updateItem.data.filesToUpload = updateItem.data.filesToUpload ? updateItem.data.filesToUpload.concat(newFiles) : newFiles;

        OrdensServico.retrieve(dialogVerItem.data.ordemServico).then(ordemServico => {
            const index = ordemServico.servicos.findIndex(x => x.id === dialogVerItem.data.id && x.item === dialogVerItem.data.item);
            if (index >= 0) {
                ordemServico.servicos[index].filesToUpload = updateItem.data.filesToUpload;

                OrdensServico.update(ordemServico._id, ordemServico).then(success => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Arquivo incluído com sucesso"
                    });

                    delete updateItem.data.filesToUpload;
                    updateItem.data.anexos = success.servicos.find(x => x.id === dialogVerItem.data.id && x.item === dialogVerItem.data.item).anexos;
                    setDialogVerItem(updateItem);
                }).catch(error => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao incluir o arquivo. Tente novamente")
                    });
                });
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const handleClickDeleteFileItem = (event, index) => {
        const updateItem = { ...dialogVerItem };
        updateItem.data.anexos.splice(index, 1);
        OrdensServico.retrieve(dialogVerItem.data.ordemServico).then(ordemServico => {
            const index = ordemServico.servicos.findIndex(x => x.id === dialogVerItem.data.id && x.item === dialogVerItem.data.item);
            if (index >= 0) {
                ordemServico.servicos[index].anexos = updateItem.data.anexos;

                OrdensServico.update(ordemServico._id, ordemServico).then(success => {
                    setResponseStatus({
                        open: true,
                        severity: "success",
                        message: "Arquivo excluído com sucesso"
                    });
                    setDialogVerItem(updateItem);
                }).catch(error => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao excluir o arquivo. Tente novamente")
                    });
                });
            }
        }).catch(error => {
            console.log(error);
        })
    }
    
    const handleChangeAnexoVisibility = (event, index) => {
        const updateItem = { ...dialogVerItem };
        updateItem.data.anexos[index].exibeCliente = !!!updateItem.data.anexos[index].exibeCliente;
        setDialogVerItem(updateItem);
    }

    const handleChangeMensagemVisibility = (event, index) => {
        const updateItem = { ...dialogVerItem };
        updateItem.data.mensagens[index].exibeCliente = !!!updateItem.data.mensagens[index].exibeCliente;
        setDialogVerItem(updateItem);
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Tarefas");

        if (user) Listar(data.page, data.size, data.filters);

    }, [data.page, data.size, data.filters, Listar, user]);

    const columns = [
        {
            id: 'prazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'nome',
            label: 'Nome',
            align: 'left'
        },
        {
            id: 'data',
            label: 'Data Solicitação',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'nomeCliente',
            label: 'Cliente',
            align: 'left'
        },
        {
            id: 'responsavelItem',
            label: 'Responsável',
            align: 'left',
            format: (responsavel) => responsavel.nome
        },
        {
            id: 'observacao',
            label: 'Observação',
            align: 'left'
        },
        {
            id: 'status',
            label: 'Situação',
            align: 'center',
            format: (value) => (
                value === 'Pendente' ?
                    <Chip size="small" label={value}
                        style={{
                            color: 'white',
                            backgroundColor: red[500],
                            borderRadius: '4px'
                        }}
                    />
                    :
                    <Chip size="small" label={value}
                        style={{
                            color: 'white',
                            backgroundColor: green[800],
                            borderRadius: '4px'
                        }}
                    />
            )
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <div key={'tarefas-' + index} style={{ minWidth: '135px' }}>
                    <IconButton
                        aria-label="Mais informações"
                        title="Mais informações"
                        onClick={() => setDialogVerItem({
                            data: {
                                ...doc
                            },
                            open: true,
                            readOnly: true
                        })}
                    >
                        <VisibilityIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="Ver OS" title="Ver OS" href={'/os/' + doc.ordemServico}>
                        <DescriptionIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="Concluir tarefa"
                        title="Concluir tarefa"
                        onClick={() => setDialogConcluir({
                            data: {
                                ...doc,
                                filesToUpload: []
                            },
                            open: true
                        })}
                        disabled={!!doc.conclusao}
                    >
                        <DoneIcon color="primary" fontSize="small" />
                    </IconButton>
                </div>
            )
        },
    ];

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            label: 'Pesquisar',
            defaultValue: filters.search,
            onKeyUp: handleKeyUpSearch
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Prazo de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Prazo até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'select',
            id: 'situacao',
            label: 'Situação',
            options: ["Pendente", "Concluído"],
            value: filters.status,
            onChange: (e) => { setFilters({ ...filters, status: e.target.value }) }
        },
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            {!isLoading && docs ?
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />
                :
                isLoading && <Loading open={isLoading} />
            }
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <EditaItemOrdemServico
                readOnly={dialogVerItem.readOnly}
                open={dialogVerItem.open}
                onClose={() => setDialogVerItem({
                    data: {},
                    open: false
                })}
                data={dialogVerItem.data}
                reopenTask={handleReopenTask}
                confirm={() => setDialogVerItem({
                    data: {},
                    open: false
                })}
                handleKeyUpMensagem={handleKeyUpMensagemItem}
                handleClickDeleteMensagem={handleClickDeleteMensagemItem}
                handleUploadFile={handleUploadFileItem}
                handleClickDeleteFile={handleClickDeleteFileItem}
                handleChangeAnexoVisibility={handleChangeAnexoVisibility}
                handleChangeMensagemVisibility={handleChangeMensagemVisibility}
            />
            <ConcluiTarefa
                open={dialogConcluir.open}
                onClose={() => setDialogConcluir({
                    data: {},
                    open: false
                })}
                data={dialogConcluir.data}
                confirm={handleConcluirTarefa}
                cancel={() => setDialogConcluir({
                    data: {},
                    open: false
                })}
                handleUploadFile={handleUploadFileConcluirTarefa}
                handleDeleteFile={handleDeleteFileConcluirTarefa}
            />
        </>
    )
}

export default ListaTarefas;