// import { createGlobalStyle } from 'styled-components';

// export default createGlobalStyle`
//     * {
//         margin: 0;
//         padding: 0;
//         outline: 0;
//         box-sizing: border-box;
//     }

//     html, body, #root {
//         height: 100%;
//         font-size: .9rem;
//     }

//     body {
//         font-family: 'Maven Pro', sans-serif !important;
//         background: #ecf1f8;
//         color: #333;
//         -webkit-font-smoothing; antialiased !important;
//     }

//     ul {
//         list-style: none;
//     }

//     @media print {
//         body {
//             background: #FFF;
//         }
//     }
// `;

import { createTheme } from "@material-ui/core/styles";

const customTheme = createTheme({
    typography: {
        fontFamily: '"Maven Pro", sans-serif !important',
        fontSize: 13,
        fontWeight: 500,
        color: '#333'
    },
    overrides: {
        MuiContainer: {
            root: {
                position: 'absolute',
                left: '0px',
                right: '0px'
            }
        },
        MuiTab: {
            root: {
                textTransform: 'none'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiTypography: {
            h6: {
                fontSize: 14
            }
        },
        MuiAccordion: {
            root: {
                '&.Mui-expanded': {
                    margin: 0
                }
            }
        },
        MuiCssBaseline: {
            '@global': {
                html: {
                    height: '100%',
                },
                body: {
                    backgroundColor: '#ecf1f8',
                    height: '100%'
                },
                ul: {
                    listStyle: 'none'
                }
            }
        }
    }
});

export default customTheme;