import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core';

import { DropzoneAreaBase } from 'material-ui-dropzone';

import Servicos from '../../services/Servicos';

function ConcluiTarefa(props) {
    const [servico, setServico] = useState(null);


    useEffect(() => {
        if (props.data.id) {
            Servicos.retrieve(props.data.id).then(result => {
                setServico(result);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [props.data.id])

    return (servico &&
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Concluir Tarefa - {props.data.nome}</DialogTitle>

            {servico.anexo === 'Sim' ?
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Para concluir esta tarefa é necessário anexar documento(s)
                    </DialogContentText>
                    <DropzoneAreaBase
                        filesLimit={10}
                        fileObjects={props.data.filesToUpload}
                        dropzoneText="Clique ou arraste os arquivos aqui (max 10)"
                        maxFileSize={200000000}
                        onAdd={props.handleUploadFile}
                        onDelete={props.handleDeleteFile}
                        showPreviews={false}
                        showFileNamesInPreview={true}
                        useChipsForPreview
                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                        previewText="Arquivos selecionados"

                    />
                </DialogContent>
                :
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Tem certeza que deseja concluir esta tarefa?
                    </DialogContentText>
                </DialogContent>
            }

            <DialogActions>
                <Button
                    onClick={props.confirm}
                    color="primary"
                    autoFocus
                    disabled={(props.data.exigeAnexo === 'Sim' && props.data.filesToUpload.length <= 0)}
                >
                    Ok
                    </Button>
                <Button onClick={props.cancel}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConcluiTarefa;