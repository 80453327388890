const user = JSON.parse(localStorage.getItem('@stk/user'));

const list = (page = 0, limit = 30, filters = '') => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const retrieve = (id) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/' + id;
        let options = {
            method: 'GET',
            headers: headers
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const create = (ordemServico) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os';
        let options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(ordemServico)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const update = (id, ordemServico) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/' + id;
        let options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(ordemServico)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const remove = (id) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/' + id;
        let options = {
            method: 'DELETE',
            headers: headers,
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const listaTarefas = (page = 0, limit = 30, filters = '') => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/tarefas?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const listaUltimaMensagem = (page = 0, limit = 30, filters = '') => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);
        

        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/ultima-mensagem?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        const response = await fetch(url, options);
        const result = await response.json();
      

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}
const listaTodasMensagem = (page = 0, limit = 30, filters = '') => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);
        
        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/todas-mensagem?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };
       

        const response = await fetch(url, options);
        const result = await response.json();
      
        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}
const listaOsconcluidas = (page = 0, limit = 30, filters = '') => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);
        
        let url = process.env.REACT_APP_BASE_URL_API + '/api/os/conclusao-os?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };
       

        const response = await fetch(url, options);
        const result = await response.json();
      
        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const obj = { list, retrieve, create, update, remove, listaTarefas, listaUltimaMensagem,listaTodasMensagem,listaOsconcluidas };

export default obj;