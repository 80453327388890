import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import {
    Button,
    Box,
    Tabs,
    Tab,
    TextField,
    Grid,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton
} from "@material-ui/core";

import {
    Alert
} from "@material-ui/lab";

import { useForm } from "react-hook-form";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"

import { Container, Paper } from "./styles";

import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import NumberField from "../../components/NumberField";
import Baixa from "./baixa";

import { DateField, CurrencyField } from '../../components/MaskedFields';
import { OrdensServicoComboBox, PessoasComboBox } from '../../components/DataComboBox';

import ContasPagar from "../../services/ContasPagar";
import { useAuth } from '../../AuthContext';

import moment from "moment";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditaContasPagar = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const [tab, setTab] = useState(0);
    const { register, handleSubmit, errors } = useForm();
    const [pagar, setPagar] = useState({});
    const [loading, setLoading] = useState(true);
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [filterOrdemServico, setFilterOrdemServico] = useState("");
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [dialogBaixa, setDialogBaixa] = useState({
        data: {},
        open: false
    });

    const saveForm = () => {
        setLoading(true);

        if (id === 'new') {
            ContasPagar.create(pagar).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro realizado com sucesso"
                });

                ContasPagar.retrieve(response._id).then(response => {
                    setPagar(response);
                    setFilterOrdemServico("&solicitante[_id]=" + response.pessoa._id)
                    setLoading(false);
                    window.location.href = '/pagar/' + response._id;
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        } else {
            ContasPagar.update(pagar._id, pagar).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                if (pagar.compensacao) {
                    ContasPagar.create(pagar.compensacao).then(response => {
                        ContasPagar.retrieve(response._id).then(response => {
                            setPagar(response);
                            setFilterOrdemServico("&solicitante[_id]=" + response.pessoa._id)
                            setLoading(false);
                        }).catch((error) => {
                            console.log(error);
                        });
                    }).catch((error) => {
                        console.log(error);
                        setResponseStatus({
                            open: true,
                            severity: "error",
                            message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                        });
                        setLoading(false);
                    });
                }
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        }
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleChangeField = (event) => {
        let updatePagar = { ...pagar };
        updatePagar[event.target.name] = event.target.value;

        if (event.target.name === 'valor') {
            let valor = event.target.value ? event.target.value : 0;
            updatePagar['saldo'] += valor - updatePagar['saldo'];
        }

        setPagar(updatePagar);
    }

    const handleChangeDate = (date, field) => {
        if (date) {
            let updatePagar = { ...pagar };
            updatePagar[field] = date.toISOString();
            if (field === 'vencimento') {
                updatePagar['vencimento_real'] = date.toISOString();
            }
            setPagar(updatePagar);
        }
    }

    const handleChangeCliente = (event, cliente) => {
        if (cliente) {
            let updatePagar = { ...pagar };
            updatePagar.pessoa = cliente._id;
            setPagar(updatePagar);
            setFilterOrdemServico("&solicitante[_id]=" + cliente._id);
        }
    }

    const handleChangeOrdemServico = (event, ordemServico) => {
        if (ordemServico) {
            let updatePagar = { ...pagar };
            updatePagar.ordemServico = ordemServico._id;
            updatePagar.ordemServicoNumero = ordemServico.numero;
            setPagar(updatePagar);
        }
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: pagar.descricao,
            id: pagar._id
        })
    }

    const handleDeleteContaPagar = (event) => {
        setLoading(true);

        ContasPagar.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/servicos';
        }).catch(error => {
            setLoading(false);

            setRemove({
                open: false,
                name: '',
                id: ''
            });

            setResponseStatus({
                open: true,
                severity: "error",
                message: (error.message ? error.message : "Ocorreu um problema ao excluir o cadastro. Tente novamente")
            });
        });
    }

    const handleClickDeleteBaixa = (event, index, baixa) => {
        let updatePagar = { ...pagar };
        updatePagar.saldo += baixa.valor_baixa;
        updatePagar.baixas.splice(index, 1);
        setPagar(updatePagar);
    }


    const handleClickConfirmDialogBaixa = (event) => {
        let updatePagar = { ...pagar };
        let updateBaixa = { ...dialogBaixa.data };

        updatePagar.baixas.push(updateBaixa);
        updatePagar.saldo -= updateBaixa.valor_baixa;

        if (updatePagar.ordemServico) {
            updatePagar.compensacao = {
                ...pagar,
                tipoConta: 'Receber'
            }
            delete updatePagar.compensacao._id;
            delete updatePagar.compensacao.numero;
        }
        console.log(updatePagar);
        setPagar(updatePagar);
        setDialogBaixa({
            data: {},
            open: false
        })
    }

    const handleChangeFieldBaixa = (event) => {
        let field = event.target.name;
        let updateBaixa = { ...dialogBaixa };
        updateBaixa.data[field] = event.target.value;
        setDialogBaixa(updateBaixa);
    }

    const handleChangeDateBaixa = (date, field) => {
        let updateBaixa = { ...dialogBaixa };
        updateBaixa.data[field] = date.toISOString();
        setDialogBaixa(updateBaixa);
    }


    const columnsBaixas = [
        {
            id: 'data_baixa',
            label: 'Data',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'historico',
            label: 'Histórico',
            align: 'left'
        },
        {
            id: 'valor_baixa',
            label: 'Valor',
            align: 'right',
            format: (value) => (
                <NumberField
                    value={value}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'desconto',
            label: 'Desconto',
            align: 'right',
            format: (value) => (
                <NumberField
                    value={value}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'juros',
            label: 'Juros',
            align: 'right',
            format: (value) => (
                <NumberField
                    value={value}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'multa',
            label: 'Multa',
            align: 'right',
            format: (value) => (
                <NumberField
                    value={value}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <IconButton
                    aria-label="Excluir"
                    color="secondary"
                    size="small"
                    onClick={e => handleClickDeleteBaixa(e, index, doc)}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
            )
        }
    ]

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button',
            disabled: !user?.permissoes["Contas-a-Pagar-excluir"]
        }
    ]

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar conta a pagar");
        if (id !== 'new') {
            ContasPagar.retrieve(id).then((response) => {
                setPagar(response);
                setFilterOrdemServico("&solicitante[_id]=" + response.pessoa._id)
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setPagar({
                tipoConta: 'Pagar',
                emissao: moment().toISOString(),
                vencimento: moment().toISOString(),
                vencimento_real: moment().toISOString(),
                saldo: 0,
                baixas: []
            })
            setLoading(false);
        }
    }, [id]);


    return !loading ? (
        <Container>
            <Paper square>
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar pagar"
                    >
                        <Tab label="Informações" {...a11yProps(0)} />
                        <Tab label="Baixas" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="numero"
                                    label="Número"
                                    size="small"
                                    defaultValue={pagar.numero}
                                    fullWidth
                                    onChange={handleChangeField}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="descricao"
                                    label="Descrição"
                                    size="small"
                                    defaultValue={pagar.descricao}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.descricao}
                                    helperText={errors.descricao?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PessoasComboBox
                                    name="cliente"
                                    onChange={handleChangeCliente}
                                    defaultValue={pagar.pessoa &&
                                    {
                                        _id: pagar.pessoa._id,
                                        nome: pagar.pessoa.nome
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <OrdensServicoComboBox
                                    name="ordemServico"
                                    onChange={handleChangeOrdemServico}
                                    filter={filterOrdemServico}
                                    defaultValue={pagar.ordemServico &&
                                    {
                                        _id: pagar.ordemServico._id,
                                        numero: pagar.ordemServico.numero,
                                        descricao: pagar.ordemServico.descricao
                                    }
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DateField
                                    name="emissao"
                                    label="Data Emissão"
                                    value={pagar.emissao}
                                    onChange={handleChangeDate}
                                    fullWidth
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.emissao}
                                    helperText={errors.emissao?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DateField
                                    name="vencimento"
                                    label="Data Vencimento"
                                    value={pagar.vencimento}
                                    onChange={handleChangeDate}
                                    fullWidth
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.vencimento}
                                    helperText={errors.vencimento?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="forma-label">Forma Pagamento</InputLabel>
                                    <Select
                                        name="metodoPagamento"
                                        labelid="forma-label"
                                        size="small"
                                        value={pagar.metodoPagamento ? pagar.metodoPagamento : 'Boleto'}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="Boleto" value="Boleto">Boleto</MenuItem>
                                        <MenuItem key="Cartão Crédito" value="Cartão Crédito">Cartão Crédito</MenuItem>
                                        <MenuItem key="Cartão Débito" value="Cartão Débito">Cartão Débito</MenuItem>
                                        <MenuItem key="Cheque" value="Cheque">Cheque</MenuItem>
                                        <MenuItem key="Dinheiro" value="Dinheiro">Dinheiro</MenuItem>
                                        <MenuItem key="Transferência Bancária" value="Transferência Bancária">Transferência Bancária</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CurrencyField
                                    name="valor"
                                    label="Valor"
                                    value={pagar.valor}
                                    defaultValue={pagar.valor}
                                    onChange={handleChangeField}
                                    fullWidth
                                    disabled={pagar.baixas.length > 0}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.valor}
                                    helperText={errors.valor?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CurrencyField
                                    name="saldo"
                                    label="Saldo"
                                    value={pagar.saldo}
                                    defaultValue={pagar.saldo}
                                    onChange={handleChangeField}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        {user?.permissoes["Contas-a-Pagar-alterar"] && (
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ marginBottom: '20px' }}
                                onClick={() => setDialogBaixa({
                                    data: {
                                        data_baixa: moment().toISOString(),
                                        historico: 'Baixa de conta a pagar',
                                        valor_baixa: pagar.saldo,
                                        desconto: 0,
                                        multa: 0,
                                        juros: 0,
                                        saldo: pagar.saldo
                                    },
                                    open: true
                                })}
                                disabled={pagar.saldo <= 0}
                            >

                                {id === 'new' || pagar.saldo > 0 ? 'Adicionar baixa' : 'Conta baixada'}
                            </Button>
                        )}
                        <DataTable
                            columns={columnsBaixas}
                            docs={pagar.baixas}
                        />
                    </TabPanel>
                    {(user?.permissoes["Contas-a-Pagar-alterar"] || (user?.permissoes["Contas-a-Pagar-incluir"] && id === 'new')) && (
                        <ActionMenu actions={menuActions} />
                    )}
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteContaPagar}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
            <Baixa
                open={dialogBaixa.open}
                onClose={() => setDialogBaixa({
                    data: {},
                    open: false
                })}
                data={dialogBaixa.data}
                confirm={handleClickConfirmDialogBaixa}
                cancel={() => setDialogBaixa({
                    data: {},
                    open: false
                })}
                handleChangeField={handleChangeFieldBaixa}
                handleChangeDateField={handleChangeDateBaixa}
            />
        </Container>
    ) :
        (loading && (
            <Loading open={loading} />
        ))
};

export default EditaContasPagar;