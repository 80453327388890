import React, { useState, useEffect, useCallback } from 'react';
import NumberFormat from 'react-number-format';
import {
    InputAdornment,
    IconButton
} from '@material-ui/core';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ActionMenu from '../../components/ActionMenu';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import Pessoas from '../../services/Pessoas';
import { useAuth } from '../../AuthContext';

const ListaPessoas = (props) => {
    const { user } = useAuth()

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0
    })

    const Listar = useCallback((page, size, filter) => {
        Pessoas.list(page, size, filter).then(pessoas => {
            setData({
                isLoading: false,
                docs: pessoas.docs,
                page: pessoas.page - 1,
                size: pessoas.limit,
                count: pessoas.totalDocs
            });
        }).catch(error => {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Pessoas");
        Listar(data.page, data.size);
    }, [data.page, data.size, Listar]);

    const handleChangePage = (event, newPage) => {
        const { size } = data;
        Listar(newPage, size);
    }

    const handleChangeSize = (event) => {
        Listar(0, +event.target.value);
    }

    const applyFilters = (searchExpression) => {
        let filters = "";

        if (searchExpression) {
            filters += "&$or[0][nome][$regex]=" + searchExpression;
            filters += "&$or[0][nome][$options]=gi";
            filters += "&$or[1][cpf][$regex]=" + searchExpression;
            filters += "&$or[1][cpf][$options]=gi";
        }

        Listar(data.page, data.size, filters);
    }

    const handleKeyUpSearch = (event) => {
        if (event.keyCode === 13) applyFilters(event.target.value);
    }

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeletePessoa = (event) => {
        Pessoas.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            let searchExpression = document.getElementById("search").value;
            applyFilters(searchExpression);
        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            id: 'nome',
            label: 'Nome',
            align: 'left'
        },
        {
            id: 'cpf',
            label: 'CPF/CNPJ',
            align: 'center',
            format: (value) => <NumberFormat value={value} displayType={'text'} format={value.length === 11 ? '###.###.###-##' : '##.###.###/####-##'} />
        },
        {
            id: 'email',
            label: 'E-mail',
            align: 'center'
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <>
                    <IconButton aria-label="Editar" href={'/pessoas/' + doc._id}>
                        {user?.permissoes["Pessoas-alterar"] ? (
                            <EditIcon color="primary" />
                        ) : (
                            <VisibilityIcon color="primary" />
                        )}
                    </IconButton>
                    {user?.permissoes["Pessoas-excluir"] &&
                        <IconButton aria-label="Excluir" onClick={e => handleClickDelete(e, doc._id, doc.nome)} >
                            <DeleteIcon color="secondary" />
                        </IconButton>
                    }
                </>
            )
        },
    ];

    const filters = [
        {
            type: 'search',
            id: 'search',
            label: 'Filtrar por nome ou CPF/CNPJ',
            width: 300,
            InputProps: {
                startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>,
            },
            onKeyUp: handleKeyUpSearch
        }
    ];

    const handleApplyFilters = () => {
        let searchExpression = document.getElementById("search").value;
        applyFilters(searchExpression);
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        applyFilters();
    }

    const { isLoading, docs, page, size, count, error } = data;

    return (!isLoading && docs ?
        <>
            <FilterContainer
                title="Filtros"
                filters={filters}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            <DataTable
                columns={columns}
                docs={docs}
                page={page}
                size={size}
                count={count}
                error={error}
                handleChangePage={handleChangePage}
                handleChangeSize={handleChangeSize}
            />
            {user?.permissoes["Pessoas-incluir"] &&
                <ActionMenu
                    actions={[
                        {
                            icon: <IconButton href="/pessoas/new"><DescriptionOutlinedIcon /></IconButton>,
                            title: 'Incluir',
                            type: 'button'
                        }
                    ]}
                />
            }
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeletePessoa}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </>
        :
        isLoading && <Loading open={isLoading} />
    )
}

export default ListaPessoas;