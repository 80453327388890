import React from 'react';
import ReactToPrint from 'react-to-print';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

import moment from 'moment';
import 'devbox-linq';

function Imprimir(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"lg"}
            fullWidth
        >
            <DialogContent>
                <Grid container spacing={3} id="imprimir" style={{ padding: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK" height="80" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography variant="h6" align="center" style={{ fontSize: '0.7rem' }}>
                            <b>Despesas em Aberto</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography variant="h6" align="left" style={{ fontSize: '0.7rem' }}>
                      
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow key="datatable-header">
                                <TableCell style={{ fontSize: '0.7rem' }} key="vencimento" align="center"><b>Vencimento</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="descricao" align="center"><b>Descrição</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="pessoa" align="center"><b>Pessoa</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="numero" align="center"><b>Número</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="emissao" align="center"><b>Emissão</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="valor" align="center"><b>Valor Original</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="saldo" align="center"><b>Saldo</b></TableCell>
                                <TableCell style={{ fontSize: '0.7rem' }} key="situacao" align="center"><b>Situação</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.docs?.map((tarefa, index) => (
                                    <TableRow key={'tarefa-' + index}>
                                                           
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center">{moment(tarefa.vencimento).format('DD/MM/YYYY')}</TableCell>
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.descricao}</TableCell> 
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.pessoa.nome}</TableCell> 
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.numero}</TableCell> 
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center">{moment(tarefa.emissao).format('DD/MM/YYYY')}</TableCell>
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center"> R$ {tarefa.valor} </TableCell>
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center"> R$ {tarefa.saldo}</TableCell> 
                                       <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.situacao}</TableCell> 

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item xs={12} style={{ fontSize: '0.7rem' }}>
                        <p>São Bento do Sul, {moment().format("DD [de] MMMM [de] YYYY")}</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.confirm &&
                    <ReactToPrint
                        trigger={() => {
                            return <Button color="primary" autoFocus>Imprimir</Button>
                        }}
                        content={() => document.getElementById("imprimir")}
                    />}
                {props.cancel && <Button onClick={props.cancel}>Cancelar</Button>}
            </DialogActions>
        </Dialog >
    )
}

export default Imprimir;