import styled from 'styled-components';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTablePagination from '@material-ui/core/TablePagination';
import MuiTableRow from '@material-ui/core/TableRow';

export const Table = styled(MuiTable)`
`;

export const TableCell = styled(MuiTableCell)`

`;

export const TableHeaderCell = styled(MuiTableCell)`
    
`;

export const TableBody = styled(MuiTableBody)`
`;

export const TableContainer = styled(MuiTableContainer)`

`;

export const TableHead = styled(MuiTableHead)`
`;

export const TablePagination = styled(MuiTablePagination)`
    & .MuiTablePagination-caption, .MuiTablePagination-input, .MuiMenuItem-root {
        font-family: 'Open Sans', sans-serif !important;
        font-weight: bold !important;
        font-size: 0.7rem !important;
    }
`;

export const TableRow = styled(MuiTableRow)`
`;