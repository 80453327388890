import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const Container = styled.li`
    &:hover {
        cursor: pointer;
        background: #dae1ea;
    }
`;

export const Link = styled.a`
    padding: 15px 30px;
    width: 100%
    height: 100%;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
`;

export const Accordion = styled(MuiAccordion)`
    background: transparent !important;
    box-shadow: none !important;
    margin: 0px !important;
    border: none !important;
    &:before {
        background: transparent !important;
    }

    & .MuiAccordionDetails-root {
        padding: 0px !important;
    }
`;
export const Menu = styled(MuiAccordionSummary)`
    padding: 0px 10px 0px 30px !important;
`;