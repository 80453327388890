const user = JSON.parse(localStorage.getItem('@stk/user'));

const list = (page = 0, limit = 30, filters = '') => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/solicitacoes?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });

    })
}

const retrieve = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/solicitacoes/' + id;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const create = (solicitacao) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/solicitacoes';
        let options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(solicitacao)
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if(data._id){
                    resolve(data);
                } else {
                    reject(data);
                }
            }).catch(error => {
                reject(error);
            });
    })
}

const update = (id, solicitacao) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/solicitacoes/' + id;
        let options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(solicitacao)
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if(data._id){
                    resolve(data);
                } else {
                    reject(data);
                }
            }).catch(error => {
                reject(error);
            });
    })
}

const remove = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/solicitacoes/' + id;
        let options = {
            method: 'DELETE',
            headers: headers,
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                if(data._id){
                    resolve(data);
                } else {
                    reject(data);
                }
            }).catch(error => {
                reject(error);
            });
    })
}

const obj = { list, retrieve, create, update, remove };

export default obj;