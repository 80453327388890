import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Hidden,
    Drawer,
    Box,
    Typography
} from '@material-ui/core';

import { OrdemServicoHeader } from '../../components/DataComboBox';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import MainMenu from '../MainMenu';
import UserMenu from '../UserMenu';

import { useAuth } from '../../AuthContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: '#007cc3',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // userMenu: {
    //     position: 'absolute',
    //     right: '10px'
    // },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        fontSize: 'inherit',
        flexGrow: 1,
        padding: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    search: {
        backgroundColor: 'white',
        width: '30%',
        marginLeft: 'auto',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },

    },
    searchDrawer: {
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },

    }
}));


const MainContainer = (props) => {
    const { user } = useAuth()
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK 3.0" height="60" />
            </div>
            <div className={classes.searchDrawer}>
                <OrdemServicoHeader placeholder="Pesquisar OS" />
            </div>
            <MainMenu />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        <IconButton href="./" size="small">
                            <ArrowBackIosIcon fontSize="small" style={{ color: 'white' }} />
                        </IconButton>
                        {sessionStorage.getItem("@stk/appTitle")}
                    </Typography>
                    <Box className={classes.search}>
                        {user?.permissoes["Serviços"] === true && user?.permissoes["Ordem-de-Serviço-visualizar"] === true && <OrdemServicoHeader placeholder="Pesquisar OS" />}
                    </Box>
                    <Box style={{ height: '100%' }} className={classes.userMenu}>
                        <UserMenu />
                    </Box>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </>
    )
}

export default MainContainer;