import React, { useState, useEffect } from "react";
import {
	IconButton,
	Snackbar,
	Box,
	useTheme,
	Grid,
	Card,
	CardContent,
	Typography,
	CardHeader,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import moment from "moment";

import FilterContainer from "../../components/FilterContainer";
import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import NumberField from "../../components/NumberField";

import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@material-ui/icons/Print";
import ArchiveIcon from "@material-ui/icons/Archive";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DotIcon from "@material-ui/icons/FiberManualRecord";

import ContasReceber from "../../services/ContasReceber";
import ImprimeRecibo from "../EditaOrdemServico/imprimeRecibo";
import Baixa from "../EditaContasReceber/baixa";

import { useAuth } from "../../AuthContext";

const ListaContasReceber = (props) => {
	const theme = useTheme();
	const { user } = useAuth();
	const [data, setData] = useState({
		isLoading: true,
		error: null,
		docs: [],
		totals: [],
		page: 0,
		size: 30,
		count: 0,
		filters: "&situacao[$in]=Em aberto,Em atraso",
	});

	const [filters, setFilters] = useState({
		search: "",
		dateFrom: null,
		dateTo: null,
		dueDateFrom: null,
		dueDateTo: null,
		status: ["Em aberto", "Em atraso"],
	});

	const [remove, setRemove] = useState({
		open: false,
		name: "",
		id: "",
	});

	const [responseStatus, setResponseStatus] = useState({
		open: false,
		severity: "success",
	});

	const [dialogImprimeRecibo, setDialogImprimeRecibo] = useState({
		data: {},
		open: false,
	});

	const [dialogBaixa, setDialogBaixa] = useState({
		data: {},
		open: false,
	});

	const Listar = (page, size, filter) => {
		setData((prevData) => {
			return {
				...prevData,
				isLoading: true,
			};
		});

		ContasReceber.list(page, size, filter).then((contasReceber) => {
			ContasReceber.totals(filter).then((totals) => {
				setData({
					isLoading: false,
					docs: contasReceber.docs,
					totals: totals,
					page: contasReceber.page - 1,
					size: contasReceber.limit,
					count: contasReceber.totalDocs,
					filters: filter,
				});
			}).catch((error) => {
				console.log(error);
			});
		}).catch((error) => {
			console.log(error);
		});
	};

	const handleChangePage = (event, newPage) => {
		setData({
			...data,
			page: newPage,
		});
	};

	const handleChangeSize = (event) => {
		setData({
			...data,
			size: +event.target.value,
		});
	};

	const handleClickDelete = (event, id, name) => {
		setRemove({
			open: true,
			name: name,
			id: id,
		});
	};

	const handleDeleteContaReceber = (event) => {
		ContasReceber.remove(remove.id)
			.then((response) => {
				setRemove({
					open: false,
					name: "",
					id: "",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const handleClickConfirmDialogBaixa = (event) => {
		setData({
			...data,
			isLoading: true,
		});

		ContasReceber.retrieve(dialogBaixa.data.titulo)
			.then((response) => {
				let updateBaixa = { ...dialogBaixa.data };
				let updateReceber = { ...response };
				delete updateBaixa.titulo;

				updateReceber.baixas.push(updateBaixa);
				updateReceber.saldo -= updateBaixa.valor_baixa;

				ContasReceber.update(updateReceber._id, updateReceber)
					.then((response) => {
						setResponseStatus({
							open: true,
							severity: "success",
							message: "Cadastro atualizado com sucesso",
						});

						Listar(data.page, data.size);
					})
					.catch((error) => {
						setResponseStatus({
							open: true,
							severity: "error",
							message: error.message
								? "Erro:" + error.message
								: "Ocorreu um problema ao atualizar o cadastro. Tente novamente",
						});

						Listar(data.page, data.size);
					});
			})
			.catch((error) => {
				console.log(error);
			});

		setDialogBaixa({
			data: {},
			open: false,
		});
	};

	const handleChangeFieldBaixa = (event) => {
		let field = event.target.name;
		let updateBaixa = { ...dialogBaixa };
		updateBaixa.data[field] = event.target.value;
		setDialogBaixa(updateBaixa);
	};

	const handleChangeDateBaixa = (date, field) => {
		let updateBaixa = { ...dialogBaixa };
		updateBaixa.data[field] = date.toISOString();
		setDialogBaixa(updateBaixa);
	};

	const handleCloseResponseStatus = () => {
		setResponseStatus({
			open: false,
			severity: "success",
		});
	};

	const handleKeyDownSearch = (event) => {
		setFilters({
			...filters,
			search: event.target.value,
		});

		if (event.keyCode === 13) {
			handleApplyFilters();
		}
	};

	const handleApplyFilters = () => {
		let qs = "";

		if (filters.search) {
			qs += "&$or[0][numero]=" + filters.search;
			qs += "&$or[1][descricao][$regex]=" + filters.search;
			qs += "&$or[1][descricao][$options]=gi";
			qs += "&$or[2][pessoa.cpf][$regex]=" + filters.search;
			qs += "&$or[2][pessoa.cpf][$options]=gi";
			qs += "&$or[3][pessoa.nome][$regex]=" + filters.search;
			qs += "&$or[3][pessoa.nome][$options]=gi";
		}

		if (filters.dateFrom) qs += "&emissao[$gte]=" + filters.dateFrom;
		if (filters.dateTo) qs += "&emissao[$lte]=" + filters.dateTo;
		if (filters.dueDateFrom) qs += "&vencimento[$gte]=" + filters.dueDateFrom;
		if (filters.dueDateTo) qs += "&vencimento[$lte]=" + filters.dueDateTo;
		if (filters.status.length > 0)
			qs += "&situacao[$in]=" + filters.status.join();

		setData({
			...data,
			filters: qs,
		});
	};

	const handleDismissFilters = () => {
		document.getElementById("search").value = "";
		setFilters({
			search: "",
			dateFrom: null,
			dateTo: null,
			dueDateFrom: null,
			dueDateTo: null,
			status: ["Em aberto", "Em atraso"],
		});

		setData({ ...data, filters: "&situacao[$in]=Em aberto,Em atraso" });
	};

	useEffect(() => {
		sessionStorage.setItem("@stk/appTitle", "Contas a Receber");
		Listar(data.page, data.size, data.filters);
	}, [data.page, data.size, data.filters]);

	const columns = [
		{
			id: "vencimento",
			label: "Vencimento",
			align: "center",
			format: (value) => new Date(value).toLocaleDateString("pt-BR"),
		},
		{
			id: "descricao",
			label: "Descrição",
			align: "left",
		},
		{
			id: "pessoa",
			label: "Cliente",
			align: "left",
			format: (pessoa) => pessoa?.nome,
		},
		{
			id: "numero",
			label: "Número",
			align: "center",
		},
		{
			id: "emissao",
			label: "Emissão",
			align: "center",
			format: (value) => (
				<>
					{new Date(value).toLocaleDateString("pt-BR")}
					<br />
					{new Date(value).toLocaleTimeString("pt-BR")}
				</>
			),
		},
		{
			id: "valor",
			label: "Valor Original",
			align: "right",
			format: (value) => <NumberField
				value={value}
				displayType="text"
				thousandSeparator="."
				decimalSeparator=","
				decimalScale={2}
				fixedDecimalScale={2}
				prefix="R$ "
			/>
		},
		{
			id: "saldo",
			label: "Saldo",
			align: "right",
			format: (value) => <NumberField
				value={value}
				displayType="text"
				thousandSeparator="."
				decimalSeparator=","
				decimalScale={2}
				fixedDecimalScale={2}
				prefix="R$ "
			/>
		},
		{
			id: "situacao",
			label: "Situação",
			align: "left",
			width: 150,
			format: (value) => (
				<Box display="flex" alignItems="center" flexWrap="wrap">
					{value === "Em aberto" && (
						<DotIcon style={{ color: theme.palette.warning.main }} />
					)}
					{value === "Em atraso" && <DotIcon color="secondary" />}
					{value === "Baixado" && (
						<DotIcon style={{ color: theme.palette.success.main }} />
					)}
					<span>{value}</span>
				</Box>
			),
		},
		{
			id: "_id",
			label: "Ações",
			align: "center",
			type: "hybrid",
			format: (doc) => (
				<div style={{ minWidth: "120px" }}>
					<IconButton
						title="Imprimir recibo"
						aria-label="Imprimir recibo"
						onClick={() =>
							setDialogImprimeRecibo({
								data: {
									...doc,
									cliente: doc.pessoa,
								},
								open: true,
							})
						}
						disabled={doc.valor === doc.saldo}
						size="small"
					>
						<PrintIcon color="primary" fontSize="small" />
					</IconButton>
					{user?.permissoes["Contas-a-Receber-alterar"] && (
						<IconButton
							title="Baixar"
							aria-label="Baixar"
							onClick={() =>
								setDialogBaixa({
									data: {
										titulo: doc._id,
										data_baixa: moment().toISOString(),
										historico: "Baixa de conta a receber",
										valor_baixa: doc.saldo,
										desconto: 0,
										multa: 0,
										juros: 0,
										saldo: doc.saldo,
									},
									open: true,
								})
							}
							disabled={doc.saldo <= 0}
							size="small"
						>
							<ArchiveIcon color="primary" fontSize="small" />
						</IconButton>
					)}
					<IconButton
						title="Editar"
						aria-label="Editar"
						href={"/receber/" + doc._id}
						size="small"
					>
						{user?.permissoes["Contas-a-Receber-alterar"] ? (
							<EditIcon color="primary" fontSize="small" />
						) : (
							<VisibilityIcon color="primary" fontSize="small" />
						)}
					</IconButton>
					{user?.permissoes["Contas-a-Receber-excluir"] && (
						<IconButton
							title="Excluir"
							aria-label="Excluir"
							onClick={(e) => handleClickDelete(e, doc._id, doc.descricao)}
							size="small"
						>
							<DeleteIcon color="secondary" fontSize="small" />
						</IconButton>
					)}
				</div>
			),
		},
	];

	const filterOptions = [
		{
			type: "search",
			id: "search",
			name: "search",
			label: "Pesquisar",
			onKeyUp: handleKeyDownSearch,
			onBlur: (e) => {
				setFilters({ ...filters, search: e.target.value });
			},
		},
		{
			type: "date",
			id: "dateFrom",
			name: "dateFrom",
			label: "Emissão de",
			value: filters.dateFrom,
			onChange: (value) => {
				setFilters({
					...filters,
					dateFrom: value?.startOf("day").toISOString(),
				});
			},
		},
		{
			type: "date",
			id: "dateTo",
			name: "dateTo",
			label: "Emissão até",
			value: filters.dateTo,
			onChange: (value) => {
				setFilters({ ...filters, dateTo: value?.endOf("day").toISOString() });
			},
		},
		{
			type: "date",
			id: "dueDateFrom",
			name: "dueDateFrom",
			label: "Vencimento de",
			value: filters.dueDateFrom,
			onChange: (value) => {
				setFilters({
					...filters,
					dueDateFrom: value?.startOf("day").toISOString(),
				});
			},
		},
		{
			type: "date",
			id: "dueDateTo",
			name: "dueDateTo",
			label: "Vencimento até",
			value: filters.dueDateTo,
			onChange: (value) => {
				setFilters({
					...filters,
					dueDateTo: value?.endOf("day").toISOString(),
				});
			},
		},
		{
			type: "select",
			id: "status",
			name: "status",
			label: "Situação",
			value: filters.status,
			options: ["Em aberto", "Em atraso", "Baixado"],
			onChange: (e) => {
				setFilters({ ...filters, status: e.target.value });
			},
		},
	];

	const { isLoading, docs, totals, page, size, count, error } = data;

	return (
		<>
			<FilterContainer
				title="Filtros"
				filters={filterOptions}
				handleApplyFilters={handleApplyFilters}
				handleDismissFilters={handleDismissFilters}
			/>
			<Grid container justify="center" spacing={3} style={{ marginBottom: "1px" }}>
				<Grid item xs={12} md={4}>
					<Card>
						<CardHeader style={{ background: theme.palette.success.dark, color: 'white' }} title="Valor original" align="center" titleTypographyProps={{ variant: 'body1' }} />
						<CardContent align="center">
							<Typography align="center" variant="h5">
								<NumberField
									value={totals.sum(x => x.total)}
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={2}
									prefix="R$ "
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card>
						<CardHeader style={{ background: theme.palette.warning.dark, color: 'white' }} title="Saldo a receber" align="center" titleTypographyProps={{ variant: 'body1' }} />
						<CardContent align="center">
							<Typography align="center" variant="h5">
								<NumberField
									value={totals.filter(x => x._id !== "Baixado").sum(x => x.saldo)}
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={2}
									prefix="R$ "
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card>
						<CardHeader style={{ background: theme.palette.error.dark, color: 'white' }} title="Saldo em atraso" align="center" titleTypographyProps={{ variant: 'body1' }} />
						<CardContent align="center">
							<Typography align="center" variant="h5">
								<NumberField
									value={totals.filter(x => x._id === "Em atraso").sum(x => x.saldo)}
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									decimalScale={2}
									fixedDecimalScale={2}
									prefix="R$ "
								/>
							</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
			{!isLoading ? (
				<DataTable
					columns={columns}
					docs={docs}
					page={page}
					size={size}
					count={count}
					error={error}
					handleChangePage={handleChangePage}
					handleChangeSize={handleChangeSize}
				/>
			) : (
				<Loading open={isLoading} />
			)}
			{user?.permissoes["Contas-a-Receber-incluir"] && (
				<ActionMenu
					actions={[
						{
							icon: (
								<IconButton href="/receber/new">
									<DescriptionOutlinedIcon />
								</IconButton>
							),
							title: "Incluir",
							type: "button",
						},
					]}
				/>
			)}
			<ConfirmationDialog
				title="Tem certeza que seja excluir?"
				confirmTitle="Sim"
				confirm={handleDeleteContaReceber}
				cancelTitle="Não"
				cancel={() => {
					setRemove({
						...remove,
						open: false,
					});
				}}
				open={remove.open}
				onClose={() => {
					setRemove({
						...remove,
						open: false,
					});
				}}
			>
				{remove.name}
			</ConfirmationDialog>
			<Snackbar
				open={responseStatus.open}
				autoHideDuration={3000}
				onClose={handleCloseResponseStatus}
			>
				<Alert
					elevation={6}
					variant="filled"
					onClose={handleCloseResponseStatus}
					severity={responseStatus.severity}
				>
					{responseStatus.message}
				</Alert>
			</Snackbar>
			<ImprimeRecibo
				open={dialogImprimeRecibo.open}
				onClose={() =>
					setDialogImprimeRecibo({
						data: {},
						open: false,
					})
				}
				data={dialogImprimeRecibo.data}
				confirm={() =>
					setDialogImprimeRecibo({
						data: {},
						open: false,
					})
				}
				cancel={() =>
					setDialogImprimeRecibo({
						data: {},
						open: false,
					})
				}
			/>
			<Baixa
				open={dialogBaixa.open}
				onClose={() =>
					setDialogBaixa({
						data: {},
						open: false,
					})
				}
				data={dialogBaixa.data}
				confirm={handleClickConfirmDialogBaixa}
				cancel={() =>
					setDialogBaixa({
						data: {},
						open: false,
					})
				}
				handleChangeField={handleChangeFieldBaixa}
				handleChangeDateField={handleChangeDateBaixa}
			/>
		</>
	);
};

export default ListaContasReceber;
