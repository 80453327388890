import React, { useState, useEffect, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";
import {
    InputAdornment,
    IconButton,
    Box,
    Tabs,
    Tab,
    Paper
} from '@material-ui/core';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ActionMenu from '../../components/ActionMenu';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Imoveis from '../../services/Imoveis';
import { useAuth } from '../../AuthContext';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ListaImoveis = (props) => {
    const { user } = useAuth();
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0
    })
    const [marcadores, setMarcadores] = useState([]);
    const [tab, setTab] = useState(0);
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const Listar = (page, size, filter) => {
        Imoveis.list(page, size, filter).then(imoveis => {
            setData({
                isLoading: false,
                docs: imoveis.docs,
                page: imoveis.page - 1,
                size: imoveis.limit,
                count: imoveis.totalDocs
            });
        }).catch(error => {
            console.log(error);
        });

        Imoveis.listMapMarkers().then(marcadores => {
            setMarcadores(marcadores);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Imóveis");
        Listar(data.page, data.size);
    }, [data.page, data.size]);

    const handleChangePage = (event, newPage) => {
        const { size } = data;
        Listar(newPage, size);
    }

    const handleChangeSize = (event) => {
        Listar(0, +event.target.value);
    }

    const applyFilters = (searchExpression) => {
        let filters = "";

        if (searchExpression) {
            filters += "&$or[0][matricula][$regex]=" + searchExpression;
            filters += "&$or[0][matricula][$options]=gi";
            filters += "&$or[1][descricao][$regex]=" + searchExpression;
            filters += "&$or[1][descricao][$options]=gi";
            filters += "&$or[2][endereco.endereco][$regex]=" + searchExpression;
            filters += "&$or[2][endereco.endereco][$options]=gi";
            filters += "&$or[3][endereco.bairro][$regex]=" + searchExpression;
            filters += "&$or[3][endereco.bairro][$options]=gi";
            filters += "&$or[4][endereco.cidade][$regex]=" + searchExpression;
            filters += "&$or[4][endereco.cidade][$options]=gi";
            filters += "&$or[5][endereco.estado][$regex]=" + searchExpression;
            filters += "&$or[5][endereco.estado][$options]=gi";

        }

        Listar(data.page, data.size, filters);
    }

    const handleKeyUpSearch = (event) => {
        if (event.keyCode === 13) applyFilters(event.target.value);
    }

    const handleApplyFilters = () => {
        let searchExpression = document.getElementById("search").value;
        applyFilters(searchExpression);
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        applyFilters();
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeleteImovel = (event) => {
        Imoveis.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            let searchExpression = document.getElementById("search").value;
            applyFilters(searchExpression);
        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            id: 'matricula',
            label: 'Matrícula',
            align: 'center',
            format: (value) => <NumberFormat format="##.###" value={value} displayType={'text'} />
        },
        {
            id: 'folha',
            label: 'Folha',
            align: 'center'
        },
        {
            id: 'livro',
            label: 'Livro',
            align: 'center'
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'proprietarios',
            label: 'Proprietários',
            align: 'left',
            format: (proprietarios) => (proprietarios.map(proprietario => (
                <Fragment key={"proprietario-" + proprietario.id}>
                    {proprietario.nome}
                    <br />
                </Fragment>
            )))
        },
        {
            id: 'endereco',
            label: 'Endereço',
            align: 'left',
            type: 'hybrid',
            format: (doc) => (doc.endereco.endereco)
        },
        {
            id: 'bairro',
            label: 'Bairro',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (doc.endereco.bairro)
        },
        {
            id: 'cidade',
            label: 'Cidade',
            align: 'left',
            type: 'hybrid',
            format: (doc) => (doc.endereco.cidade + '/' + doc.endereco.estado)
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <div style={{ width: '100px' }}>
                    <IconButton aria-label="Editar" href={'/imoveis/' + doc._id} color="primary">
                        {user?.permissoes["Imóveis-alterar"] ? (
                            <EditIcon />
                        ) : (
                                <VisibilityIcon />
                            )}
                    </IconButton>
                    {user?.permissoes["Imóveis-excluir"] && (
                        <IconButton aria-label="Editar" onClick={e => handleClickDelete(e, doc._id, doc.descricao)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            )
        },
    ];

    const filters = [
        {
            type: 'search',
            id: 'search',
            label: 'Digite a expressão para filtrar',
            width: 500,
            InputProps: {
                startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>,
            },
            onKeyUp: handleKeyUpSearch
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (!isLoading && docs ?
        <>
            <Paper square>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChangeTab}
                    aria-label="Editar pessoa"
                >
                    <Tab label="Lista" {...a11yProps(0)} />
                    <Tab label="Mapa" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tab} index={0}>
                    <FilterContainer
                        title="Filtros"
                        filters={filters}
                        handleApplyFilters={handleApplyFilters}
                        handleDismissFilters={handleDismissFilters}
                    />
                    <DataTable
                        columns={columns}
                        docs={docs}
                        page={page}
                        size={size}
                        count={count}
                        error={error}
                        handleChangePage={handleChangePage}
                        handleChangeSize={handleChangeSize}
                    />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <LoadScript
                        googleMapsApiKey="AIzaSyAymdqUYpofthT5ixv2Y9Bit58a2yb-Hy0"
                    >
                        <GoogleMap
                            mapContainerStyle={{ width: 'calc(100vw - 340px)', height: 'calc(100vh - 260px)' }}
                            center={{
                                lat: -26.2504871,
                                lng: -49.3789437
                            }}
                            zoom={12}
                        >
                            {marcadores.map((marcador, index) => (
                                <Marker
                                    key={index}
                                    title={marcador.matricula + ' - ' + marcador.descricao}
                                    position={JSON.parse(marcador.marcador)}
                                />
                            ))}

                        </GoogleMap>
                    </LoadScript>
                </TabPanel>
            </Paper>
            {user?.permissoes["Imóveis-incluir"] && (
                <ActionMenu
                    actions={[
                        {
                            icon: <IconButton href="/imoveis/new"><DescriptionOutlinedIcon /></IconButton>,
                            title: 'Incluir',
                            type: 'button'
                        }
                    ]}
                />
            )}
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteImovel}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </>
        :
        isLoading && <Loading open={isLoading} />
    )
}

export default ListaImoveis;