import React, { useState } from 'react';

import {
    TextField,
    CircularProgress
} from "@material-ui/core";

import SearchIcon from '@material-ui/icons/Search';

import {
    Autocomplete
} from "@material-ui/lab";

import Condicoes from "../../services/Condicoes";
import Pessoas from "../../services/Pessoas";
import Usuarios from "../../services/Usuarios";
import OrdensServico from "../../services/OrdensServico";
import Instituicoes from "../../services/Instituicoes";
import Comarcas from "../../services/Comarcas";
import Servicos from "../../services/Servicos";
import Imoveis from "../../services/Imoveis";

function CondicoesComboBox(props) {

    const [listaCondicaoPagamento, setListaCondicaoPagamento] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListCondicaoPagamento = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][descricao][$regex]=" + event.target.value;
            filters += "&$or[0][descricao][$options]=gi";
        }

        setListaCondicaoPagamento({ ...listaCondicaoPagamento, loading: true });

        Condicoes.list(0, 30, filters).then((response) => {
            setListaCondicaoPagamento({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="combo-condicao-pagamento"
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhuma condição de pagamento encontrada"
            open={listaCondicaoPagamento.open}
            onOpen={handleListCondicaoPagamento}
            onClose={() => {
                setListaCondicaoPagamento({ ...listaCondicaoPagamento, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListCondicaoPagamento}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.descricao}
            filterOptions={x => x}
            options={listaCondicaoPagamento.options}
            loading={listaCondicaoPagamento.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Condição Pagamento"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaCondicaoPagamento.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function PessoasComboBox(props) {

    const [listaPessoas, setListaPessoas] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListPessoas = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][nome][$regex]=" + event.target.value;
            filters += "&$or[0][nome][$options]=gi";
            filters += "&$or[1][cpf][$regex]=" + event.target.value;
            filters += "&$or[1][cpf][$options]=gi";
        }

        setListaPessoas({ ...listaPessoas, loading: true });

        Pessoas.list(0, 30, filters).then((response) => {
            setListaPessoas({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id={props.id ? props.id : "combo-pessoa"}
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhuma pessoa encontrada"
            open={listaPessoas.open}
            onOpen={handleListPessoas}
            onClose={() => {
                setListaPessoas({ ...listaPessoas, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListPessoas}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.nome}
            filterOptions={x => x}
            options={listaPessoas.options}
            loading={listaPessoas.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Pessoa"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaPessoas.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function UsuariosComboBox(props) {
    const [listaUsuarios, setListaUsuarios] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListUsuarios = (event) => {
        let filters = "&ativo=true";

        console.log(event.target.value);

        if (event.target.value) {
            filters += "&$or[0][nome][$regex]=" + event.target.value;
            filters += "&$or[0][nome][$options]=gi";
        }

        setListaUsuarios({ ...listaUsuarios, loading: true });

        Usuarios.list(0, 30, filters).then((response) => {
            setListaUsuarios({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="combo-usuario"
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhum usuário encontrado"
            open={listaUsuarios.open}
            onOpen={handleListUsuarios}
            onClose={() => {
                setListaUsuarios({ ...listaUsuarios, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListUsuarios}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.nome}
            filterOptions={x => x}
            options={listaUsuarios.options}
            loading={listaUsuarios.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Responsável"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaUsuarios.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function OrdensServicoComboBox(props) {

    const [listaOrdemServico, setListaOrdemServico] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListOrdemServico = (event) => {
        let filters = "";

        if (props.filter && props.filter !== "") {
            filters += props.filter;
        }

        if (event.target.value) {
            filters += "&$or[0][descricao][$regex]=" + event.target.value;
            filters += "&$or[0][descricao][$options]=gi";
        }

        setListaOrdemServico({ ...listaOrdemServico, loading: true });

        OrdensServico.list(0, 30, filters).then((response) => {
            setListaOrdemServico({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="combo-ordem-servico"
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhuma ordem de serviço encontrada"
            open={listaOrdemServico.open}
            onOpen={handleListOrdemServico}
            onClose={() => {
                setListaOrdemServico({ ...listaOrdemServico, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListOrdemServico}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => (
                option.numero + ' - ' + option.descricao
            )}
            filterOptions={x => x}
            options={listaOrdemServico.options}
            loading={listaOrdemServico.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label || "Ordem Serviço"}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaOrdemServico.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function OrdemServicoHeader(props) {

    const [listaOrdemServico, setListaOrdemServico] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListOrdemServico = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][numero]=" + event.target.value;
            filters += "&$or[1][descricao][$regex]=" + event.target.value;
            filters += "&$or[1][descricao][$options]=gi";
            filters += "&$or[2][solicitante.nome][$regex]=" + event.target.value;
            filters += "&$or[2][solicitante.nome][$options]=gi";
            //Solicitado por Gustavo em 23/05/2022
            // filters += "&$or[3][usuario.nome][$regex]=" + event.target.value;
            // filters += "&$or[3][usuario.nome][$options]=gi";
        }

        setListaOrdemServico({ ...listaOrdemServico, loading: true });

        OrdensServico.list(0, 30, filters).then((response) => {
            setListaOrdemServico({
                open: true,
                options: response.docs,
                loading: false,
                filters: filters
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    const handleChange = (event, ordemServico) => {
        if (ordemServico) {
            window.location.href = '/os/' + ordemServico._id
        }
    }
    return (
        <Autocomplete
            id={props.id}
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhuma ordem de serviço encontrada"
            open={listaOrdemServico.open}
            onOpen={handleListOrdemServico}
            onClose={() => {
                setListaOrdemServico({ ...listaOrdemServico, open: false });
            }}
            onChange={handleChange}
            onKeyUp={handleListOrdemServico}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => `${option.numero}: ${option.solicitante.nome}: ${option.descricao}`}
            renderOption={(option) => (
                <div style={{ borderBottom: '1px solid grey', paddingBottom: '15px'}}><b>OS:</b> {option.numero}<br /><b>Cliente:</b> {option.solicitante.nome}<br /><b>Descrição: </b>{option.descricao}</div>
            )}
            filterOptions={x => x}
            options={listaOrdemServico.options}
            loading={listaOrdemServico.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <SearchIcon />,
                        endAdornment: (
                            <>
                                {listaOrdemServico.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function InstituicoesComboBox(props) {

    const [listaInstituicao, setListaInstituicao] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListInstituicao = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][nome][$regex]=" + event.target.value;
            filters += "&$or[0][nome][$options]=gi";
        }

        setListaInstituicao({ ...listaInstituicao, loading: true });

        Instituicoes.list(0, 30, filters).then((response) => {
            setListaInstituicao({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="combo-instituicao"
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhuma instituição encontrada"
            open={listaInstituicao.open}
            onOpen={handleListInstituicao}
            onClose={() => {
                setListaInstituicao({ ...listaInstituicao, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListInstituicao}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.nome}
            filterOptions={x => x}
            options={listaInstituicao.options}
            loading={listaInstituicao.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Instituição"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaInstituicao.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function ComarcasComboBox(props) {

    const [listaComarca, setListaComarca] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListComarca = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][nome][$regex]=" + event.target.value;
            filters += "&$or[0][nome][$options]=gi";
        }

        setListaComarca({ ...listaComarca, loading: true });

        Comarcas.list(0, 30, filters).then((response) => {
            setListaComarca({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="combo-comarca"
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhuma comarca encontrada"
            open={listaComarca.open}
            onOpen={handleListComarca}
            onClose={() => {
                setListaComarca({ ...listaComarca, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListComarca}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.nome}
            filterOptions={x => x}
            options={listaComarca.options}
            loading={listaComarca.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Comarca"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaComarca.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function ServicosComboBox(props) {

    const [listaServicos, setListaServicos] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListServicos = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][nome][$regex]=" + event.target.value;
            filters += "&$or[0][nome][$options]=gi";
            filters += "&$or[1][descricao][$regex]=" + event.target.value;
            filters += "&$or[1][descricao][$options]=gi";
        }

        if (props.parent && props.parent !== 'new') {
            filters += "&_id[$ne]=" + props.parent;
        }

        setListaServicos({ ...listaServicos, loading: true });

        Servicos.list(0, 30, filters).then((response) => {
            setListaServicos({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            id="combo-servico"
            size="small"
            style={props.style}
            noOptionsText="Nenhum serviço encontrado"
            fullWidth
            open={listaServicos.open}
            onOpen={handleListServicos}
            onClose={() => {
                setListaServicos({ ...listaServicos, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListServicos}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.nome}
            filterOptions={x => x}
            options={listaServicos.options}
            loading={listaServicos.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Serviço"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaServicos.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

function ImoveisComboBox(props) {

    const [listaImoveis, setListaImoveis] = useState({
        open: false,
        options: [],
        loading: false
    });

    const handleListImoveis = (event) => {
        let filters = "";

        if (event.target.value) {
            filters += "&$or[0][matricula][$regex]=" + event.target.value;
            filters += "&$or[0][matricula][$options]=gi";
            filters += "&$or[1][descricao][$regex]=" + event.target.value;
            filters += "&$or[1][descricao][$options]=gi";
        }

        setListaImoveis({ ...listaImoveis, loading: true });

        Imoveis.list(0, 30, filters).then((response) => {
            setListaImoveis({
                open: true,
                options: response.docs,
                loading: false
            });
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Autocomplete
            {...props}
            id="combo-imoveis"
            size="small"
            fullWidth
            style={props.style}
            noOptionsText="Nenhum imóvel encontrado"
            open={listaImoveis.open}
            onOpen={handleListImoveis}
            onClose={() => {
                setListaImoveis({ ...listaImoveis, open: false });
            }}
            onChange={props.onChange}
            onKeyUp={handleListImoveis}
            getOptionSelected={(option, value) => option._id === value._id}
            getOptionLabel={(option) => option.matricula + ' - ' + option.descricao}
            filterOptions={x => x}
            options={listaImoveis.options}
            loading={listaImoveis.loading}
            defaultValue={props.defaultValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Imóveis"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {listaImoveis.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}

export {
    CondicoesComboBox,
    PessoasComboBox,
    UsuariosComboBox,
    OrdensServicoComboBox,
    OrdemServicoHeader,
    InstituicoesComboBox,
    ComarcasComboBox,
    ServicosComboBox,
    ImoveisComboBox
};