import React, { useEffect, useState, useCallback } from 'react';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import ActionMenu from '../../components/ActionMenu';
import Loading from '../../components/Loading';

import { UsuariosComboBox } from '../../components/DataComboBox';
import { useAuth } from '../../AuthContext';

import OrdensServico from '../../services/OrdensServico';

import Imprimir from './Imprimir';

import PrintIcon from "@material-ui/icons/Print";




const RelatorioConclusaoOs = () => {
    const { user } = useAuth();

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });

    const [filters, setFilters] = useState({
        user: null,
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        finishDateFrom: null,
        finishDateTo: null,
        messageDateFrom: null,
        messageDateTo: null
    });

    const [dialogImprimir, setDialogImprimir] = useState({
        data: {},
        open: false
    });

    const Listar = useCallback((page, size, filters) => {

        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        if (user && !!!user.permissoes["Visualizar-Tarefas-de-Todos-os-Usuários"] && !filters?.includes("responsavel=")) {
            filters += "&responsavel=" + filters.user
        }

        OrdensServico.listaOsconcluidas(page, size, filters).then(tarefas => {
            setData({
                isLoading: false,
                docs: tarefas.docs,
                page: tarefas.page - 1,
                size: tarefas.limit,
                count: tarefas.totalDocs,
                filters: filters
            });
        }).catch(error => {
            console.log(error);
        });
    }, [user])

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.user) qs += "&responsavel=" + filters.user;
        if (filters.dateFrom) qs += '&data[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&data[$lte]=' + filters.dateTo;
        if (filters.dueDateFrom) qs += '&prazo[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&prazo[$lte]=' + filters.dueDateTo;
        if (filters.finishDateFrom) qs += '&conclusao[$gte]=' + filters.finishDateFrom;
        if (filters.finishDateTo) qs += '&conclusao[$lte]=' + filters.finishDateTo;
        if (filters.messageDateFrom) qs += '&mensagem[data][$gte]=' + filters.messageDateFrom;
        if (filters.messageDateTo) qs += '&mensagem[data][$lte]=' + filters.messageDateTo;

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        setFilters({
            user: null,
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            finishDateFrom: null,
            finishDateTo: null,
            messageDateFrom: null,
            messageDateTo: null
        });

        setData({ ...data, filters: '' })
    }

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleChangeResponsavel = (event, responsavel) => {
        if (responsavel) {
            setFilters({ ...filters, user: responsavel.nome });

            let qs = '';

            if (filters.user) qs += "&responsavel=" + responsavel.nome;
            if (filters.dateFrom) qs += '&data[$gte]=' + filters.dateFrom;
            if (filters.dateTo) qs += '&data[$lte]=' + filters.dateTo;
            if (filters.dueDateFrom) qs += '&prazo[$gte]=' + filters.dueDateFrom;
            if (filters.dueDateTo) qs += '&prazo[$lte]=' + filters.dueDateTo;
            if (filters.finishDateFrom) qs += '&conclusao[$gte]=' + filters.finishDateFrom;
            if (filters.finishDateTo) qs += '&conclusao[$lte]=' + filters.finishDateTo;
            if (filters.messageDateFrom) qs += '&mensagem[data][$gte]=' + filters.messageDateFrom;
            if (filters.messageDateTo) qs += '&mensagem[data][$lte]=' + filters.messageDateTo;

            setData({
                ...data,
                filters: qs
            })
        }
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Conclusão das OS");
        Listar(data.page, data.size, data.filters);

    }, [data.page, data.size, data.filters, Listar]);

    const menuActions = [
        {
            icon: <PrintIcon onClick={() => {
                setDialogImprimir({
                    data: {
                        ...filters,
                        docs: data.docs
                    },
                    open: true
                })
            }} />,
            title: 'Imprimir',
            type: 'button'
        }
    ]

    const filterOptions = [
        {
            type: 'component',
            component: <UsuariosComboBox defaultValue={filters.user} onChange={handleChangeResponsavel} />,
            id: 'user',
            name: 'user',
            label: 'Responsável'
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Solicitação de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Solicitação até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Prazo de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Prazo até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'finishDateFrom',
            name: 'finishDateFrom',
            label: 'Conclusão de',
            value: filters.finishDateFrom,
            onChange: (value) => { setFilters({ ...filters, finishDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'finishDateTo',
            name: 'finishDateTo',
            label: 'Conclusão até',
            value: filters.finishDateTo,
            onChange: (value) => { setFilters({ ...filters, finishDateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'messageDateFrom',
            name: 'messageDateFrom',
            label: 'Data Mensagem de',
            value: filters.messageDateFrom,
            onChange: (value) => { setFilters({ ...filters, messageDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'messageDateTo',
            name: 'messageDateTo',
            label: 'Data Mensagem até',
            value: filters.messageDateTo,
            onChange: (value) => { setFilters({ ...filters, messageDateTo: value?.endOf('day').toISOString() }) }
        }
    ];

    const columns = [
        {
            id: 'numeroOs',
            label: '# OS',
            align: 'center'
        },
        {
            id: 'porcentagemConcluidos',
            label: 'Porcentagem Concluídos',
            align: 'center'
          
        },
        {
            id: 'nomeCliente',
            label: 'Cliente',
            align: 'left'
        },
        {
            id: 'responsavel',
            label: 'Responsável',
            align: 'left'
        },
        {
            id: 'nome',
            label: 'Tarefa',
            align: 'left'
        },
        {
            id: 'status',
            label: 'Status Tarefa',
            align: 'center'
        }
    ]

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            {!data.isLoading && data.docs ?
                <DataTable
                    columns={columns}
                    docs={data.docs}
                    page={data.page}
                    size={data.size}
                    count={data.count}
                    error={data.error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />
                :
                data.isLoading && <Loading open={data.isLoading} />
            }
            <ActionMenu actions={menuActions} />
            <Imprimir
                open={dialogImprimir.open}
                onClose={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
                data={dialogImprimir.data}
                params={dialogImprimir.data}
                confirm={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
                cancel={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
            />
        </>
    )
}

export default RelatorioConclusaoOs;