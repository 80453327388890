import React from 'react';
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import NumeroPorExtenso from 'numero-por-extenso';

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography
} from '@material-ui/core';

import moment from 'moment';

function ImprimeRecibo(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"md"}
            fullWidth
        >
            <DialogContent>
                <Grid container spacing={3} id="imprimeRecibo" style={{ padding: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK" height="80" />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem'}}>
                        <Typography variant="h5" align="left">
                            RECIBO Nº {props.data.numero}
                        </Typography>
                        <Typography variant="h5" align="right">
                            OS Nº {props.data.ordemServicoNumero}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="left">
                            VALOR: &nbsp;
                            <NumberFormat
                                value={props.data.valor - props.data.saldo}
                                displayType="text"
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale={2}
                                prefix="R$ "
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Recebemos de <b>{props.data.cliente?.nome}</b> a importância de:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <i>
                                ({props.data.valor && NumeroPorExtenso.porExtenso(props.data.valor - props.data.saldo, NumeroPorExtenso.estilo.monetario)})
                            </i>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Referente a {props.data.descricao}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="right">
                            São Bento do Sul, {moment().format("DD [de] MMMM [de] YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} style={{ marginTop: '50px' }}>
                        <Typography align="center" style={{ borderTop: '1px solid black' }}>
                            Stockchneider
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.confirm &&
                    <ReactToPrint
                        trigger={() => {
                            return <Button color="primary" autoFocus>Imprimir</Button>
                        }}
                        content={() => document.getElementById("imprimeRecibo")}
                    />}
                {props.cancel && <Button onClick={props.cancel}>Cancelar</Button>}
            </DialogActions>
        </Dialog >
    )
}

export default ImprimeRecibo;