import React, { useEffect } from 'react';

const FluxoCaixa = () => {
  useEffect(() => {
    sessionStorage.setItem("@stk/appTitle", "Fluxo de Caixa");
  }, []);

  return (<h4>Em breve!</h4>);
}

export default FluxoCaixa;