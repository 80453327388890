import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Divider,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Input,
    Checkbox,
    ListItemText,
    TextField,
    Grid
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';

import { DateField } from "../../components/MaskedFields";

function FilterContainer(props) {
    const filterType = (filter) => {
        if (filter.type === 'search') {
            return (
                <TextField
                    {...filter}
                    key={filter.id}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>,
                    }}
                    fullWidth
                />
            )
        }

        if (filter.type === 'select') {
            return (
                <FormControl key={filter.id} fullWidth>
                    <InputLabel shrink htmlFor={filter.id} id={filter.id}>{filter.label}</InputLabel>
                    <Select
                        multiple
                        id={filter.id}
                        labelid={filter.id}
                        value={filter.value}
                        onChange={filter.onChange}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 250
                                },
                            },
                        }}
                        fullWidth
                    >
                        {filter.options && filter.options.map(option => (
                            <MenuItem key={option} value={option}>
                                <Checkbox checked={filter.value.indexOf(option) > -1} />
                                <ListItemText primary={option} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )
        }

        if (filter.type === 'date') {
            return (
                <DateField
                    {...filter}
                    type="text"
                    fullWidth
                />
            )
        }
        
        if (filter.type === 'component') {
            return filter.component
        }
    }

    return (
        <Accordion style={{ marginBottom: '10px' }}>
            <AccordionSummary
                expandIcon={<FilterListIcon />}
                aria-controls="filter-content"
                id="filter-header"
            >
                {props.title}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    {props.filters.map((filter, index) => (
                        <Grid item key={'filter-' + index} xs={12} md={4}>
                            {filterType(filter)}
                        </Grid>
                    ))}
                </Grid>

            </AccordionDetails>
            <Divider />
            <AccordionActions>
                <Button size="small" color="primary" onClick={props.handleApplyFilters}>Aplicar</Button>
                <Button size="small" color="secondary" onClick={props.handleDismissFilters}>Limpar</Button>
            </AccordionActions>
        </Accordion>
    );
}

export default FilterContainer;