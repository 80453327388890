const user = JSON.parse(localStorage.getItem('@stk/user'));

const list = (page = 0, limit = 30, filters = '') => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/comarcas?page=' + (page + 1) + '&size=' + limit + filters;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });

    })
}

const retrieve = (id) => {
    return new Promise((resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/comarcas/' + id;
        let options = {
            method: 'GET',
            headers: headers
        };

        fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        localStorage.removeItem('@stk/user');
                        window.location.reload();
                    } else {
                        return response.json();
                    }
                } else {
                    return response.json();
                }
            })
            .then(data => {
                resolve(data);
            }).catch(error => {
                reject(error);
            });
    })
}

const create = (comarca) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/comarcas';
        let options = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(comarca)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const update = (id, comarca) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/comarcas/' + id;
        let options = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(comarca)
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const remove = (id) => {
    return new Promise(async (resolve, reject) => {
        let headers = new Headers()
        headers.append("Content-Type", "application/json");
        headers.append("token", user.token);

        let url = process.env.REACT_APP_BASE_URL_API + '/api/comarcas/' + id;
        let options = {
            method: 'DELETE',
            headers: headers,
        };

        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
            resolve(result);
        } else {
            if (response.status === 401) {
                localStorage.removeItem('@stk/user');
                window.location.reload();
            } else {
                reject(result);
            }
        }
    })
}

const obj = { list, retrieve, create, update, remove };

export default obj;