import React, { useState, useEffect } from 'react';
import {
    IconButton
} from '@material-ui/core';

import Anexos from './anexos';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';

import Solicitacoes from '../../services/Solicitacoes';
import { useAuth } from '../../AuthContext';

const ListaSolicitacoes = (props) => {
    const { user } = useAuth();
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: ''
    });

    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        tipo: ['Topografia', 'Engenharia/Arquitetura', 'Regularização de Imóvel', 'Ajuda']
    })

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const [dialogAnexos, setDialogAnexos] = useState({
        open: false,
        data: []
    })

    const Listar = (page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        Solicitacoes.list(page, size, filter).then(solicitacoes => {
            setData({
                isLoading: false,
                docs: solicitacoes.docs,
                page: solicitacoes.page - 1,
                size: solicitacoes.limit,
                count: solicitacoes.totalDocs,
                filters: filter
            });
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Solicitacoes");
        Listar(data.page, data.size, data.filters);
    }, [data.page, data.size, data.filters]);

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleClickAnexos = (event, anexos) => {
        setDialogAnexos({
            open: true,
            data: anexos
        })
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeleteSolicitacao = (event) => {
        Solicitacoes.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            Listar(data.page, data.size, data.filters);
        }).catch(error => {
            console.log(error);
        });
    }

    const columns = [
        {
            id: 'data',
            label: 'Data',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'cliente',
            label: 'Cliente',
            align: 'left',
            format: (cliente) => cliente?.nome
        },
        {
            id: 'tipoDescricao',
            label: 'Tipo',
            align: 'left',
        },
        {
            id: 'resumo',
            label: 'Resumo',
            align: 'left'
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <div style={{ minWidth: '100px' }}>
                    <IconButton aria-label="Anexos" onClick={e => handleClickAnexos(e, doc.anexos)} color="primary" disabled={doc.anexos.length <= 0}>
                        <AttachFileIcon />
                    </IconButton>
                    {user?.permissoes["Solicitações-do-Cliente-excluir"] && (
                        <IconButton aria-label="Excluir" onClick={e => handleClickDelete(e, doc._id, doc.resumo)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            )
        }
    ];

    const handleKeyDownSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][cliente.nome][$regex]=" + filters.search;
            qs += "&$or[0][cliente.nome][$options]=gi";
        }

        if (filters.dateFrom) qs += '&data[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&data[$lte]=' + filters.dateTo;
        if (filters.tipo.length > 0) {
            const tipos = [];

            for (let index = 0; index < filters.tipo.length; index++) {
                let tipo = '';
                switch (filters.tipo[index]) {
                    case 'Topografia': tipo = '0'; break;
                    case 'Engenharia/Arquitetura': tipo = '1'; break;
                    case 'Regularização de Imóvel': tipo = '2'; break;
                    case 'Ajuda': tipo = '3'; break;
                    default: tipo = '0'; break;
                }

                if (tipo !== '') tipos.push(tipo);
            }

            qs += "&tipo[$in]=" + tipos.join();
        }

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            tipo: ['Topografia', 'Engenharia/Arquitetura', 'Regularização de Imóvel', 'Ajuda']
        });

        setData({ ...data, filters: '' })
    }

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyDownSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'select',
            id: 'tipo',
            name: 'tipo',
            label: 'Tipo',
            value: filters.tipo,
            options: ['Topografia', 'Engenharia/Arquitetura', 'Regularização de Imóvel', 'Ajuda'],
            onChange: (e) => { setFilters({ ...filters, tipo: e.target.value }) }
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            {!isLoading && docs ? (
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />) :
                isLoading && <Loading open={isLoading} />
            }
            <Anexos
                open={dialogAnexos.open}
                data={dialogAnexos.data}
                confirm={() => {
                    setDialogAnexos({
                        open: false,
                        data: []
                    })
                }}
                onClose={() => {
                    setDialogAnexos({
                        open: false,
                        data: []
                    })
                }}
            />
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteSolicitacao}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </>

    )
}

export default ListaSolicitacoes;