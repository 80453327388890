import React from 'react';
import {
    Backdrop,
    Typography,
    LinearProgress
} from '@material-ui/core'

function Loading(props) {
    return (
        <Backdrop open={props.open} style={{
            zIndex: 987349872489,
            background: 'rgba(255,255,255,0.5)'
        }}>
            <Typography variant="h5" align="center" style={{ width: '40%' }}>
                Aguarde
                <LinearProgress style={{ width: '100%', marginTop: '20px' }} />
            </Typography>
        </Backdrop>
    )
}

export default Loading;