import React, { useState, useEffect, Fragment } from 'react';
import {
    IconButton
} from '@material-ui/core';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ActionMenu from '../../components/ActionMenu';
import ConfirmationDialog from '../../components/ConfirmationDialog';

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import OrdensServico from '../../services/OrdensServico';
import { useAuth } from '../../AuthContext';

const ListaOrdensServico = (props) => {
    const { user } = useAuth();
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: '&situacao[$in]=Orçamento,Confirmado&quitacao[$in]=Total,Parcial,Pendente'
    });

    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        status: ['Orçamento', 'Confirmado'],
        quitacao: ['Total', 'Parcial', 'Pendente']
    });

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const Listar = (page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        OrdensServico.list(page, size, filter).then(response => {
            setData({
                isLoading: false,
                docs: response.docs,
                page: response.page - 1,
                size: response.limit,
                count: response.totalDocs,
                filters: filter
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleKeyDownSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeleteOrdemServico = (event) => {
        OrdensServico.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][numero]=" + filters.search;
            qs += "&$or[1][descricao][$regex]=" + filters.search;
            qs += "&$or[1][descricao][$options]=gi";
            qs += "&$or[2][solicitante.nome][$regex]=" + filters.search;
            qs += "&$or[2][solicitante.nome][$options]=gi";
            //Solicitado por Gustavo em 23/05/2022
            // qs += "&$or[3][usuario.nome][$regex]=" + filters.search;
            // qs += "&$or[3][usuario.nome][$options]=gi";
        }

        if (filters.dateFrom) qs += '&data[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&data[$lte]=' + filters.dateTo;
        if (filters.dueDateFrom) qs += '&prazo[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&prazo[$lte]=' + filters.dueDateTo;
        if (filters.status.length > 0) qs += "&situacao[$in]=" + filters.status.join();
        if (filters.quitacao.length > 0) qs += "&quitacao[$in]=" + filters.quitacao.join();

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            status: ["Orçamento", "Confirmado"],
            quitacao: ['Total', 'Parcial', 'Pendente']
        });

        setData({ ...data, filters: '&situacao[$in]=Orçamento,Confirmado&quitacao[$in]=Total,Parcial,Pendente' })
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Ordens de Serviço");

        Listar(data.page, data.size, data.filters);
    }, [data.page, data.size, data.filters]);


    const columns = [
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'imoveis',
            label: 'Imóveis',
            align: 'left',
            format: (imoveis) => (imoveis.map((imovel, index) => (
                <Fragment key={imovel.descricao + "-" + index}>
                    {imovel.descricao}
                    <br />
                </Fragment>
            )))
        },
        {
            id: 'prioridade',
            label: 'Prioridade',
            align: 'center'
        },
        {
            id: 'data',
            label: 'Data',
            align: 'center',
            format: (value) => (value &&
                <>
                    {new Date(value).toLocaleDateString('pt-BR')}
                    <br />
                    {new Date(value).toLocaleTimeString('pt-BR')}
                </>
            )
        },
        {
            id: 'prazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => (value &&
                <>
                    {new Date(value).toLocaleDateString('pt-BR')}
                    <br />
                    {new Date(value).toLocaleTimeString('pt-BR')}
                </>
            )
        },
        {
            id: 'solicitante',
            label: 'Solicitante',
            align: 'center',
            format: (solicitante) => solicitante.nome
        },
        {
            id: 'responsavelNome',
            label: 'Responsável',
            align: 'center'
        },
        {
            id: 'situacao',
            label: 'Situação',
            align: 'center'
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <div key={index} style={{ width: '100px' }}>
                    <IconButton aria-label="Editar" href={'/os/' + doc._id} color="primary">
                        {user?.permissoes["Ordem-de-Serviço-alterar"] ? (
                            <EditIcon color="primary" />
                        ) : (
                            <VisibilityIcon color="primary" />
                        )}
                    </IconButton>
                    {user?.permissoes["Ordem-de-Serviço-excluir"] && (
                        <IconButton aria-label="Excluir" onClick={e => handleClickDelete(e, doc._id, doc.descricao)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            )
        },
    ];

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyDownSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Prazo de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Prazo até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'select',
            id: 'status',
            name: 'status',
            label: 'Situação',
            value: filters.status,
            options: ['Orçamento', 'Confirmado', 'Concluído'],
            onChange: (e) => { setFilters({ ...filters, status: e.target.value }) }
        },
        {
            type: 'select',
            id: 'quitacao',
            name: 'quitacao',
            label: 'Quitação',
            value: filters.quitacao,
            options: ['Total', 'Parcial', 'Pendente'],
            onChange: (e) => { setFilters({ ...filters, quitacao: e.target.value }) }
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            {!isLoading && docs ? (
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />) :
                isLoading && <Loading open={isLoading} />
            }
            {user?.permissoes["Ordem-de-Serviço-incluir"] && (
                <ActionMenu
                    actions={[
                        {
                            icon: <IconButton href="/os/new"><DescriptionOutlinedIcon /></IconButton>,
                            title: 'Incluir',
                            type: 'button'
                        }
                    ]}
                />
            )}
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteOrdemServico}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </>

    )
}

export default ListaOrdensServico;