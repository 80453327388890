import React, { createContext, useContext, useState, useEffect } from 'react';

import Usuarios from './services/Usuarios';

const AuthContext = createContext({
	authenticated: false, // to check if authenticated or not
	user: null,
	loading: true,
	login: async () => { },
	logout: () => { }
});

const AuthProvider = (props) => {
	const [user, setUser] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		function loadStorageData() {
			const storedUser = JSON.parse(localStorage.getItem("@stk/user"));

			if (storedUser) {
				setIsLoggedIn(true);
				Usuarios.retrieve(storedUser.id).then(response => {
					setUser(response);
				}).catch(error => {
					setIsLoggedIn(false);
					console.log(error);
				})
			}

			setLoading(false);
		}

		loadStorageData();
	}, [])

	function login(userLogin) {
		return new Promise((resolve, reject) => {
			setLoading(true);
			Usuarios.postAuth(userLogin).then(response => {
				if (!response.error) {
					localStorage.setItem("@stk/user", JSON.stringify({
						id: response._id,
						nome: response.nome,
						token: response.token,
					}));
					setUser(response);
					setIsLoggedIn(true);
					setLoading(false);
					resolve(response);
				} else {
					setLoading(false);
					reject(response.error);
				}
			}).catch(error => {
				setLoading(false);
				reject(error);
			});
		})
	}

	async function logout() {
		setIsLoggedIn(false);
		setUser(null);
		localStorage.removeItem("@stk/user");
	}

	return (
		<AuthContext.Provider value={{ authenticated: isLoggedIn, user, loading, login, logout }}>
			{props.children}
		</AuthContext.Provider>
	)
}

function useAuth() {
	const context = useContext(AuthContext);

	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider.');
	}

	return context;
}

export { AuthProvider, useAuth };