import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme) => ({
    root: {
        background: '#FFF !important',
        position:'absolute',
        left: '0px',
        right: '0px'
    },
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: indigo[500],
        color: 'white',
        '&:hover': {
            backgroundColor: indigo[700],
        },
    },
}));

export default useStyles;