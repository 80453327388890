import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    CircularProgress
} from '@material-ui/core'

import { Doughnut } from 'react-chartjs-2';
import moment from 'moment';
import "moment/locale/pt-br";

import DescriptionIcon from '@material-ui/icons/Warning';
import ReceiptIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Container, ListItemText } from './styles';
import DashboardService from '../../services/Dashboard';

import { useAuth } from '../../AuthContext';

moment.locale('pt-br');

const Dashboard = (props) => {
    const { user } = useAuth()
    const [ordensServico, setOrdensServico] = useState({
        isLoading: true,
        data: []
    });

    const [tarefas, setTarefas] = useState({
        isLoading: true,
        data: {}
    });

    const [filters] = useState({});

    const dynamicColors = (aux, total, hover) => {
        var r = aux * 100 / total;
        var g = aux * 255 / total;
        var b = 200 + aux * 155 / total;
        return "rgba(" + r + "," + g + "," + b + ", " + hover + ")";
    };

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Painel");
        if (user) {
            let filters = "";

            if (!!!user.permissoes["Visualizar-Tarefas-de-Todos-os-Usuários"]) {
                filters = "responsavel=" + user._id
            }

            DashboardService.tarefas(filters).then(response => {
                setTarefas({
                    isLoading: false,
                    data: response
                })
            }).catch(error => console.log(error));

            DashboardService.ordensServico().then(response => {
                let colors = [];
                let hover = [];

                for (let index = 0; index < response.length; index++) {
                    colors.push(dynamicColors(index, response.length, 0.6));
                    hover.push(dynamicColors(index, response.length, 0.5));
                }

                setOrdensServico({
                    isLoading: false,
                    data: response,
                    colors: colors,
                    hover: hover
                });
            }).catch(error => console.log(error));
        }
    }, [filters, user]);

    return (
        <Container>
            <Grid container justify="center" spacing={2}>
                <Grid key={0} item sm={12} md={6} style={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}>
                    <Card style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <CardContent align="center" style={{ minHeight: '360px' }}>
                            <Typography align="center" gutterBottom>
                                Ordens de Serviço - {moment().format('MMMM/YYYY')}
                            </Typography>
                            {ordensServico && !ordensServico.isLoading && (
                                <Doughnut
                                    data={{
                                        labels: ordensServico.data.map(x => x._id),
                                        datasets: [{
                                            label: 'Ordens de Serviço',
                                            data: ordensServico.data.map(x => x.quantity),
                                            backgroundColor: ordensServico.colors,
                                            hoverBackgroundColor: ordensServico.hover,
                                            borderWidth: 0
                                        }]
                                    }}
                                    options={{
                                        legend: {
                                            position: 'bottom',
                                            labels: {
                                                boxWidth: 12
                                            }
                                        }
                                    }}
                                />
                            )}
                            {ordensServico.isLoading && <CircularProgress variant="indeterminate" size={100} thickness={2} style={{
                                margin: '50px'
                            }} />}
                        </CardContent>
                        <CardActions dir="rtl">
                            {user?.permissoes["Serviços"] === true && user?.permissoes["Ordem-de-Serviço-visualizar"] === true && <Button size="small" href="/os">Ver Todas</Button>}
                            {user?.permissoes["Serviços"] === true && user?.permissoes["Ordem-de-Serviço-incluir"] === true && <Button size="small" href="/os/new">Criar Nova OS</Button>}
                        </CardActions>
                    </Card>
                </Grid>
                <Grid key={1} item sm={12} md={6} style={{ display: 'flex', flexGrow: '1', flexDirection: 'column' }}>
                    <Card style={{ display: 'flex', flexGrow: '1', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <CardContent align="center" style={{ minHeight: '360px' }}>
                            <Typography align="center" gutterBottom>
                                Visão geral
                            </Typography>
                            {tarefas && !tarefas.isLoading && (
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <DescriptionIcon style={{ fontSize: '40px', marginRight: '20px', color: 'orange' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={tarefas.data.hoje}
                                            secondary="tarefas vencendo hoje"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <ReceiptIcon style={{ fontSize: '40px', marginRight: '20px', color: 'green' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={tarefas.data.proximosDias}
                                            secondary="tarefas vencendo nos próximos 3 dias"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <CancelIcon color="secondary" style={{ fontSize: '40px', marginRight: '20px' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={tarefas.data.atrasadas}
                                            secondary="tarefas atrasadas"
                                        />
                                    </ListItem>
                                </List>
                            )}
                            {tarefas.isLoading && <CircularProgress variant="indeterminate" size={100} thickness={2} style={{
                                margin: '50px'
                            }} />}
                        </CardContent>
                        <CardActions dir="rtl">
                            {user?.permissoes["Tarefas"] === true && <Button size="small" href="/tarefas">Ver Todas</Button>}
                        </CardActions>
                    </Card>
                </Grid>
                {/* <Grid key={2} item sm={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography align="center" gutterBottom>
                                Contas a Receber
                            </Typography>
                            {data.ordersByMarketplace && (
                                <Pie
                                    data={{
                                        labels: data.ordersByMarketplace.map(x => x._id),
                                        datasets: [{
                                            label: 'Contas a Receber',
                                            data: data.ordersByMarketplace.map(x => x.total),
                                            backgroundColor: data.ordersByMarketplaceColors,
                                            hoverBackgroundColor: data.ordersByMarketplaceHover,
                                            borderWidth: 0
                                        }]
                                    }}
                                    options={{
                                        legend: {
                                            position: 'bottom',
                                            labels: {
                                                boxWidth: 12
                                            }
                                        }
                                    }}
                                />
                            )}
                        </CardContent>
                        <CardActions>
                            <Button size="small">Ver títulos</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid key={3} item sm={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography align="center" gutterBottom>
                                Contas a Pagar
                            </Typography>
                            {data.ordersByMarketplace && (
                                <Pie
                                    data={{
                                        labels: data.contasPagar.map(x => x._id),
                                        datasets: [{
                                            label: 'Contas a Pagar',
                                            data: data.contasPagar.map(x => x.total),
                                            backgroundColor: data.contasPagarColors,
                                            hoverBackgroundColor: data.contasPagarHover,
                                            borderWidth: 0
                                        }]
                                    }}
                                    options={{
                                        legend: {
                                            position: 'bottom',
                                            labels: {
                                                boxWidth: 12
                                            }
                                        }
                                    }}
                                />
                            )}
                        </CardContent>
                        <CardActions>
                            <Button size="small">Ver títulos</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid key={4} item sm={12} md={12}>
                    <Card>
                        <CardContent style={{ height: '620px', marginBottom: '20px' }}>
                            <Typography align="center" gutterBottom>
                                Fluxo de Caixa
                            </Typography>
                            {data.ordersByState && (
                                <Bar
                                    data={{
                                        labels: data.ordersByState.map(x => x._id),
                                        datasets: [{
                                            label: 'Fluxo de Caixa',
                                            data: data.ordersByState.map(x => x.total),
                                            backgroundColor: 'rgba(50, 102, 222, 0.6)',
                                            borderWidth: 1
                                        }]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        legend: {
                                            display: false
                                        }
                                    }}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid key={5} item sm={12} md={12}>
                    <Card>
                        <CardContent style={{ height: '620px', marginBottom: '20px' }}>
                            <Typography align="center" gutterBottom>
                                Fluxo de Caixa por Dia
                            </Typography>
                            {data.ordersByDay && (
                                <Line
                                    data={{
                                        labels: data.ordersByDay.map(x => x._id),
                                        datasets: [
                                            {
                                                label: 'Contas a Receber',
                                                fill: false,
                                                lineTension: 0.1,
                                                borderColor: "blue", // The main line color
                                                borderCapStyle: 'square',
                                                data: data.ordersByDay.map(x => x.receber),
                                                backgroundColor: 'rgba(0, 0, 255, 0.6)',
                                                borderWidth: 1
                                            },
                                            {
                                                label: 'Contas a Pagar',
                                                fill: false,
                                                lineTension: 0.1,
                                                borderColor: "red", // The main line color
                                                borderCapStyle: 'square',
                                                data: data.ordersByDay.map(x => x.pagar),
                                                backgroundColor: 'rgba(255, 0, 0, 0.6)',
                                                borderWidth: 1
                                            }
                                        ]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        legend: {
                                            display: false
                                        }
                                    }}
                                />
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid key={6} item sm={12} md={12}>
                    <Card>
                        <CardContent>
                            <Typography align="center" gutterBottom>
                                Próximas tarefas
                            </Typography>
                            {data.topProducts && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Prazo</TableCell>
                                            <TableCell align="left">Descrição</TableCell>
                                            <TableCell align="center">Número OS</TableCell>
                                            <TableCell align="left">Cliente</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.topProducts.map((product, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">{product.prazo}</TableCell>
                                                <TableCell align="left">{product.descricao}</TableCell>
                                                <TableCell align="center">{product.numeroOS}</TableCell>
                                                <TableCell align="left">{product.cliente}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </CardContent>
                    </Card>
                </Grid> */}
            </Grid>
        </Container>
    )
}

export default Dashboard;