import React, { useState, useEffect, useCallback } from 'react';
import {
    InputAdornment,
    IconButton,
    Button
} from '@material-ui/core';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ActionMenu from '../../components/ActionMenu';

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import Usuarios from '../../services/Usuarios';
import { useAuth } from '../../AuthContext';

const ListaUsuarios = (props) => {
    const { user } = useAuth();

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0
    })

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const Listar = useCallback((page, size, filter) => {
        Usuarios.list(page, size, filter).then(result => {
            setData({
                isLoading: false,
                docs: result.docs,
                page: result.page - 1,
                size: result.limit,
                count: result.totalDocs
            });
        }).catch(error => {
            console.log(error);
        });
    }, []);

    const handleChangePage = (event, newPage) => {
        const { size } = data;
        Listar(newPage, size);
    }

    const handleChangeSize = (event) => {
        Listar(0, +event.target.value);
    }

    const handleKeyUpSearch = (event) => {
        if (event.keyCode === 13) applyFilters(event.target.value);
    }

    const handleChangeActive = (event, usuario) => {
        Usuarios.update(usuario._id, { ativo: !usuario.ativo }).then(response => {
            let searchExpression = document.getElementById("search").value;
            applyFilters(searchExpression);
        }).catch(error => {
            console.log(error);
        });
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeleteUsuario = (event) => {
        Usuarios.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            let searchExpression = document.getElementById("search").value;
            applyFilters(searchExpression);
        }).catch(error => {
            console.log(error);
        });
    }

    const applyFilters = (searchExpression) => {
        let filters = "";

        if (searchExpression) {
            filters += "&$or[0][nome][$regex]=" + searchExpression;
            filters += "&$or[0][nome][$options]=gi";
            filters += "&$or[1][usuario][$regex]=" + searchExpression;
            filters += "&$or[1][usuario][$options]=gi";
            filters += "&$or[2][email][$regex]=" + searchExpression;
            filters += "&$or[2][email][$options]=gi";

        }

        Listar(data.page, data.size, filters);
    }

    const handleApplyFilters = () => {
        let searchExpression = document.getElementById("search").value;
        applyFilters(searchExpression);
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        applyFilters();
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Usuários");
        Listar(data.page, data.size);
    }, [data.page, data.size, Listar]);

    const columns = [
        {
            id: 'nome',
            label: 'Nome',
            align: 'left'
        },
        {
            id: 'email',
            label: 'E-mail',
            align: 'left'
        },
        {
            id: 'usuario',
            label: 'Usuário',
            align: 'left'
        },
        {
            id: 'ativo',
            label: 'Status',
            align: 'center',
            type: 'hybrid',
            format: (usuario) => usuario.ativo ?
                <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    disableElevation
                    onClick={e => user?.permissoes["Usuários-alterar"] && handleChangeActive(e, usuario)}

                >
                    Ativo
                </Button>
                :
                <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    disableElevation
                    onClick={e => user?.permissoes["Usuários-alterar"] && handleChangeActive(e, usuario)}
                >
                    Inativo
                </Button>
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <div>
                    <IconButton aria-label="Editar" href={'/usuarios/' + doc._id} color="primary">
                        {user?.permissoes["Usuários-alterar"] ? (
                            <EditIcon color="primary" />
                        ) : (
                                <VisibilityIcon color="primary" />
                            )}
                    </IconButton>
                    {user?.permissoes["Usuários-excluir"] && (
                        <IconButton aria-label="Excluir" onClick={e => handleClickDelete(e, doc._id, doc.nome)} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    )}
                </div>
            )
        },
    ];

    const filters = [
        {
            type: 'search',
            id: 'search',
            label: 'Filtrar por nome',
            width: 300,
            InputProps: {
                startAdornment: <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>,
            },
            onKeyUp: handleKeyUpSearch
        }
    ];

    const { isLoading, docs, page, size, count, error } = data;

    return (!isLoading && docs ?
        <>
            <FilterContainer
                title="Filtros"
                filters={filters}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            <DataTable
                columns={columns}
                docs={docs}
                page={page}
                size={size}
                count={count}
                error={error}
                handleChangePage={handleChangePage}
                handleChangeSize={handleChangeSize}
            />
            {user?.permissoes["Usuários-incluir"] && (
                <ActionMenu
                    actions={[
                        {
                            icon: <IconButton href="/usuarios/new"><DescriptionOutlinedIcon /></IconButton>,
                            title: 'Incluir',
                            type: 'button'
                        }
                    ]}
                />
            )}
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteUsuario}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </>
        :
        isLoading && <Loading open={isLoading} />
    )
}

export default ListaUsuarios;