import React, { useEffect, useState, useCallback } from 'react';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import ActionMenu from '../../components/ActionMenu';
import Loading from '../../components/Loading';

import { UsuariosComboBox } from '../../components/DataComboBox';
import { useAuth } from '../../AuthContext';
import {
    IconButton,
   
} from '@material-ui/core';
import OrdensServico from '../../services/OrdensServico';
import DescriptionIcon from '@material-ui/icons/Description';
import Imprimir from './Imprimir';

import PrintIcon from "@material-ui/icons/Print";

import moment from 'moment';


const RelatorioAtividadesExecutadas = () => {
    const { user } = useAuth();
    const storedUser = JSON.parse(localStorage.getItem("@stk/user"));

    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        page: 0,
        size: 30,
        count: 0,
        filters: '&responsavel=' + storedUser.id + '&conclusao[$gte]=' + moment().startOf('month').toISOString() + '&conclusao[$lte]=' + moment().endOf('month').toISOString()
    });
   

    const [filters, setFilters] = useState({
        user: {
            _id: storedUser.id,
            nome: storedUser.nome
        },
        dateFrom: moment().startOf('month').toISOString(),
        dateTo: moment().endOf('month').toISOString()
    });

    const [dialogImprimir, setDialogImprimir] = useState({
        data: {},
        open: false
    });

    const Listar = useCallback((page, size, filters) => {

        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        if (user && !!!user.permissoes["Visualizar-Tarefas-de-Todos-os-Usuários"] && !filters?.includes("responsavel=")) {
            filters += "&responsavel=" + filters.user
        }

        OrdensServico.listaTarefas(page, size, filters).then(tarefas => {
            setData({
                isLoading: false,
                docs: tarefas.docs,
                page: tarefas.page - 1,
                size: tarefas.limit,
                count: tarefas.totalDocs,
                filters: filters
            });
        }).catch(error => {
            console.log(error);
        });
    }, [user])

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.user) qs += "&responsavel=" + filters.user;
        if (filters.dateFrom) qs += '&conclusao[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&conclusao[$lte]=' + filters.dateTo;

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        setFilters({
            user: null,
            dateFrom: null,
            dateTo: null
        });

        setData({ ...data, filters: '' })
    }

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        })
    }

    const handleChangeResponsavel = (event, responsavel) => {
        if (responsavel) {
            setFilters({ ...filters, user: responsavel._id });

            let qs = "&responsavel=" + responsavel._id;
            if (filters.dateFrom) qs += '&conclusao[$gte]=' + filters.dateFrom;
            if (filters.dateTo) qs += '&conclusao[$lte]=' + filters.dateTo;

            setData({
                ...data,
                filters: qs
            })
        }
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Atividades Executadas no Período");
        Listar(data.page, data.size, data.filters);

    }, [data.page, data.size, data.filters, Listar]);

    const menuActions = [
        {
            icon: <PrintIcon onClick={() => {
                setDialogImprimir({
                    data: {
                        ...filters,
                        docs: data.docs
                    },
                    open: true
                })
            }} />,
            title: 'Imprimir',
            type: 'button'
        }
    ]

    const filterOptions = [
        {
            type: 'component',
            component: <UsuariosComboBox defaultValue={filters.user} onChange={handleChangeResponsavel} />,
            id: 'user',
            name: 'user',
            label: 'Responsável'
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Data de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Data até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        }
    ];

    const columns = [
        {
            id: 'numeroOs',
            label: '# OS',
            align: 'center'
        },
        {
            id: 'nomeCliente',
            label: 'Cliente',
            align: 'left'
        },
        {
            id: 'descricaoOs',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'nome',
            label: 'Tarefa',
            align: 'left'
        },
        {
            id: 'data',
            label: 'Data Solicitação',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'prazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        }
        ,
        {
            id: 'conclusao',
            label: 'Conclusão',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'id',
            label: 'Ver OS',
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <div key={'tarefas-' + index} style={{ minWidth: '135px' }}>
                   
                    <IconButton aria-label="Ver OS" title="Ver OS" href={'/os/' + doc.ordemServico}>
                        <DescriptionIcon color="primary" fontSize="small" />
                    </IconButton>
                    
                </div>
            )
        }
    ]

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            {!data.isLoading && data.docs ?
                <DataTable
                    columns={columns}
                    docs={data.docs}
                    page={data.page}
                    size={data.size}
                    count={data.count}
                    error={data.error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />
                :
                data.isLoading && <Loading open={data.isLoading} />
            }
            <ActionMenu actions={menuActions} />
            <Imprimir
                open={dialogImprimir.open}
                onClose={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
                data={dialogImprimir.data}
                params={dialogImprimir.data}
                confirm={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
                cancel={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
            />
        </>
    )
}

export default RelatorioAtividadesExecutadas;