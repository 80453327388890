import React, { Fragment } from 'react';
import { useForm } from "react-hook-form";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Link,
    Divider
} from '@material-ui/core';

import moment from "moment";
import { DropzoneAreaBase } from 'material-ui-dropzone';

import DeleteIcon from "@material-ui/icons/Delete"
import VisibilityOn from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

import { ServicosComboBox, UsuariosComboBox } from '../../components/DataComboBox';
import { DateField, CurrencyField, FloatField, IntegerField } from '../../components/MaskedFields';

import { useAuth } from '../../AuthContext';

function EditaItemOrdemServico(props) {
    const { user } = useAuth();
    const { register, handleSubmit, errors } = useForm();

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"md"}
            fullWidth
        >
            <DialogTitle id="form-dialog-title">{!props.readOnly ? 'Editar Item' : 'Ver Item'} - {props.data.nome}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {!!props.data.novo &&
                        <Grid item xs={12}>
                            <ServicosComboBox
                                onChange={props.handleChangeServicoField}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            name="etiqueta"
                            label="Etiqueta"
                            defaultValue={props.data.etiqueta}
                            onChange={props.handleChangeField}
                            fullWidth
                            disabled={!!props.readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {!props.readOnly &&
                            <UsuariosComboBox
                                name="usuario"
                                defaultValue={props.data.responsavelItem}
                                onChange={props.handleChangeUsuarioField}
                                inputRef={register()}
                            />
                        }
                        {!!props.readOnly &&
                            <TextField
                                name="responsavel"
                                label="Responsável"
                                value={props.data.responsavelItem?.nome}
                                fullWidth
                                disabled={true}
                            />
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <DateField
                            name="prazo"
                            label="Prazo"
                            value={props.data.prazo}
                            onChange={props.handleChangeDateField}
                            inputRef={register({ required: "*Campo obrigatório" })}
                            error={!!errors.prazo}
                            helperText={errors.prazo?.message}
                            fullWidth
                            disabled={!!props.readOnly}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <IntegerField
                            name="duracao"
                            label="Duração (horas)"
                            value={props.data.duracao}
                            onChange={props.handleChangeField}
                            inputRef={register({ required: "*Campo obrigatório", min: 1 })}
                            error={!!errors.duracao}
                            helperText={errors.duracao?.message}
                            fullWidth
                            disabled={!!props.readOnly}
                        />
                    </Grid>
                    {!props.readOnly &&
                        <>
                            {typeof user?.permissoes["Mostrar-valores"] !== 'undefined' && user?.permissoes["Mostrar-valores"] !== 3 &&
                                <>
                                    <Grid item xs={4}>
                                        <FloatField
                                            name="quantidade"
                                            label="Quantidade"
                                            value={props.data.quantidade}
                                            onChange={props.handleChangeField}
                                            inputRef={register({ required: "*Campo obrigatório" })}
                                            error={!!errors.quantidade}
                                            helperText={errors.quantidade?.message}
                                            fullWidth
                                            disabled={!!props.readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CurrencyField
                                            name="valor"
                                            label="Valor Unitário"
                                            value={props.data.valor}
                                            onChange={props.handleChangeField}
                                            inputRef={register({ required: "*Campo obrigatório" })}
                                            error={!!errors.valor}
                                            helperText={errors.valor?.message}
                                            fullWidth
                                            disabled={!!props.readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <CurrencyField
                                            name="total"
                                            label="Valor Total"
                                            value={props.data.total}
                                            onChange={props.handleChangeField}
                                            inputRef={register({ required: "*Campo obrigatório" })}
                                            error={!!errors.total}
                                            helperText={errors.total?.message}
                                            fullWidth
                                            disabled={!!props.readOnly}
                                        />
                                    </Grid>
                                </>
                            }
                        </>
                    }
                    <Grid item xs={12}>
                        <TextField
                            id="adiciona-mensagem"
                            name="mensagem"
                            label="Mensagem"
                            onKeyUp={props.handleKeyUpMensagem}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {props.data.mensagens && props.data.mensagens.map((mensagem, index) => (
                            <Fragment key={"mensagem-" + index}>
                                <ListItem key={index}>
                                    <ListItemText align="left"
                                        primary={mensagem.mensagem}
                                        secondary={mensagem.responsavel + ' (' + moment(mensagem.data).format("DD/MM/YYYY - HH:mm") + ')'}
                                    />
                                    <ListItemIcon>
                                        <IconButton title="Exibe Cliente" onClick={e => props.handleChangeMensagemVisibility(e, index)}>
                                            {mensagem.exibeCliente === true ? <VisibilityOn color="primary" /> : <VisibilityOff color="secondary" />}
                                        </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon>
                                        <IconButton title="Exclui Mensagem" onClick={e => props.handleClickDeleteMensagem(e, index)} disabled={!!props.readOnly}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItem>
                                <Divider />
                            </Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <DropzoneAreaBase
                            filesLimit={10}
                            fileObjects={props.data.filesToUpload}
                            dropzoneText="Clique ou arraste os arquivos aqui (max 10)"
                            maxFileSize={300000000}
                            onAdd={props.handleUploadFile}
                            onDelete={props.handleDeleteFile}
                            showPreviews={true}
                            showFileNamesInPreview={true}
                            useChipsForPreview
                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                            previewText="Arquivos selecionados"

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <List>
                            {props.data.anexos && props.data.anexos.map((anexo, index) => (
                                <Fragment key={"anexo-" + index}>
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <DescriptionOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText align="center">
                                            <Link href={anexo.url} target="_blank">
                                                {anexo.descricao}
                                            </Link>
                                        </ListItemText>
                                        <ListItemIcon>
                                            <IconButton title="Exibe Cliente" onClick={e => props.handleChangeAnexoVisibility(e, index)}>
                                                {anexo.exibeCliente === true ? <VisibilityOn color="primary" /> : <VisibilityOff color="secondary" />}
                                            </IconButton>
                                        </ListItemIcon>
                                        <ListItemIcon>
                                            <IconButton title="Exclui anexo" onClick={e => props.handleClickDeleteFile(e, index)} disabled={!!props.readOnly}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemIcon>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.data.conclusao && <Button onClick={props.reopenTask} color="secondary">Reabrir tarefa</Button>}
                {props.confirm && <Button onClick={handleSubmit(props.confirm)} color="primary" autoFocus>Ok</Button>}
                {props.cancel && <Button onClick={props.cancel}>Cancelar</Button>}
            </DialogActions>
        </Dialog>
    )
}

export default EditaItemOrdemServico;