import React from 'react';
import ReactToPrint from 'react-to-print';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';
import moment from 'moment';
import 'devbox-linq';

function Imprimir(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"lg"}
            fullWidth
        >
            <DialogContent>
                <Grid container spacing={3} id="imprimir" style={{ padding: '50px' }}>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="STK" height="80" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography variant="h6" align="center" style={{ fontSize: '0.7rem' }}>
                            <b>Atividades executadas no período</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableHead>
                                <TableRow key="datatable-header">
                                    <TableCell style={{ fontSize: '0.7rem' }} key="numeroOs" align="center"><b># OS</b></TableCell>
                                    <TableCell style={{ fontSize: '0.7rem' }} key="porcentagemConcluidos" align="center"><b>Porcentagem Concluídos</b></TableCell>
                                    <TableCell style={{ fontSize: '0.7rem' }} key="cliente" align="left"><b>Cliente</b></TableCell>
                                    <TableCell style={{ fontSize: '0.7rem' }} key="responsavel" align="left"><b>Responsável</b></TableCell>
                                    <TableCell style={{ fontSize: '0.7rem' }} key="tarefa" align="left"><b>Tarefa</b></TableCell>
                                    <TableCell style={{ fontSize: '0.7rem' }} key="status" align="center"><b>Status Tarefa</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.data.docs?.map((tarefa, index) => (
                                    <TableRow key={'tarefa-' + index}>
                                        <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.numeroOs}</TableCell>
                                        <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.porcentagemConcluidos}</TableCell>
                                        <TableCell style={{ fontSize: '0.7rem' }} align="left">{tarefa.nomeCliente}</TableCell>
                                        <TableCell style={{ fontSize: '0.7rem' }} align="left">{tarefa.responsavel}</TableCell>
                                        <TableCell style={{ fontSize: '0.7rem' }} align="left">{tarefa.nome}</TableCell>
                                        <TableCell style={{ fontSize: '0.7rem' }} align="center">{tarefa.status}</TableCell>
                                   
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid item xs={12} style={{ fontSize: '0.7rem' }}>
                        <p>São Bento do Sul, {moment().format("DD [de] MMMM [de] YYYY")}</p>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {props.confirm &&
                    <ReactToPrint
                        trigger={() => {
                            return <Button color="primary" autoFocus>Imprimir</Button>
                        }}
                        content={() => document.getElementById("imprimir")}
                    />}
                {props.cancel && <Button onClick={props.cancel}>Cancelar</Button>}
            </DialogActions>
        </Dialog >
    )
}

export default Imprimir;