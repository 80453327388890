import React, { useState, useEffect } from 'react';
import {
    IconButton,
    Box,
    useTheme,
    Grid,
    Card,
    CardContent,
    Typography,
    CardHeader,
} from '@material-ui/core';

import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import ActionMenu from '../../components/ActionMenu';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import NumberField from "../../components/NumberField";

import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DotIcon from '@material-ui/icons/FiberManualRecord';

import ContasPagar from '../../services/ContasPagar';
import { useAuth } from '../../AuthContext';

const ListaContasPagar = (props) => {
    const theme = useTheme();
    const { user } = useAuth();
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        totals: [],
        page: 0,
        size: 30,
        count: 0,
        filters: '&situacao[$in]=Em aberto,Em atraso'
    });

    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        status: ["Em aberto", "Em atraso"]
    });

    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });

    const Listar = (page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        ContasPagar.list(page, size, filter).then(contasPagar => {
            ContasPagar.totals(filter).then(totals => {
                setData({
                    isLoading: false,
                    docs: contasPagar.docs,
                    totals: totals,
                    page: contasPagar.page - 1,
                    size: contasPagar.limit,
                    count: contasPagar.totalDocs,
                    filters: filter
                });
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        });
    }

    const handleClickDelete = (event, id, name) => {
        setRemove({
            open: true,
            name: name,
            id: id
        })
    }

    const handleDeleteContaPagar = (event) => {
        ContasPagar.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Contas a Pagar");
        Listar(data.page, data.size, data.filters);
    }, [data.page, data.size, data.filters]);


    const columns = [
        {
            id: 'vencimento',
            label: 'Vencimento',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'pessoa',
            label: 'Pessoa',
            align: 'left',
            format: (pessoa) => pessoa?.nome
        },
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'emissao',
            label: 'Emissão',
            align: 'center',
            format: (value) => (
                <>
                    {new Date(value).toLocaleDateString('pt-BR')}
                    <br />
                    {new Date(value).toLocaleTimeString('pt-BR')}
                </>
            )
        },
        {
            id: 'valor',
            label: 'Valor Original',
            align: 'right',
            format: (value) => <NumberField
                value={value}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={2}
                prefix="R$ "
            />
        },
        {
            id: 'saldo',
            label: 'Saldo',
            align: 'right',
            format: (value) => <NumberField
                value={value}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={2}
                prefix="R$ "
            />
        },
        {
            id: 'situacao',
            label: 'Situação',
            align: 'left',
            width: 150,
            format: (value) => (
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    {value === "Em aberto" && <DotIcon style={{ color: theme.palette.warning.main }} />}
                    {value === "Em atraso" && <DotIcon color="secondary" />}
                    {value === "Baixado" && <DotIcon style={{ color: theme.palette.success.main }} />}
                    <span>{value}</span>
                </Box>
            )
        },
        {
            id: '_id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                <>
                    <IconButton aria-label="Editar" href={'/pagar/' + doc._id}>
                        {user?.permissoes["Contas-a-Pagar-alterar"] ? (
                            <EditIcon color="primary" />
                        ) : (
                            <VisibilityIcon color="primary" />
                        )}
                    </IconButton>
                    {user?.permissoes["Contas-a-Pagar-excluir"] && (
                        <IconButton aria-label="Excluir" onClick={e => handleClickDelete(e, doc._id, doc.descricao)} >
                            <DeleteIcon color="secondary" />
                        </IconButton>
                    )}
                </>
            )
        },
    ];

    const handleKeyDownSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][numero]=" + filters.search;
            qs += "&$or[1][descricao][$regex]=" + filters.search;
            qs += "&$or[1][descricao][$options]=gi";
            qs += "&$or[2][pessoa.cpf][$regex]=" + filters.search;
            qs += "&$or[2][pessoa.cpf][$options]=gi";
            qs += "&$or[3][pessoa.nome][$regex]=" + filters.search;
            qs += "&$or[3][pessoa.nome][$options]=gi";
        }

        if (filters.dateFrom) qs += '&emissao[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&emissao[$lte]=' + filters.dateTo;
        if (filters.dueDateFrom) qs += '&vencimento[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&vencimento[$lte]=' + filters.dueDateTo;
        if (filters.status.length > 0) qs += "&situacao[$in]=" + filters.status.join();

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            status: ["Em aberto", "Em atraso"]
        });

        setData({ ...data, filters: '&situacao[$in]=Em aberto,Em atraso' })
    }

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyDownSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Emissão de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Emissão até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Vencimento de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Vencimento até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        },
        {
            type: 'select',
            id: 'status',
            name: 'status',
            label: 'Situação',
            value: filters.status,
            options: ['Em aberto', 'Em atraso', 'Baixado'],
            onChange: (e) => { setFilters({ ...filters, status: e.target.value }) }
        }
    ];

    const { isLoading, docs, totals, page, size, count, error } = data;

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
            <Grid container justify="center" spacing={3} style={{ marginBottom: "1px" }}>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader style={{ background: theme.palette.success.dark, color: 'white' }} title="Valor original" align="center" titleTypographyProps={{ variant: 'body1' }} />
                        <CardContent align="center">
                            <Typography align="center" variant="h5">
                                <NumberField
                                    value={totals.sum(x => x.total)}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    prefix="R$ "
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader style={{ background: theme.palette.warning.dark, color: 'white' }} title="Saldo a receber" align="center" titleTypographyProps={{ variant: 'body1' }} />
                        <CardContent align="center">
                            <Typography align="center" variant="h5">
                                <NumberField
                                    value={totals.filter(x => x._id !== "Baixado").sum(x => x.saldo)}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    prefix="R$ "
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardHeader style={{ background: theme.palette.error.dark, color: 'white' }} title="Saldo em atraso" align="center" titleTypographyProps={{ variant: 'body1' }} />
                        <CardContent align="center">
                            <Typography align="center" variant="h5">
                                <NumberField
                                    value={totals.filter(x => x._id === "Em atraso").sum(x => x.saldo)}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    prefix="R$ "
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {!isLoading ? (
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />) : (
                <Loading open={isLoading} />
            )}
            {user?.permissoes["Contas-a-Pagar-incluir"] && (
                <ActionMenu
                    actions={[
                        {
                            icon: <IconButton href="/pagar/new"><DescriptionOutlinedIcon /></IconButton>,
                            title: 'Incluir',
                            type: 'button'
                        }
                    ]}
                />
            )}
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteContaPagar}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </>
    )
}

export default ListaContasPagar;