import React, { useState, useEffect } from 'react';
import {
    Box,
    useTheme,
    Grid,
    Card,
    CardContent,
    Typography,
    CardHeader,
} from '@material-ui/core';
import ActionMenu from '../../components/ActionMenu';
import FilterContainer from '../../components/FilterContainer';
import DataTable from '../../components/DataTable';
import Loading from '../../components/Loading';
import NumberField from "../../components/NumberField";
import DotIcon from '@material-ui/icons/FiberManualRecord';
import ContasPagar from '../../services/ContasPagar';

import Imprimir from './Imprimir';

import PrintIcon from "@material-ui/icons/Print";


const RelatorioDespesas = (props) => {
    const theme = useTheme();
    const [data, setData] = useState({
        isLoading: true,
        error: null,
        docs: [],
        totals: [],
        page: 0,
        size: 30,
        count: 0,
        filters: '&situacao[$in]=Em aberto,Em atraso'
    });

      const [dialogImprimir, setDialogImprimir] = useState({
        data: {},
        open: false
    });

    const [filters, setFilters] = useState({
        search: '',
        dateFrom: null,
        dateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        status: ["Em aberto", "Em atraso"]
    });

    const Listar = (page, size, filter) => {
        setData(prevData => {
            return {
                ...prevData,
                isLoading: true
            }
        })

        ContasPagar.list(page, size, filter).then(contasPagar => {
            ContasPagar.totals(filter).then(totals => {
                setData({
                    isLoading: false,
                    docs: contasPagar.docs,
                    totals: totals,
                    page: contasPagar.page - 1,
                    size: contasPagar.limit,
                    count: contasPagar.totalDocs,
                    filters: filter
                });
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        setData({
            ...data,
            page: newPage
        })
    }

    const handleChangeSize = (event) => {
        setData({
            ...data,
            size: +event.target.value
        });
    }

   

   

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Despesas Em Aberto");
        Listar(data.page, data.size, data.filters);
    }, [data.page, data.size, data.filters]);

     const menuActions = [
        {
            icon: <PrintIcon onClick={() => {
                setDialogImprimir({
                    data: {
                        ...filters,
                        docs: data.docs
                    },
                    open: true
                })
            }} />,
            title: 'Imprimir',
            type: 'button'
        }
    ]
    console.log(data.docs)
    const columns = [
        
     
        { 
            id: 'ordemServicoNumero',
            label: '# OS',
            align: 'center'
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'pessoa',
            label: 'Pessoa',
            align: 'left',
            format: (pessoa) => pessoa?.nome
        },
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'emissao',
            label: 'Emissão',
            align: 'center',
            format: (value) => (
                <>
                    {new Date(value).toLocaleDateString('pt-BR')}
                    <br />
                    {new Date(value).toLocaleTimeString('pt-BR')}
                </>
            )
        },
        {
            id: 'vencimento',
            label: 'Vencimento',
            align: 'center',
            format: (value) => new Date(value).toLocaleDateString('pt-BR')
        },
        {
            id: 'valor',
            label: 'Valor Original',
            align: 'right',
            format: (value) => <NumberField
                value={value}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={2}
                prefix="R$ "
            />
        },
        {
            id: 'saldo',
            label: 'Saldo',
            align: 'right',
            format: (value) => <NumberField
                value={value}
                displayType="text"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale={2}
                prefix="R$ "
            />
        },
        {
            id: 'situacao',
            label: 'Situação',
            align: 'left',
            width: 150,
            format: (value) => (
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    {value === "Em aberto" && <DotIcon style={{ color: theme.palette.warning.main }} />}
                    {value === "Em atraso" && <DotIcon color="secondary" />}
                    {value === "Baixado" && <DotIcon style={{ color: theme.palette.success.main }} />}
                    <span>{value}</span>
                </Box>
            )
        }
    ];

    const handleKeyDownSearch = (event) => {

        setFilters({
            ...filters,
            search: event.target.value
        })

        if (event.keyCode === 13) {
            handleApplyFilters();
        }
    }

    const handleApplyFilters = () => {
        let qs = '';

        if (filters.search) {
            qs += "&$or[0][numero]=" + filters.search;
            qs += "&$or[1][descricao][$regex]=" + filters.search;
            qs += "&$or[1][descricao][$options]=gi";
            qs += "&$or[2][pessoa.cpf][$regex]=" + filters.search;
            qs += "&$or[2][pessoa.cpf][$options]=gi";
            qs += "&$or[3][pessoa.nome][$regex]=" + filters.search;
            qs += "&$or[3][pessoa.nome][$options]=gi";
        }

        if (filters.dateFrom) qs += '&emissao[$gte]=' + filters.dateFrom;
        if (filters.dateTo) qs += '&emissao[$lte]=' + filters.dateTo;
        if (filters.dueDateFrom) qs += '&vencimento[$gte]=' + filters.dueDateFrom;
        if (filters.dueDateTo) qs += '&vencimento[$lte]=' + filters.dueDateTo;
        if (filters.status.length > 0) qs += "&situacao[$in]=" + filters.status.join();

        setData({
            ...data,
            filters: qs
        })
    }

    const handleDismissFilters = () => {
        document.getElementById("search").value = '';
        setFilters({
            search: '',
            dateFrom: null,
            dateTo: null,
            dueDateFrom: null,
            dueDateTo: null,
            status: ["Em aberto", "Em atraso"]
        });

        setData({ ...data, filters: '&situacao[$in]=Em aberto,Em atraso' })
    }

    const filterOptions = [
        {
            type: 'search',
            id: 'search',
            name: 'search',
            label: 'Pesquisar',
            onKeyUp: handleKeyDownSearch,
            onBlur: (e) => { setFilters({ ...filters, search: e.target.value }) }
        },
        {
            type: 'date',
            id: 'dateFrom',
            name: 'dateFrom',
            label: 'Emissão de',
            value: filters.dateFrom,
            onChange: (value) => { setFilters({ ...filters, dateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dateTo',
            name: 'dateTo',
            label: 'Emissão até',
            value: filters.dateTo,
            onChange: (value) => { setFilters({ ...filters, dateTo: value?.endOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateFrom',
            name: 'dueDateFrom',
            label: 'Vencimento de',
            value: filters.dueDateFrom,
            onChange: (value) => { setFilters({ ...filters, dueDateFrom: value?.startOf('day').toISOString() }) }
        },
        {
            type: 'date',
            id: 'dueDateTo',
            name: 'dueDateTo',
            label: 'Vencimento até',
            value: filters.dueDateTo,
            onChange: (value) => { setFilters({ ...filters, dueDateTo: value?.endOf('day').toISOString() }); }
        }
    ];

    const { isLoading, docs, totals, page, size, count, error } = data;

    return (
        <>
            <FilterContainer
                title="Filtros"
                filters={filterOptions}
                handleApplyFilters={handleApplyFilters}
                handleDismissFilters={handleDismissFilters}
            />
             <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader style={{ background: theme.palette.warning.dark, color: 'white' }} title="Saldo a receber" align="center" titleTypographyProps={{ variant: 'body1' }} />
                        <CardContent align="center">
                            <Typography align="center" variant="h5">
                                <NumberField
                                    value={totals.filter(x => x._id !== "Baixado").sum(x => x.saldo)}
                                    displayType="text"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale={2}
                                    prefix="R$ "
                                />
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
           
            {!isLoading ? (
                <DataTable
                    columns={columns}
                    docs={docs}
                    page={page}
                    size={size}
                    count={count}
                    error={error}
                    handleChangePage={handleChangePage}
                    handleChangeSize={handleChangeSize}
                />) : (
                <Loading open={isLoading} />
                
            )}
             <ActionMenu actions={menuActions} />
            <Imprimir
                open={dialogImprimir.open}
                onClose={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
                data={dialogImprimir.data}
                params={dialogImprimir.data}
                confirm={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
                cancel={() => setDialogImprimir({
                    data: {},
                    open: false
                })}
            />
           
        </>
    )
}

export default RelatorioDespesas;