import styled from 'styled-components';
import MuiAvatar from '@material-ui/core/Avatar';
import MuiMenuItem from '@material-ui/core/MenuItem';

export const Avatar = styled(MuiAvatar)`
    color: black !important;
    background: #ecf1f8 !important;
`;

export const MenuItem = styled(MuiMenuItem)`
    font-size: 0.8rem !important;
`;