import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Grid
} from '@material-ui/core';

import { DateField, CurrencyField } from '../../components/MaskedFields';

function Baixa(props) {
    const [ error, setError ] = useState({
        state: false,
        message: ''
    });

    const handleError = (event) => {
        if(event.target.value > props.data.saldo){
            setError({
                state: true,
                message: 'O valor da baixa não pode ser maior do que o saldo a receber'
            })
        } else {
            setError({
                state: false,
                message: ''
            })
        }
    } 

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
        >
            <DialogTitle id="form-dialog-title">Baixa</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DateField
                            name="data_baixa"
                            label="Data da Baixa"
                            value={props.data.data_baixa}
                            onChange={props.handleChangeDateField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="historico"
                            label="Histórico"
                            size="small"
                            defaultValue={props.data.historico}
                            onChange={props.handleChangeField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyField
                            name="valor_baixa"
                            label="Valor"
                            value={props.data.valor_baixa}
                            defaultValue={props.data.valor_baixa}
                            onChange={e => { props.handleChangeField(e); handleError(e)}}
                            error={error.state}
                            helperText={error.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyField
                            name="desconto"
                            label="Desconto"
                            value={props.data.desconto}
                            defaultValue={props.data.desconto}
                            onChange={props.handleChangeField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyField
                            name="juros"
                            label="Juros"
                            value={props.data.juros}
                            defaultValue={props.data.juros}
                            onChange={props.handleChangeField}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CurrencyField
                            name="multa"
                            label="Multa"
                            value={props.data.multa}
                            defaultValue={props.data.multa}
                            onChange={props.handleChangeField}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.confirm} color="primary" autoFocus disabled={error.state}>Ok</Button>
                <Button onClick={props.cancel}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Baixa;