import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    TextField,
    Grid,
    Snackbar,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

import {
    Alert
} from "@material-ui/lab";

import { useForm } from "react-hook-form";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"

import { Container, Paper } from "./styles";

import Loading from "../../components/Loading";
import { FloatField, IntegerField } from '../../components/MaskedFields';
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import Condicoes from "../../services/Condicoes";
import { useAuth } from '../../AuthContext';

const EditaCondicao = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const [condicao, setCondicao] = useState({});
    const [loading, setLoading] = useState(true);
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    });

    const saveForm = () => {
        setLoading(true);

        if (id === 'new') {
            Condicoes.create(condicao).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro realizado com sucesso"
                });

                Condicoes.retrieve(response._id).then(response => {
                    setCondicao(response);
                    setLoading(false);

                    window.location.href = '/condicoes/' + response._id;
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        } else {
            Condicoes.update(condicao._id, condicao).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                Condicoes.retrieve(id).then((response) => {
                    setCondicao(response);
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        }
    }

    const handleChangeField = (event) => {
        let updateCondicao = { ...condicao };
        updateCondicao[event.target.name] = event.target.value;
        setCondicao(updateCondicao);
    }

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: condicao.descricao,
            id: condicao._id
        })
    }

    const handleDeleteCondicao = (event) => {
        Condicoes.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/condicoes';
        }).catch(error => {
            setLoading(false);

            setRemove({
                open: false,
                name: '',
                id: ''
            });

            setResponseStatus({
                open: true,
                severity: "error",
                message: (error.message ? error.message : "Ocorreu um problema ao excluir o cadastro. Tente novamente")
            });
        });
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar condição");
        if (id !== 'new') {
            Condicoes.retrieve(id).then((response) => {
                setCondicao(response);
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        } else {
            setCondicao({
                metodoPagamento: 'Boleto'
            })
            setLoading(false);
        }
    }, [id]);

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit'
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button'
        }
    ]

    return !loading ? (
        <Container>
            <Paper
                square
                style={{
                    padding: '25px'
                }}
            >
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="descricao"
                                label="Descrição"
                                size="small"
                                defaultValue={condicao.descricao}
                                fullWidth
                                onChange={handleChangeField}
                                inputRef={register({ required: "*Campo obrigatório" })}
                                error={!!errors.descricao}
                                helperText={errors.descricao?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="forma-label">Forma Pagamento</InputLabel>
                                <Select
                                    name="metodoPagamento"
                                    labelid="forma-label"
                                    size="small"
                                    value={condicao.metodoPagamento}
                                    onChange={handleChangeField}
                                    fullWidth
                                >
                                    <MenuItem key="Boleto" value="Boleto">Boleto</MenuItem>
                                    <MenuItem key="Cartão Crédito" value="Cartão Crédito">Cartão Crédito</MenuItem>
                                    <MenuItem key="Cartão Débito" value="Cartão Débito">Cartão Débito</MenuItem>
                                    <MenuItem key="Cheque" value="Cheque">Cheque</MenuItem>
                                    <MenuItem key="Dinheiro" value="Dinheiro">Dinheiro</MenuItem>
                                    <MenuItem key="Transferência Bancária" value="Transferência Bancária">Transferência Bancária</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <IntegerField
                                name="parcelas"
                                label="Parcelas"
                                size="small"
                                defaultValue={condicao.parcelas}
                                fullWidth
                                onChange={handleChangeField}
                                inputRef={register({ required: "*Campo obrigatório" })}
                                error={!!errors.parcelas}
                                helperText={errors.parcelas?.message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FloatField
                                name="acrescimo"
                                label="Acréscimo"
                                value={condicao.acrescimo}
                                defaultValue={condicao.acrescimo}
                                onChange={handleChangeField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FloatField
                                name="juros"
                                label="Juros"
                                value={condicao.juros}
                                defaultValue={condicao.juros}
                                onChange={handleChangeField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FloatField
                                name="taxaAdministracao"
                                label="Taxa Administração"
                                value={condicao.taxaAdministracao}
                                defaultValue={condicao.taxaAdministracao}
                                onChange={handleChangeField}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                name="texto"
                                label="Texto para orçamento"
                                value={condicao.texto}
                                defaultValue={condicao.texto}
                                onChange={handleChangeField}
                                multiline
                                rows={5}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {(user?.permissoes["Condições-alterar"] || (user?.permissoes["Condições-incluir"] && id === 'new')) && (
                        <ActionMenu actions={menuActions} />
                    )}
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteCondicao}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
        </Container>
    ) :
        (loading && (
            <Loading open={loading} />
        ))
};

export default EditaCondicao;