import styled from 'styled-components';

export const Menu = styled.ul`
    padding: 0px;
    width: 235px;
    margin-top: 20px;
    font-size: inherit;
`;

export const SubMenu = styled.ul`
    width: 100%;
    font-size: inherit;
    padding: 0px;
`;

export const SubMenuItem = styled.li`
    padding: 10px 20px 10px 50px;
    display: flex;
    font-size: inherit;
    align-items: center;
    &:hover {
        cursor: pointer;
        background: #dae1ea;
    }
`;

export const Link = styled.a`
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
    font-size: inherit;
`