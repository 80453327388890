import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import {
    TextField,
    Tabs,
    Tab,
    Box,
    Grid,
    MenuItem,
    Snackbar,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Link,
    Divider,
    TableRow,
    TableCell,
    Checkbox
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";

import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import DescriptionIcon from "@material-ui/icons/Description";
import ArchiveIcon from "@material-ui/icons/Archive";
import PrintIcon from "@material-ui/icons/Print";

import { useForm } from "react-hook-form";
import moment from "moment";

import { Container, Paper } from "./styles";

import EditaItemOrdemServico from "./item";
import EditaReceita from "./receita";
import EditaDespesa from "./despesa";
import EditaProtocolo from "./protocolo";
import ConcluiTarefa from './concluiTarefa'
import ParametrosOrcamento from './parametrosOrcamento'
import ImprimeOrcamento from './imprimeOrcamento'
import ImprimeFichaServico from './imprimeFichaServico'
import ImprimeRecibo from './imprimeRecibo'
import Baixa from '../EditaContasReceber/baixa';

import DataTable from "../../components/DataTable";
import Loading from "../../components/Loading";
import ActionMenu from "../../components/ActionMenu";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { DateField } from "../../components/MaskedFields";
import NumberField from "../../components/NumberField";

import {
    CondicoesComboBox,
    PessoasComboBox,
    UsuariosComboBox,
    ImoveisComboBox
} from "../../components/DataComboBox";

import OrdensServico from "../../services/OrdensServico";
import Servicos from "../../services/Servicos";
import ContasReceber from "../../services/ContasReceber";
import ContasPagar from "../../services/ContasPagar";
import Protocolos from "../../services/Protocolos";

import { useAuth } from '../../AuthContext';

import { green } from "@material-ui/core/colors";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
        style: {
            'minWidth': '120px'
        }
    };
}

const EditaOrdemServico = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const [tab, setTab] = useState(0);
    const [ordemServico, setOrdemServico] = useState({});
    const [receitas, setReceitas] = useState([]);
    const [despesas, setDespesas] = useState([]);
    const [protocolos, setProtocolos] = useState([]);
    const [mensagens, setMensagens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogEditaItem, setDialogEditaItem] = useState({
        data: {},
        open: false
    });
    const [dialogEditaReceita, setDialogEditaReceita] = useState({
        data: {},
        open: false
    });
    const [dialogEditaDespesa, setDialogEditaDespesa] = useState({
        data: {},
        open: false
    });
    const [dialogEditaProtocolo, setDialogEditaProtocolo] = useState({
        data: {},
        open: false
    });
    const [dialogImprimeOrcamento, setDialogImprimeOrcamento] = useState({
        data: {},
        open: false
    });
    const [dialogParametrosOrcamento, setDialogParametrosOrcamento] = useState({
        data: {
            logotipo: 'STK',
            valores_itens: 'Sim'
        },
        open: false
    });
    const [dialogImprimeFichaServico, setDialogImprimeFichaServico] = useState({
        data: {},
        open: false
    });
    const [dialogImprimeRecibo, setDialogImprimeRecibo] = useState({
        data: {},
        open: false
    });
    const [dialogBaixa, setDialogBaixa] = useState({
        data: {},
        open: false
    });
    const [remove, setRemove] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [dialogConcluir, setDialogConcluir] = useState({
        data: {},
        open: false
    });
    const [despesasSelecionadas, setDespesasSelecionadas] = useState([]);
    const [dialogQuitar, setDialogQuitar] = useState({
        open: false
    })
    const [conclui, setConclui] = useState({
        open: false,
        name: '',
        id: ''
    });
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    });

    const { register, handleSubmit, errors } = useForm();

    const saveForm = () => {
        setLoading(true);

        const updateOrdemServico = { ...ordemServico };
        let servicos = [];

        for (let index = 0; index < updateOrdemServico.servicos.length; index++) {
            const servico = updateOrdemServico.servicos[index];

            if (servico.item) {
                servicos.push(servico);
            }
        }

        updateOrdemServico.servicos = servicos;

        if (id === 'new') {
            OrdensServico.create(updateOrdemServico).then(async response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro realizado com sucesso"
                });

                for (let index = 0; index < receitas.length; index++) {
                    const receita = receitas[index];
                    receita.ordemServico = response._id;

                    await ContasReceber.create(receita).catch(error => {
                        console.log(error);
                    })
                }

                for (let index = 0; index < despesas.length; index++) {
                    const despesa = despesas[index];
                    despesa.ordemServico = response._id;

                    await ContasPagar.create(despesa).catch(error => {
                        console.log(error);
                    })
                }

                for (let index = 0; index < protocolos.length; index++) {
                    const protocolo = protocolos[index];
                    protocolo.ordemServico = response._id;

                    await Protocolos.create(protocolo).catch(error => {
                        console.log(error);
                    })
                }

                setOrdemServico(response);
                setLoading(false);

                window.location.href = '/os/' + response._id;

            }).catch((error) => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });

                setLoading(false);
            });
        } else {
            OrdensServico.update(updateOrdemServico._id, updateOrdemServico).then(async response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                for (let index = 0; index < receitas.length; index++) {
                    const receita = receitas[index];

                    if (!receita.deleted) {
                        if (receita.novo) {
                            await ContasReceber.create(receita).catch(error => {
                                console.log(error);
                            })
                        } else {
                            await ContasReceber.update(receita._id, receita).catch(error => {
                                console.log(error);
                            })
                        }
                    } else {
                        await ContasReceber.remove(receita._id).catch(error => {
                            console.log(error);
                        })
                    }
                }

                for (let index = 0; index < despesas.length; index++) {
                    const despesa = despesas[index];

                    if (!despesa.deleted) {
                        if (despesa.novo) {
                            await ContasPagar.create(despesa).catch(error => {
                                console.log(error);
                            })
                        } else {
                            await ContasPagar.update(despesa._id, despesa).catch(error => {
                                console.log(error);
                            })
                        }
                    } else {
                        await ContasPagar.remove(despesa._id).catch(error => {
                            console.log(error);
                        })
                    }
                }

                for (let index = 0; index < protocolos.length; index++) {
                    const protocolo = protocolos[index];

                    if (!protocolo.deleted) {
                        if (protocolo.novo) {
                            await Protocolos.create(protocolo).catch(error => {
                                console.log(error);
                            })
                        } else {
                            await Protocolos.update(protocolo._id, protocolo).catch(error => {
                                console.log(error);
                            })
                        }
                    } else {
                        await Protocolos.remove(protocolo._id).catch(error => {
                            console.log(error);
                        })
                    }
                }

                for (let index = 0; index < despesasSelecionadas.length; index++) {
                    const despesa = despesasSelecionadas[index];

                    ContasPagar.retrieve(despesa.id).then(res => {
                        const baixarDespesa = {
                            saldo: 0,
                            baixas: res.baixas.concat({
                                data_baixa: moment().toISOString(),
                                historico: "Quitação de despesa via recebimento",
                                valor_baixa: res.saldo,
                                desconto: 0,
                                multa: 0,
                                juros: 0
                            })
                        }

                        ContasPagar.update(despesa.id, baixarDespesa).catch(error => {
                            setResponseStatus({
                                open: true,
                                severity: "error",
                                message: (error.message ? error.message : "Ocorreu um erro ao baixar a despesa. Tente novamente.")
                            });
                        })
                    }).catch(error => {
                        setResponseStatus({
                            open: true,
                            severity: "error",
                            message: (error.message ? error.message : "Ocorreu um erro ao baixar a despesa. Tente novamente.")
                        });
                    })
                }

                OrdensServico.retrieve(id).then((response) => {
                    transformarOS(response);
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                });

                ContasReceber.list(0, 10000, "&ordemServico=" + id).then((response) => {
                    setReceitas(response.docs);
                }).catch((error) => {
                    console.log(error);
                })

                ContasPagar.list(0, 10000, "&ordemServico=" + id).then((response) => {
                    setDespesas(response.docs);
                }).catch((error) => {
                    console.log(error);
                })

                Protocolos.list(0, 10000, "&ordemServico=" + id).then((response) => {
                    setProtocolos(response.docs);
                }).catch((error) => {
                    console.log(error);
                })
            }).catch((error) => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente " + JSON.stringify(error))
                });

                setLoading(false);
            });
        }
    }

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    }

    const handleChangeField = (event, aux, obj, index) => {
        const updateOrdemServico = { ...ordemServico };
        const value = event.target.value;

        if (obj) {
            let field = event.target.name.split('-')[event.target.name.split('-').length - 1];

            if (index >= 0) {
                if (!updateOrdemServico[obj]) updateOrdemServico[obj] = [];
                updateOrdemServico[obj][index][field] = value;
            } else {
                if (!updateOrdemServico[obj]) updateOrdemServico[obj] = {};

                updateOrdemServico[obj][field] = value;
            }
        } else {
            updateOrdemServico[event.target.name] = value;
        }

        setOrdemServico(updateOrdemServico);
    }

    const handleChangeItemField = (event) => {
        const field = event.target.name;
        const updateItem = { ...dialogEditaItem };

        if (field === 'quantidade') {
            updateItem.data.total = event.target.value * updateItem.data.valor;
        }

        if (field === 'valor') {
            updateItem.data.total = event.target.value * updateItem.data.quantidade;
        }

        updateItem.data[field] = event.target.value;
        setDialogEditaItem(updateItem);
    }

    const handleChangeDateItem = (date, field) => {
        if (date) {
            const updateItem = { ...dialogEditaItem };
            updateItem.data[field] = date.toISOString();
            setDialogEditaItem(updateItem);
        }
    }

    const handleChangeUsuarioItem = (event, usuario) => {
        if (usuario) {
            const updateItem = { ...dialogEditaItem };
            updateItem.data.responsavel = usuario._id;
            updateItem.data.responsavelItem = usuario;
            setDialogEditaItem(updateItem);
        }
    }

    const handleChangeServicoItem = (event, servico) => {
        if (servico) {
            let prazo = moment();

            switch (ordemServico.prioridade) {
                case "Alta": prazo = moment(prazo).add(servico.alta, "days"); break;
                case "Média": prazo = moment(prazo).add(servico.media, "days"); break;
                case "Baixa": prazo = moment(prazo).add(servico.baixa, "days"); break;
                default: break;
            }

            const updateItem = { ...dialogEditaItem };
            updateItem.data.id = servico._id;
            updateItem.data.item = ordemServico.servicos.length + 1;
            updateItem.data.nome = servico.nome;
            updateItem.data.data = new Date().toISOString();
            updateItem.data.prazo = moment(prazo).toISOString();
            updateItem.data.duracao = servico.duracao;
            updateItem.data.quantidade = 1;
            updateItem.data.valor = servico.valor;
            updateItem.data.total = servico.valor;
            updateItem.data.status = "Pendente";
            updateItem.data.adicionais = servico.adicionais

            setDialogEditaItem(updateItem);
        }
    }

    const handleKeyUpMensagemItem = (event) => {
        if (event.keyCode === 13 && event.target.value !== "") {
            let updateItem = { ...dialogEditaItem };

            if (!updateItem.data.mensagens) {
                updateItem.data.mensagens = [];
            }

            updateItem.data.mensagens.push({
                data: moment().toISOString(),
                mensagem: event.target.value,
                responsavel: user?.nome
            });
            console.log(updateItem);
            setDialogEditaItem(updateItem);
            document.getElementById("adiciona-mensagem").value = '';
        }

    }

    const handleClickDeleteMensagemItem = (event, index) => {
        const updateItem = { ...dialogEditaItem };
        updateItem.data.mensagens.splice(index, 1);
        setDialogEditaItem(updateItem);
    }

    const handleUploadFileItem = (newFiles) => {
        const updateItem = { ...dialogEditaItem };
        updateItem.data.filesToUpload = updateItem.data.filesToUpload ? updateItem.data.filesToUpload.concat(newFiles) : newFiles;
        setDialogEditaItem(updateItem);
    }

    const handleDeleteFileItem = (file) => {
        const updateItem = { ...dialogEditaItem };
        updateItem.data.filesToUpload.splice(updateItem.data.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setDialogEditaItem(updateItem);
    }

    const handleClickDeleteFileItem = (event, index) => {
        const updateItem = { ...dialogEditaItem };
        updateItem.data.anexos.splice(index, 1);
        setDialogEditaItem(updateItem);
    }

    const handleChangeAnexoVisibility = (event, index) => {
        const updateItem = { ...dialogEditaItem };
        updateItem.data.anexos[index].exibeCliente = !!!updateItem.data.anexos[index].exibeCliente;
        setDialogEditaItem(updateItem);
    }

    const handleChangeMensagemVisibility = (event, index) => {
        const updateItem = { ...dialogEditaItem };
        updateItem.data.mensagens[index].exibeCliente = !!!updateItem.data.mensagens[index].exibeCliente;
        setDialogEditaItem(updateItem);
    }

    const handleReopenTask = (event) => {
        const updateOrdemServico = { ...ordemServico };
        const index = updateOrdemServico.servicos.findIndex(x => x.item === dialogEditaItem.data.item);
        updateOrdemServico.servicos[index].status = 'Pendente';
        updateOrdemServico.servicos[index].conclusao = null;
        if (index < ordemServico.servicos.length) {
            ordemServico.servicos[index].reabretask = true;
            ordemServico.servicos[index].enviaEmail = false;
        }

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: `A tarefa do item ${dialogEditaItem.data.item} foi reaberta`,
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);

        setDialogEditaItem({
            data: {},
            open: false,
            readOnly: false
        })
    }

    const handleClickConfirmDialogEditaItem = async (event) => {
        const updateOrdemServico = { ...ordemServico };
        const updateItem = { ...dialogEditaItem.data };
        const index = updateOrdemServico.servicos.findIndex(x => x.item === updateItem.item);

        if (updateItem.nome) {
            if (!updateItem.novo) {
                updateOrdemServico.servicos[index] = updateItem;

                updateOrdemServico.historico.push({
                    data: moment().toISOString(),
                    descricao: `Item ${updateItem.item} alterado`,
                    responsavel: user?.nome
                })
            } else {
                if (updateItem.adicionais.length <= 0) {

                    updateOrdemServico.historico.push({
                        data: moment().toISOString(),
                        descricao: `Item ${updateItem.item} adicionado |(GRUPO)`,
                        responsavel: user?.nome
                    })
                    updateOrdemServico.servicos.push(updateItem);
                } else {
                    const grupo = updateItem.nome;

                    updateItem.grupo = grupo;
                    updateItem.nome = <b>{updateItem.nome}</b>
                    updateItem.item = null;
                    updateItem.responsavel = null;
                    updateItem.responsavelItem = null;
                    updateItem.data = null;
                    updateItem.prazo = null;
                    updateItem.quantidade = 0;
                    updateItem.valor = 0;
                    updateItem.total = 0;
                    updateItem.status = null;

                    updateOrdemServico.servicos.push(updateItem);
                    for (let nX = 0; nX < updateItem.adicionais.length; nX++) {
                        const adicional = updateItem.adicionais[nX];

                        await Servicos.retrieve(adicional).then(servico => {

                            let prazo = moment();

                            switch (updateOrdemServico.prioridade) {
                                case "Alta": prazo = moment(prazo).add(servico.alta, "days"); break;
                                case "Média": prazo = moment(prazo).add(servico.media, "days"); break;
                                case "Baixa": prazo = moment(prazo).add(servico.baixa, "days"); break;
                                default: break;
                            }
                            updateOrdemServico.historico.push({
                                data: moment().toISOString(),
                                descricao: `Item ${updateOrdemServico.servicos.length} adicionado`,
                                responsavel: user?.nome
                            })

                            updateOrdemServico.servicos.push({
                                novo: true,
                                id: servico._id,
                                item: updateOrdemServico.servicos.length,
                                nome: servico.nome,
                                responsavel: updateItem.responsavel,
                                responsavelItem: updateItem.responsavelItem,
                                data: new Date().toISOString(),
                                prazo: moment(prazo).toISOString(),
                                quantidade: 1,
                                valor: servico.valor,
                                total: servico.valor,
                                status: "Pendente",
                                grupo: grupo
                            })
                        }).catch(error => {
                            console.log(error);
                        })
                    }
                }
            }

            updateOrdemServico.valorTotal = 0;

            for (let nX = 0; nX < updateOrdemServico.servicos.length; nX++) {
                const servico = updateOrdemServico.servicos[nX];
                updateOrdemServico.valorTotal += servico.total;
            }

            setOrdemServico(updateOrdemServico);
            setDialogEditaItem({
                data: {},
                open: false
            })
        }
    }

    const handleConcluirTarefa = (event) => {
        const updateOrdemServico = { ...ordemServico };
        const index = updateOrdemServico.servicos.findIndex(x => x.item === dialogConcluir.data.item);

        updateOrdemServico.servicos[index].status = 'Concluído';
        updateOrdemServico.servicos[index].conclusao = moment().toISOString();
        updateOrdemServico.servicos[index].filesToUpload = dialogConcluir.data.filesToUpload;
        if (index < ordemServico.servicos.length) {
            ordemServico.servicos[index].enviaEmail = true;
            ordemServico.servicos[index].reabretask = false;
        }

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: `A tarefa do item ${dialogConcluir.data.item} foi concluída`,
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
        setDialogConcluir({
            data: {},
            open: false
        })
    }

    const handleUploadFileConcluirTarefa = (newFiles) => {
        const updateConcluir = { ...dialogConcluir };
        updateConcluir.data.filesToUpload = updateConcluir.data.filesToUpload ? updateConcluir.data.filesToUpload.concat(newFiles) : newFiles;
        setDialogConcluir(updateConcluir);
    }

    const handleDeleteFileConcluirTarefa = (file) => {
        const updateConcluir = { ...dialogConcluir };
        updateConcluir.data.filesToUpload.splice(updateConcluir.data.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setDialogConcluir(updateConcluir);
    }

    const handleChangeFieldReceita = (event) => {
        const field = event.target.name;
        const updateReceita = { ...dialogEditaReceita };
        updateReceita.data[field] = event.target.value;
        setDialogEditaReceita(updateReceita);
    }

    const handleChangeDateReceita = (date, field) => {
        const updateReceita = { ...dialogEditaReceita };
        updateReceita.data[field] = date.toISOString();
        setDialogEditaReceita(updateReceita);
    }

    const handleClickConfirmDialogEditaReceita = (event) => {
        const updateOrdemServico = { ...ordemServico };
        const updateReceitas = [...receitas];
        const updateReceita = { ...dialogEditaReceita.data };
        const index = updateReceitas.findIndex(x => x._id === updateReceita._id);

        updateReceita.tipoConta = 'Receber';
        updateReceita.saldo = updateReceita.valor;
        updateReceita.desconto = 0;
        updateReceita.juros = 0;
        updateReceita.multa = 0;
        updateReceita.ordemServico = ordemServico._id;
        updateReceita.ordemServicoNumero = ordemServico.numero;
        updateReceita.vencimento_real = updateReceita.vencimento;
        updateReceita.emissao = moment().toISOString();
        updateReceita.pessoa = ordemServico.solicitante;

        if (index >= 0) {
            updateReceitas[index] = updateReceita;
        } else {
            updateReceitas.push(updateReceita);
        }
        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: 'Receita ' + (index >= 0 ? 'alterada' : 'adicionada'),
            responsavel: user?.nome
        })

        if (updateReceita.compensacao) {
            const updateDespesas = [...despesas];
            for (let index = 0; index < despesasSelecionadas.length; index++) {
                const despesa = despesasSelecionadas[index];
                const idx = updateDespesas.findIndex(x => x._id === despesa.id);

                if (idx >= 0) {
                    updateDespesas[idx].saldo = 0;
                    despesasSelecionadas[index].quitado = true;
                }
            }

            setDespesas(updateDespesas);
        }

        setOrdemServico(updateOrdemServico);
        setReceitas(updateReceitas);
        setDialogEditaReceita({
            data: {},
            open: false
        })
    }

    const handleClickDeleteReceita = (event, receita) => {
        const updateOrdemServico = { ...ordemServico };
        const updateReceitas = [...receitas];
        const index = updateReceitas.findIndex(x => x._id === receita._id);
        updateReceitas[index].deleted = true;

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: 'Receita excluída',
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
        setReceitas(updateReceitas);
    }

    const handleChangeFieldDespesa = (event) => {
        const field = event.target.name;
        const updateDespesa = { ...dialogEditaDespesa };
        updateDespesa.data[field] = event.target.value;
        setDialogEditaDespesa(updateDespesa);
    }

    const handleChangeDateDespesa = (date, field) => {
        const updateDespesa = { ...dialogEditaDespesa };
        updateDespesa.data[field] = date.toISOString();
        setDialogEditaDespesa(updateDespesa);
    }

    const handleClickConfirmDialogEditaDespesa = (event) => {
        const updateOrdemServico = { ...ordemServico };
        const updateDespesas = [...despesas];
        const updateDespesa = { ...dialogEditaDespesa.data };
        const index = updateDespesas.findIndex(x => x._id === updateDespesa._id);

        updateDespesa.tipoConta = 'Pagar';
        updateDespesa.saldo = updateDespesa.valor;
        updateDespesa.desconto = 0;
        updateDespesa.juros = 0;
        updateDespesa.multa = 0;
        updateDespesa.ordemServico = ordemServico._id;
        updateDespesa.ordemServicoNumero = ordemServico.numero;
        updateDespesa.vencimento_real = updateDespesa.vencimento;
        updateDespesa.emissao = moment().toISOString();
        updateDespesa.pessoa = ordemServico.solicitante;

        if (index >= 0) {
            updateDespesas[index] = updateDespesa;
        } else {
            updateDespesas.push(updateDespesa);
        }

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: 'Despesa ' + (index >= 0 ? 'alterada' : 'adicionada'),
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
        setDespesas(updateDespesas);
        setDialogEditaDespesa({
            data: {},
            open: false
        })
    }

    const handleClickDeleteDespesa = (event, despesa) => {
        const updateOrdemServico = { ...ordemServico };
        const updateDespesas = [...despesas];
        const index = updateDespesas.findIndex(x => x._id === despesa._id);
        updateDespesas[index].deleted = true;

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: 'Despesa excluída',
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
        setDespesas(updateDespesas);
    }

    const handleChangeFieldProtocolo = (event) => {
        const field = event.target.name;
        const updateProtocolo = { ...dialogEditaProtocolo };
        updateProtocolo.data[field] = event.target.value;
        setDialogEditaProtocolo(updateProtocolo);
    }

    const handleChangeDateProtocolo = (date, field) => {
        const updateProtocolo = { ...dialogEditaProtocolo };
        updateProtocolo.data[field] = date.toISOString();

        if (field === 'dataProtocolo') {
            updateProtocolo.data.dataPrazo = date.add(30, "days").toISOString();
        }
        setDialogEditaProtocolo(updateProtocolo);
    }

    const handleChangeInstituicaoProtocolo = (event, instituicao) => {
        if (instituicao) {
            const updateProtocolo = { ...dialogEditaProtocolo };
            updateProtocolo.data.instituicao = instituicao._id;
            setDialogEditaProtocolo(updateProtocolo);
        }
    }

    const handleChangeResponsavelProtocolo = (event, responsavel) => {
        if (responsavel) {
            const updateProtocolo = { ...dialogEditaProtocolo };
            updateProtocolo.data.responsavel = responsavel._id;
            setDialogEditaProtocolo(updateProtocolo);
        }
    }

    const handleUploadFileProtocolo = (newFiles) => {
        const updateProtocolo = { ...dialogEditaProtocolo };
        updateProtocolo.data.filesToUpload = updateProtocolo.data.filesToUpload ? updateProtocolo.data.filesToUpload.concat(newFiles) : newFiles;
        setDialogEditaProtocolo(updateProtocolo);
    }

    const handleDeleteFileProtocolo = (file) => {
        const updateProtocolo = { ...dialogEditaProtocolo };
        updateProtocolo.data.filesToUpload.splice(updateProtocolo.data.filesToUpload.findIndex(x => x.file.name === file.file.name), 1);
        setDialogEditaProtocolo(updateProtocolo);
    }

    const handleClickDeleteFileProtocolo = (event, index) => {
        const updateProtocolo = { ...dialogEditaProtocolo };
        updateProtocolo.data.anexos.splice(index, 1);
        setDialogEditaProtocolo(updateProtocolo);
    }

    const handleClickConfirmDialogEditaProtocolo = (event) => {

        const updateOrdemServico = { ...ordemServico };
        const updateProtocolos = Array.isArray(protocolos) ? [...protocolos] : [];
        const updateProtocolo = { ...dialogEditaProtocolo.data };
        const index = updateProtocolos.findIndex(x => x._id === updateProtocolo._id);

        updateProtocolo.dataSolicitacao = moment().toISOString();
        updateProtocolo.cliente = ordemServico.solicitante;
        updateProtocolo.ordemServico = ordemServico._id;
        updateProtocolo.situacao = 'Aberto';

        if (index >= 0) {
            updateProtocolos[index] = updateProtocolo;
        } else {
            updateProtocolos.push(updateProtocolo);
        }

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: 'Protocolo ' + (index >= 0 ? 'alterado' : 'adicionado'),
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
        setProtocolos(updateProtocolos);
        setDialogEditaProtocolo({
            data: {},
            open: false
        })
    }

    const handleClickDeleteProtocolo = (event, protocolo) => {
        const updateOrdemServico = { ...ordemServico }
        const updateProtocolos = [...protocolos];
        const index = updateProtocolos.findIndex(x => x._id === protocolo._id);
        updateProtocolos[index].deleted = true;

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: 'Protocolo excluído',
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
        setProtocolos(updateProtocolos);
    }

    const handleChangeDate = (date, field) => {
        const updateOrdemServico = { ...ordemServico };
        updateOrdemServico[field] = date?.toISOString();
        setOrdemServico(updateOrdemServico);
    }

    const handleChangeCondicaoPagamento = (event, condicao) => {
        if (condicao) {
            const updateOrdemServico = { ...ordemServico };
            updateOrdemServico.condicaoPagamento = condicao._id;
            setOrdemServico(updateOrdemServico);
        }
    }

    const handleChangePessoa = (event, pessoa) => {
        if (pessoa) {
            const updateOrdemServico = { ...ordemServico };
            updateOrdemServico.solicitante = pessoa._id;
            setOrdemServico(updateOrdemServico);
        }
    }

    const handleChangeUsuario = (event, usuario) => {
        if (usuario) {
            const updateOrdemServico = { ...ordemServico };
            updateOrdemServico.responsavel = usuario._id;
            setOrdemServico(updateOrdemServico);
        }
    }

    const handleClickDelete = (event) => {
        setRemove({
            open: true,
            name: ordemServico.nome,
            id: ordemServico._id
        })
    }

    const handleClickDeleteItem = (event, index) => {
        const updateOrdemServico = { ...ordemServico };

        if (updateOrdemServico.servicos[index].item) {
            updateOrdemServico.servicos.splice(index, 1);

            updateOrdemServico.historico.push({
                data: moment().toISOString(),
                descricao: `Item ${index + 1} excluído`,
                responsavel: user?.nome
            })
        } else {
            const servicos = [];

            for (let nX = 0; nX < updateOrdemServico.servicos.length; nX++) {
                const servico = updateOrdemServico.servicos[nX];

                if (servico.grupo !== updateOrdemServico.servicos[index].grupo) {
                    servicos.push(servico);
                }
            }

            updateOrdemServico.servicos = servicos;
        }

        setOrdemServico(updateOrdemServico);
    }

    const handleClickDeleteFile = (event, servico, anexo) => {
        const updateOrdemServico = { ...ordemServico };
        updateOrdemServico.servicos[servico].anexos.splice(anexo, 1);
        setOrdemServico(updateOrdemServico);
    }

    const handleDeleteOrdemServico = (event) => {
        OrdensServico.remove(remove.id).then(response => {
            setRemove({
                open: false,
                name: '',
                id: ''
            });

            window.location.href = '/os';
        }).catch(error => {
            console.log(error);
        });
    }

    const handleChangeImovel = (event, imovel) => {
        if (imovel) {
            const updateOrdemServico = { ...ordemServico };

            updateOrdemServico.imoveis.push(imovel._id);
            updateOrdemServico.imovel.push({
                id: imovel._id,
                matricula: imovel.matricula,
                descricao: imovel.descricao,
                tipo: imovel.tipo
            })

            updateOrdemServico.historico.push({
                data: moment().toISOString(),
                descricao: `Imóvel ${imovel.descricao} adicionado`,
                responsavel: user?.nome
            })

            setOrdemServico(updateOrdemServico);
        }
    }

    const handleClickDeleteImovel = (event, index) => {
        const updateOrdemServico = { ...ordemServico };
        updateOrdemServico.imoveis.splice(index, 1);
        updateOrdemServico.imovel.splice(index, 1);

        updateOrdemServico.historico.push({
            data: moment().toISOString(),
            descricao: `Imóvel excluído`,
            responsavel: user?.nome
        })

        setOrdemServico(updateOrdemServico);
    }

    const handleClickConfirmDialogBaixa = (event) => {
        setLoading(true);

        ContasReceber.retrieve(dialogBaixa.data.titulo).then((response) => {
            let updateBaixa = { ...dialogBaixa.data };
            let updateReceber = { ...response };
            delete updateBaixa.titulo;

            updateReceber.baixas.push(updateBaixa);
            updateReceber.saldo -= updateBaixa.valor_baixa;

            ContasReceber.update(updateReceber._id, updateReceber).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                OrdensServico.retrieve(id).then((response) => {
                    setOrdemServico(response);

                    ContasReceber.list(0, 10000, "&ordemServico=" + id).then((response) => {
                        setReceitas(response.docs);
                    }).catch((error) => {
                        console.log(error);
                    })

                    ContasPagar.list(0, 10000, "&ordemServico=" + id).then((response) => {
                        setDespesas(response.docs);
                    }).catch((error) => {
                        console.log(error);
                    })

                    Protocolos.list(0, 10000, "&ordemServico=" + id).then((response) => {
                        setProtocolos(response.docs);
                    }).catch((error) => {
                        console.log(error);
                    })

                    setLoading(false);
                }).catch((error) => {
                    setResponseStatus({
                        open: true,
                        severity: "error",
                        message: (error.message ? error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                    });

                    setLoading(false);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });

                setLoading(false);
            });
        }).catch((error) => {
            console.log(error);
        });

        setDialogBaixa({
            data: {},
            open: false
        })
    }

    const handleChangeFieldBaixa = (event) => {
        let field = event.target.name;
        let updateBaixa = { ...dialogBaixa };
        updateBaixa.data[field] = event.target.value;
        setDialogBaixa(updateBaixa);
    }

    const handleChangeDateBaixa = (date, field) => {
        let updateBaixa = { ...dialogBaixa };
        updateBaixa.data[field] = date.toISOString();
        setDialogBaixa(updateBaixa);
    }

    const transformarOS = (ordemServico) => {
        const servicos = [];
        let mensagens = [];

        for (let index = 0; index < ordemServico.servicos.length; index++) {
            mensagens = mensagens.concat(ordemServico.servicos[index].mensagens)
        }

        const grupos = ordemServico.servicos.groupBy(x => x.grupo);

        for (let index = 0; index < grupos.length; index++) {
            const grupo = grupos[index];

            if (typeof (grupo.key) !== "undefined") {
                servicos.push({
                    id: grupo.key,
                    nome: <b>{grupo.key}</b>,
                    grupo: grupo.key,
                    quantidade: 0,
                    valor: 0,
                    total: ordemServico.servicos.filter(x => x.grupo === grupo.key).sum(x => x.total)
                })
            }

            for (let nX = 0; nX < grupo.length; nX++) {
                const servico = grupo[nX];

                servicos.push(servico);
            }
        }

        ordemServico.servicos = servicos;
        setOrdemServico(ordemServico);
        setMensagens(mensagens);
    }

    const handleChangeParam = (event) => {
        const updateParametros = { ...dialogParametrosOrcamento };
        updateParametros.data[event.target.name] = event.target.value;
        setDialogParametrosOrcamento(updateParametros);
    }
    const handleKeyMessagemProtocolo = (event) => {
        if (event.keyCode === 13 && event.target.value !== "") {
            let updateItem = { ...dialogEditaProtocolo };

            if (!updateItem.data.mensagens || !Array.isArray(updateItem.data.mensagens)) {
                updateItem.data.mensagens = [];
            }

            updateItem.data.mensagens.push({
                data: moment().toISOString(),
                mensagem: event.target.value,
                responsavel: user?.nome
            });

            setProtocolos(updateItem);

            document.getElementById("mensagens").value = '';
        }
    };


    const handleDeleteMensagemProtocolo = (event, index) => {
        const updateItem = { ...dialogEditaProtocolo };
        updateItem.data.mensagens.splice(index, 1);
        setProtocolos(updateItem);
    }

    const handleImprimeOrcamento = (event) => {
        setDialogParametrosOrcamento({
            ...dialogParametrosOrcamento,
            open: false
        })

        setDialogImprimeOrcamento({
            data: {
                ...ordemServico,
                financeiro: receitas.concat(despesas).sort((a, b) => {
                    if (a.vencimento < b.vencimento) {
                        return -1;
                    }
                    if (a.vencimento > b.vencimento) {
                        return 1;
                    }
                    return 0;
                }),

            },
            open: true
        })
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar ordem de serviço");
        if (id !== 'new') {
            OrdensServico.retrieve(id).then((response) => {
                transformarOS(response);

                ContasReceber.list(0, 10000, "&ordemServico=" + id).then((response) => {
                    setReceitas(response.docs);
                }).catch((error) => {
                    console.log(error);
                })

                ContasPagar.list(0, 10000, "&ordemServico=" + id).then((response) => {
                    setDespesas(response.docs);
                }).catch((error) => {
                    console.log(error);
                })

                Protocolos.list(0, 10000, "&ordemServico=" + id).then((response) => {
                    setProtocolos(response.docs);
                }).catch((error) => {
                    console.log(error);
                })

                setLoading(false);
            }).catch((error) => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });

                setLoading(false);
            });

        } else {
            setOrdemServico({
                situacao: 'Orçamento',
                prioridade: 'Média',
                servicos: [],
                imovel: [],
                imoveis: [],
                historico: [],
                data: moment().toISOString()
            })
            setLoading(false);
        }

    }, [id]);

    const menuActions = [
        {
            icon: <SaveIcon />,
            title: 'Salvar',
            type: 'submit',
            disabled: (!user?.permissoes["Ordem-de-Serviço-alterar"] & !user?.permissoes["Ordem-de-Serviço-incluir"] && id !== 'new')
        },
        {
            icon: <DeleteIcon onClick={handleClickDelete} />,
            title: 'Excluir',
            type: 'button',
            disabled: (!user?.permissoes["Ordem-de-Serviço-alterar"] & !user?.permissoes["Ordem-de-Serviço-incluir"] && id !== 'new')
        },
        {
            icon: <DescriptionIcon onClick={() => {
                setDialogParametrosOrcamento({
                    ...dialogParametrosOrcamento,
                    open: true
                })
            }} />,
            title: 'Imprime Orçamento',
            type: 'button'
        },
        {
            icon: <PrintIcon onClick={() => {
                setDialogImprimeFichaServico({
                    data: ordemServico,
                    index: -1,
                    open: true
                })
            }} />,
            title: 'Imprime Ficha de Serviço',
            type: 'button',
            disabled: (ordemServico.situacao !== 'Confirmado')
        }
    ]

    const columnsItens = [
        {
            id: 'item',
            label: 'Item',
            align: 'center'
        },
        {
            id: 'nome',
            label: 'Serviço',
            align: 'left',
            type: 'hybrid',
            format: (doc) => (
                <>
                    {doc.nome} <br />
                    <small style={{ color: 'grey' }}>{doc.etiqueta}</small>
                </>
            )
        },
        {
            id: 'data',
            label: 'Data',
            align: 'center',
            format: (value) => (value &&
                <>
                    {new Date(value).toLocaleDateString()}
                    <br />
                    {new Date(value).toLocaleTimeString()}
                </>
            )
        },
        {
            id: 'responsavelItem',
            label: 'Responsável',
            align: 'left',
            format: (responsavel) => responsavel ? responsavel.nome : ''
        },
        {
            id: 'prazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => value && (new Date(value).toLocaleDateString())
        },
        {
            id: 'conclusao',
            label: 'Conclusão',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (
                doc.conclusao ?
                    <>
                        {new Date(doc.conclusao).toLocaleDateString()}
                        <br />
                        {new Date(doc.conclusao).toLocaleTimeString()}
                    </>
                    : (user?._id === doc.responsavel ?
                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                color: 'white',
                                backgroundColor: green[700],
                                fontSize: '0.7rem'
                            }}
                            onClick={() => setDialogConcluir({
                                data: {
                                    ...doc,
                                    filesToUpload: []
                                },
                                open: true
                            })}
                        >
                            Concluir
                        </Button>
                        : ''
                    )
            )
        },
        {
            id: 'quantidade',
            label: 'Quant',
            align: 'right',
            type: 'hybrid',
            format: (item) => item.id !== item.grupo ?
                <NumberFormat
                    value={item.quantidade}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
                : ''
        },
        {
            id: 'valor',
            label: 'Vlr Unit',
            align: 'right',
            type: 'hybrid',
            format: (item) => item.id !== item.grupo ?
                <NumberField
                    value={item.valor}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                    responsavelOS={ordemServico.responsavel?._id}
                    responsavel={item.responsavel}
                />
                : ''
        },
        {
            id: 'total',
            label: 'Total',
            align: 'right',
            type: 'hybrid',
            format: (item) => item.id !== item.grupo ?
                <NumberField
                    value={item.total}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                    responsavelOS={ordemServico.responsavel?._id}
                    responsavel={item.responsavel}
                />
                :
                <>
                    <b>
                        <NumberField
                            value={item.total}
                            displayType="text"
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={2}
                            fixedDecimalScale={2}
                            responsavelOS={ordemServico.responsavel?._id}
                            responsavel={item.responsavel}
                        />
                    </b>
                </>
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (doc, index) => (
                <div key={index} style={{ width: '80px' }}>
                    {doc.item &&
                        <IconButton
                            aria-label={(!!doc.conclusao || !!!user?.permissoes["Ordem-de-Serviço-alterar"]) ? 'Ver' : 'Editar'}
                            title={(!!doc.conclusao || !!!user?.permissoes["Ordem-de-Serviço-alterar"]) ? 'Ver' : 'Editar'}
                            color="primary"
                            size="small"
                            onClick={() => setDialogEditaItem({
                                data: {
                                    ...doc,
                                    novo: false
                                },
                                open: true,
                                readOnly: !!doc.conclusao
                            })}
                        >
                            {!!doc.conclusao ?
                                <VisibilityIcon fontSize="small" />
                                :
                                <EditIcon fontSize="small" />
                            }
                        </IconButton>
                    }
                    {user?.permissoes["Ordem-de-Serviço-excluir"] === true &&
                        <IconButton
                            aria-label="Excluir"
                            title="Excluir"
                            color="secondary"
                            size="small"
                            onClick={e => handleClickDeleteItem(e, index)}
                            disabled={!!doc.conclusao}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    }
                </div>
            )
        }
    ]

    const columnsImoveis = [
        {
            id: 'matricula',
            label: 'Matrícula',
            align: 'center'
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'tipo',
            label: 'Tipo',
            align: 'center'
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            format: (value, index) => (
                <>
                    <IconButton
                        aria-label="Imprime Ficha de Serviço"
                        title="Imprime Ficha de Serviço"
                        color="primary"
                        size="small"
                        onClick={() => {
                            setDialogImprimeFichaServico({
                                data: ordemServico,
                                index: index,
                                open: true
                            })
                        }}
                    >
                        <PrintIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="Excluir"
                        title="Excluir"
                        color="secondary"
                        size="small"
                        onClick={e => handleClickDeleteImovel(e, index)}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            )
        }
    ]

    const columnsReceitas = [
        {
            id: 'vencimento',
            label: 'Vencimento',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'metodoPagamento',
            label: 'Forma Pagamento',
            align: 'center'
        },
        {
            id: 'valor',
            label: 'Valor',
            align: 'right',
            format: (value) => (
                <NumberField
                    value={value}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'pago',
            label: 'Pago',
            align: 'right',
            type: 'hybrid',
            format: (receita) => (
                <NumberField
                    value={receita.valor - receita.saldo}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'saldo',
            label: 'Saldo',
            align: 'right',
            format: (saldo) => (
                <NumberField
                    value={saldo}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (receita, index) => (
                <Fragment key={index}>
                    <IconButton
                        aria-label="Recibo"
                        title="Recibo"
                        color="primary"
                        size="small"
                        onClick={() => setDialogImprimeRecibo({
                            data: {
                                cliente: ordemServico.cliente,
                                ...receita
                            },
                            open: true
                        })}
                        disabled={receita.valor === receita.saldo}
                    >
                        <PrintIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        title="Baixar"
                        aria-label="Baixar"
                        onClick={() => setDialogBaixa({
                            data: {
                                titulo: receita._id,
                                data_baixa: moment().toISOString(),
                                historico: 'Baixa de conta a receber',
                                valor_baixa: receita.saldo,
                                desconto: 0,
                                multa: 0,
                                juros: 0,
                                saldo: receita.saldo
                            },
                            open: true
                        })}
                        disabled={receita.saldo <= 0}
                        size="small"
                    >
                        <ArchiveIcon color="primary" fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="Editar"
                        title="Editar"
                        color="primary"
                        size="small"
                        onClick={() => setDialogEditaReceita({
                            data: {
                                ...receita
                            },
                            open: true
                        })}
                        disabled={receita.saldo === 0}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="Excluir"
                        title="Excluir"
                        color="secondary"
                        size="small"
                        onClick={e => handleClickDeleteReceita(e, receita)}
                        disabled={receita.saldo === 0}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            )
        }
    ]

    const selecionarTodasDespesas = (event) => {
        let selecionaDespesas = [...despesasSelecionadas];

        if (event.target.checked) {
            for (let index = 0; index < despesas.length; index++) {
                const despesa = despesas[index];

                if (despesa.saldo > 0) {
                    if (!selecionaDespesas.find(x => x.id === despesa._id)) {
                        selecionaDespesas.push({
                            id: despesa._id,
                            saldo: despesa.saldo,
                            quitado: false
                        });
                    }
                }
            }
        } else {
            selecionaDespesas = [];
        }

        setDespesasSelecionadas(selecionaDespesas);
    }

    const selecionaDespesa = (event) => {
        const selecionaDespesas = [...despesasSelecionadas];
        const despesa = JSON.parse(event.target.value);
        if (event.target.checked) {
            if (!selecionaDespesas.find(x => x.id === despesa.id)) {
                selecionaDespesas.push({
                    id: despesa.id,
                    saldo: despesa.saldo,
                    quitado: false
                });
            }
        } else {
            selecionaDespesas.splice(selecionaDespesas.findIndex(x => x.id === despesa.id), 1);
        }

        setDespesasSelecionadas(selecionaDespesas);
    }

    const handleQuitarDespesas = (event) => {
        setDialogEditaReceita({
            data: {
                descricao: 'Pagamento de despesas',
                valor: despesasSelecionadas.map(({ saldo }) => saldo).reduce((sum, i) => sum + i, 0),
                vencimento: moment().toISOString(),
                vencimento_real: moment().toISOString(),
                metodoPagamento: 'Boleto',
                novo: true,
                compensacao: true
            },
            open: true
        })

        setDialogQuitar({ open: false });
    }

    const handleClickConcluir = (event, id, name) => {
        setConclui({
            open: true,
            name: name,
            id: id
        })
    }

    const handleConcluiProtocolo = (event) => {
        Protocolos.update(conclui.id, {
            dataConclusao: new Date().toISOString(),
            situacao: 'Concluído'
        }).then(response => {
            setConclui({
                open: false,
                name: '',
                id: ''
            });

            window.location.reload();
        });
    }

    const columnsDespesas = [
        {
            id: 'selecionar',
            label: <Checkbox color="primary" size="small" onClick={selecionarTodasDespesas} />,
            align: 'center',
            type: 'hybrid',
            format: (despesa) => (despesa.saldo > 0 && <Checkbox
                color="primary"
                size="small"
                value={JSON.stringify({
                    id: despesa._id,
                    saldo: despesa.saldo
                })}
                onClick={selecionaDespesa}
                checked={despesasSelecionadas.find(x => x.id === despesa._id) ? true : false}
            />)
        },
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'vencimento',
            label: 'Vencimento',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'descricao',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'metodoPagamento',
            label: 'Forma Pagamento',
            align: 'center'
        },
        {
            id: 'valor',
            label: 'Valor',
            align: 'right',
            format: (value) => (
                <NumberField
                    value={value}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'pago',
            label: 'Pago',
            align: 'right',
            type: 'hybrid',
            format: (despesa) => (
                <NumberField
                    value={despesa.valor - despesa.saldo}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'saldo',
            label: 'Saldo',
            align: 'right',
            format: (saldo) => (
                <NumberField
                    value={saldo}
                    displayType="text"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={2}
                />
            )
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (despesa, index) => (
                <Fragment key={index}>
                    <IconButton
                        aria-label="Editar"
                        color="primary"
                        size="small"
                        onClick={() => setDialogEditaDespesa({
                            data: {
                                ...despesa
                            },
                            open: true
                        })}
                        disabled={despesa.saldo === 0}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="Excluir"
                        color="secondary"
                        size="small"
                        onClick={e => handleClickDeleteDespesa(e, despesa)}
                        disabled={despesa.saldo === 0}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            )
        }
    ]

    const columnsProtocolos = [
        {
            id: 'numero',
            label: 'Número',
            align: 'center'
        },
        {
            id: 'dataProtocolo',
            label: 'Data',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'resumo',
            label: 'Descrição',
            align: 'left'
        },
        {
            id: 'dataPrazo',
            label: 'Prazo',
            align: 'center',
            format: (value) => moment(value).format("DD/MM/YYYY")
        },
        {
            id: 'dataConclusao',
            label: 'Conclusão',
            align: 'center',
            type: 'hybrid',
            format: (doc) => (doc.dataConclusao ?
                new Date(doc.dataConclusao).toLocaleDateString('pt-BR')
                :
                <Button
                    size="small"
                    variant="contained"
                    onClick={e => handleClickConcluir(e, doc._id, doc.resumo)}
                    style={{ color: 'white', backgroundColor: green[700] }}
                    disabled={!user?.permissoes["Protocolos-alterar"]}
                >
                    Concluir
                </Button>
            )
        },
        {
            id: 'id',
            label: 'Ações',
            align: 'center',
            type: 'hybrid',
            format: (protocolo, index) => (
                <Fragment key={index}>
                    <IconButton
                        aria-label="Editar"
                        color="primary"
                        size="small"
                        onClick={() => setDialogEditaProtocolo({
                            data: {
                                ...protocolo
                            },
                            open: true
                        })}
                        disabled={!!protocolo.dataConclusao}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        aria-label="Excluir"
                        color="secondary"
                        size="small"
                        onClick={e => handleClickDeleteProtocolo(e, protocolo)}
                        disabled={!!protocolo.dataConclusao}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </Fragment>
            )
        }
    ]

    return (!loading ?
        <Container>
            <Paper square>
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar ordem serviço"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Descrição" {...a11yProps(0)} />
                        <Tab label="Serviços" {...a11yProps(1)} />
                        <Tab label="Imóveis" {...a11yProps(2)} />
                        <Tab label="Protocolos" {...a11yProps(3)} disabled={!user?.permissoes["Protocolos-visualizar"]} />
                        <Tab label="Receitas" {...a11yProps(4)} />
                        <Tab label="Despesas" {...a11yProps(5)} />
                        <Tab label="Anexos" {...a11yProps(6)} />
                        <Tab label="Mensagens" {...a11yProps(7)} />
                        <Tab label="Histórico" {...a11yProps(8)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="numero"
                                    label="Número OS"
                                    size="small"
                                    defaultValue={ordemServico.numero}
                                    inputRef={register()}
                                    fullWidth
                                    onChange={handleChangeField}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="situacao-label">Situação</InputLabel>
                                    <Select
                                        name="situacao"
                                        labelid="situacao-label"
                                        size="small"
                                        value={ordemServico.situacao}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="Orçamento" value="Orçamento">Orçamento</MenuItem>
                                        <MenuItem key="Confirmado" value="Confirmado">Confirmado</MenuItem>
                                        <MenuItem key="Concluído" value="Concluído">Concluído</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateField
                                    name="data"
                                    label="Data Solicitação"
                                    value={ordemServico.data}
                                    onChange={handleChangeDate}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.data}
                                    helperText={errors.data?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateField
                                    name="prazo"
                                    label="Prazo"
                                    value={ordemServico.prazo}
                                    onChange={handleChangeDate}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.prazo}
                                    helperText={errors.prazo?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="prioridade-label">Prioridade</InputLabel>
                                    <Select
                                        name="prioridade"
                                        labelid="prioridade-label"
                                        size="small"
                                        value={ordemServico.prioridade}
                                        onChange={handleChangeField}
                                        fullWidth
                                    >
                                        <MenuItem key="Alta" value="Alta">Alta</MenuItem>
                                        <MenuItem key="Média" value="Média">Média</MenuItem>
                                        <MenuItem key="Baixa" value="Baixa">Baixa</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CondicoesComboBox
                                    onChange={handleChangeCondicaoPagamento}
                                    defaultValue={ordemServico.condicao &&
                                    {
                                        _id: ordemServico.condicao._id,
                                        descricao: ordemServico.condicao.descricao
                                    }
                                    }
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.condicao}
                                    helperText={errors.condicao?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PessoasComboBox
                                    onChange={handleChangePessoa}
                                    defaultValue={ordemServico.cliente &&
                                    {
                                        _id: ordemServico.cliente._id,
                                        nome: ordemServico.cliente.nome
                                    }
                                    }
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.cliente}
                                    helperText={errors.cliente?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UsuariosComboBox
                                    onChange={handleChangeUsuario}
                                    defaultValue={ordemServico.responsavel &&
                                    {
                                        _id: ordemServico.responsavel._id,
                                        nome: ordemServico.responsavel.nome
                                    }
                                    }
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.responsavel}
                                    helperText={errors.responsavel?.message}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="descricao"
                                    label="Descrição da Ordem de Serviço"
                                    size="small"
                                    variant="outlined"
                                    defaultValue={ordemServico.descricao}
                                    fullWidth
                                    multiline
                                    rows={8}
                                    onChange={handleChangeField}
                                    inputRef={register({ required: "*Campo obrigatório" })}
                                    error={!!errors.descricao}
                                    helperText={errors.descricao?.message}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    {/* Serviços */}
                    <TabPanel value={tab} index={1}>
                        {(user?.permissoes["Ordem-de-Serviço-incluir"] === true || user?.permissoes["Ordem-de-Serviço-alterar"] === true) &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginBottom: '20px' }}
                                onClick={() => setDialogEditaItem({
                                    data: {
                                        novo: true,
                                        filesToUpload: []
                                    },
                                    open: true
                                })}
                            >
                                Adicionar serviço
                            </Button>
                        }
                        <DataTable
                            columns={columnsItens}
                            docs={ordemServico.servicos}
                            footer={
                                <TableRow>
                                    <TableCell colSpan={8} align="right" style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold'
                                    }}>
                                        Total
                                    </TableCell>
                                    <TableCell align="right" style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold'
                                    }}>
                                        <NumberField
                                            value={ordemServico.servicos.filter(x => x.id !== x.grupo).map(({ total }) => total).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            }
                        />
                    </TabPanel>
                    {/* Imóveis */}
                    <TabPanel value={tab} index={2}>
                        <ImoveisComboBox
                            onChange={handleChangeImovel}
                            style={{
                                paddingBottom: '20px'
                            }}
                        />
                        <DataTable
                            columns={columnsImoveis}
                            docs={ordemServico.imovel}
                        />
                    </TabPanel>
                    {/* Protocolos */}
                    <TabPanel value={tab} index={3}>
                        {user?.permissoes["Protocolos-incluir"] && (
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginBottom: '20px' }}
                                onClick={() => setDialogEditaProtocolo({
                                    data: {
                                        novo: true,
                                        dataSolicitacao: moment().toISOString(),
                                        dataProtocolo: moment().toISOString(),
                                        dataPrazo: moment().add(30, "days").toISOString()
                                    },
                                    open: true
                                })}
                            >
                                Adicionar protocolo
                            </Button>
                        )}
                        {user?.permissoes["Protocolos-visualizar"] && (
                            <DataTable
                                columns={columnsProtocolos}
                                docs={Array.isArray(protocolos) ? protocolos.filter(x => !x.deleted) : []}
                            />

                        )}
                    </TabPanel>
                    {/* Receitas */}
                    <TabPanel value={tab} index={4}>
                        {(user?.permissoes["Ordem-de-Serviço-incluir"] === true || user?.permissoes["Ordem-de-Serviço-alterar"] === true) &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginBottom: '20px' }}
                                onClick={() => setDialogEditaReceita({
                                    data: {
                                        vencimento: moment().toISOString(),
                                        vencimento_real: moment().toISOString(),
                                        metodoPagamento: 'Boleto',
                                        novo: true,
                                        valor: receitas.filter(x => !x.deleted).map(({ saldo }) => saldo).reduce((sum, i) => sum + i, 0)
                                    },
                                    open: true
                                })}
                            >
                                Adicionar receita
                            </Button>
                        }
                        <DataTable
                            columns={columnsReceitas}
                            docs={receitas.filter(x => !x.deleted)}
                            footer={
                                <TableRow>
                                    <TableCell colSpan={3} align="right" style={{ fontWeight: 'bold' }}>
                                        Total
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <NumberField
                                            value={receitas.filter(x => !x.deleted).map(({ valor }) => valor).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <NumberField
                                            value={receitas.filter(x => !x.deleted).map(({ valor, saldo }) => valor - saldo).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <NumberField
                                            value={receitas.filter(x => !x.deleted).map(({ saldo }) => saldo).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            }
                        />
                    </TabPanel>
                    {/* Despesas */}
                    <TabPanel value={tab} index={5}>
                        {(user?.permissoes["Ordem-de-Serviço-incluir"] === true || user?.permissoes["Ordem-de-Serviço-alterar"] === true) &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginBottom: '20px' }}
                                onClick={() => setDialogEditaDespesa({
                                    data: {
                                        vencimento: moment().toISOString(),
                                        vencimento_real: moment().toISOString(),
                                        metodoPagamento: 'Boleto',
                                        novo: true
                                    },
                                    open: true
                                })}
                            >
                                Adicionar despesa
                            </Button>
                        }
                        {despesasSelecionadas.length > 0 && despesasSelecionadas.filter(x => x.quitado === false).length > 0 &&
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginLeft: '10px', marginBottom: '20px', backgroundColor: green[700] }}
                                onClick={() => setDialogQuitar({ open: true })}
                            >
                                Quitar despesas
                            </Button>
                        }
                        <DataTable
                            columns={columnsDespesas}
                            docs={despesas.filter(x => !x.deleted)}
                            footer={
                                <TableRow>
                                    <TableCell colSpan={5} align="right" style={{ fontWeight: 'bold' }}>
                                        Total
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <NumberField
                                            value={despesas.filter(x => !x.deleted).map(({ valor }) => valor).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <NumberField
                                            value={despesas.filter(x => !x.deleted).map(({ valor, saldo }) => valor - saldo).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                        <NumberField
                                            value={despesas.filter(x => !x.deleted).map(({ saldo }) => saldo).reduce((sum, i) => sum + i, 0)}
                                            displayType="text"
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={2}
                                        />
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            }
                        />
                    </TabPanel>
                    {/* Anexos */}
                    <TabPanel value={tab} index={6}>
                        <List>
                            <ListItem key={"cabecalho-anexos"}>
                                <ListItemIcon style={{ maxWidth: '150px' }}>
                                    &nbsp;
                                </ListItemIcon>
                                <ListItemText align="center" style={{ maxWidth: '150px' }}>
                                    Item
                                </ListItemText>
                                <ListItemText align="left">
                                    Descrição
                                </ListItemText>
                                <ListItemIcon style={{ maxWidth: '150px' }}>
                                    &nbsp;
                                </ListItemIcon>
                            </ListItem>
                            <Divider />
                            {ordemServico.servicos && ordemServico.servicos.map((servico, servicoIndex) => (
                                servico.anexos && servico.anexos.map((anexo, index) =>
                                    <Fragment key={"anexo-" + index}>
                                        <ListItem key={index}>
                                            <ListItemIcon style={{ maxWidth: '150px' }}>
                                                <DescriptionOutlinedIcon />
                                            </ListItemIcon>
                                            <ListItemText align="center" style={{ maxWidth: '150px' }}>
                                                {servico.item}
                                            </ListItemText>
                                            <ListItemText align="left">
                                                <Link href={anexo.url} target="_blank">
                                                    {anexo.descricao ? anexo.descricao : anexo.name}
                                                </Link>
                                            </ListItemText>
                                            <ListItemIcon align="center" style={{ maxWidth: '150px' }}>
                                                <IconButton
                                                    onClick={e => handleClickDeleteFile(e, servicoIndex, index)}
                                                    disabled={!!servico.conclusao}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                )
                            ))}
                        </List>
                    </TabPanel>
                    {/* Mensagens */}
                    <TabPanel value={tab} index={7}>
                        <List>
                            {mensagens.length > 0 && mensagens.sort((a, b) => { return a.data > b.data ? -1 : 1 }).map((mensagem, index) =>
                                <Fragment key={"mensagem-" + index}>
                                    <ListItem key={index}>
                                        <ListItemText
                                            align="left"
                                            primary={mensagem.mensagem}
                                            secondary={mensagem.responsavel + ' (' + moment(mensagem.data).format("DD/MM/YYYY - HH:mm") + ')'}
                                        />
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            )}
                        </List>
                    </TabPanel>
                    {/* Histórico */}
                    <TabPanel value={tab} index={8}>
                        <List dense>
                            <ListItem key="cabecalho-historico">
                                <ListItemText align="left" style={{ maxWidth: '160px' }}>
                                    Data
                                </ListItemText>
                                <ListItemText align="left">
                                    Descrição
                                </ListItemText>
                                <ListItemText align="left" style={{ maxWidth: '200px' }}>
                                    Responsável
                                </ListItemText>
                            </ListItem>
                            <Divider />
                            {ordemServico.historico && ordemServico.historico.reverse().map((historico, index) => (
                                <Fragment key={"historico-" + index}>
                                    <ListItem key={index}>
                                        <ListItemText align="left" style={{ maxWidth: '160px' }}>
                                            {moment(historico.data).format("DD/MM/YYYY - HH:mm")}
                                        </ListItemText>
                                        <ListItemText align="left">
                                            {historico.descricao}
                                        </ListItemText>
                                        <ListItemText align="left" style={{ maxWidth: '200px' }}>
                                            {historico.responsavel}
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </TabPanel>
                    <ActionMenu actions={menuActions} />
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <ConfirmationDialog
                title="Tem certeza que seja excluir?"
                confirmTitle="Sim"
                confirm={handleDeleteOrdemServico}
                cancelTitle="Não"
                cancel={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
                open={remove.open}
                onClose={() => {
                    setRemove({
                        ...remove,
                        open: false
                    })
                }}
            >{remove.name}</ConfirmationDialog>
            <ConfirmationDialog
                title="Quitar despesas"
                confirmTitle="Sim"
                confirm={handleQuitarDespesas}
                cancelTitle="Não"
                cancel={() => {
                    setDialogQuitar({
                        open: false
                    })
                }}
                open={dialogQuitar.open}
                onClose={() => {
                    setDialogQuitar({
                        open: false
                    })
                }}
            >
                Esta operação irá quitar as despesas selecionadas e gerar uma receita em aberto. Deseja continuar?
            </ConfirmationDialog>
            <EditaItemOrdemServico
                readOnly={dialogEditaItem.readOnly}
                open={dialogEditaItem.open}
                onClose={() => setDialogEditaItem({
                    data: {},
                    open: false
                })}
                data={dialogEditaItem.data}
                reopenTask={handleReopenTask}
                confirm={handleClickConfirmDialogEditaItem}
                cancel={() => setDialogEditaItem({
                    data: {},
                    open: false
                })}
                handleChangeField={handleChangeItemField}
                handleChangeDateField={handleChangeDateItem}
                handleChangeUsuarioField={handleChangeUsuarioItem}
                handleChangeServicoField={handleChangeServicoItem}
                handleKeyUpMensagem={handleKeyUpMensagemItem}
                handleClickDeleteMensagem={handleClickDeleteMensagemItem}
                handleUploadFile={handleUploadFileItem}
                handleDeleteFile={handleDeleteFileItem}
                handleClickDeleteFile={handleClickDeleteFileItem}
                handleChangeAnexoVisibility={handleChangeAnexoVisibility}
                handleChangeMensagemVisibility={handleChangeMensagemVisibility}
            />
            <EditaReceita
                open={dialogEditaReceita.open}
                onClose={() => setDialogEditaReceita({
                    data: {},
                    open: false
                })}
                data={dialogEditaReceita.data}
                confirm={handleClickConfirmDialogEditaReceita}
                cancel={() => setDialogEditaReceita({
                    data: {},
                    open: false
                })}
                handleChangeField={handleChangeFieldReceita}
                handleChangeDateField={handleChangeDateReceita}
            />
            <EditaDespesa
                open={dialogEditaDespesa.open}
                onClose={() => setDialogEditaDespesa({
                    data: {},
                    open: false
                })}
                data={dialogEditaDespesa.data}
                confirm={handleClickConfirmDialogEditaDespesa}
                cancel={() => setDialogEditaDespesa({
                    data: {},
                    open: false
                })}
                handleChangeField={handleChangeFieldDespesa}
                handleChangeDateField={handleChangeDateDespesa}
            />
            <EditaProtocolo
                open={dialogEditaProtocolo.open}
                onClose={() => setDialogEditaProtocolo({
                    data: {},
                    open: false
                })}
                data={dialogEditaProtocolo.data}
                confirm={handleClickConfirmDialogEditaProtocolo}
                cancel={() => setDialogEditaProtocolo({
                    data: {},
                    open: false
                })}
                handleChangeField={handleChangeFieldProtocolo}
                handleChangeDateField={handleChangeDateProtocolo}
                handleChangeInstituicao={handleChangeInstituicaoProtocolo}
                handleChangeResponsavel={handleChangeResponsavelProtocolo}
                handleUploadFile={handleUploadFileProtocolo}
                handleDeleteFile={handleDeleteFileProtocolo}
                handleClickDeleteFile={handleClickDeleteFileProtocolo}
                handleKeymessagem={handleKeyMessagemProtocolo}
                handleDeleteMensagem={handleDeleteMensagemProtocolo}
            />
            <ConcluiTarefa
                open={dialogConcluir.open}
                onClose={() => setDialogConcluir({
                    data: {},
                    open: false
                })}
                data={dialogConcluir.data}
                confirm={handleConcluirTarefa}
                cancel={() => setDialogConcluir({
                    data: {},
                    open: false
                })}
                handleUploadFile={handleUploadFileConcluirTarefa}
                handleDeleteFile={handleDeleteFileConcluirTarefa}
            />
            <ImprimeOrcamento
                open={dialogImprimeOrcamento.open}
                onClose={() => setDialogImprimeOrcamento({
                    data: {},
                    open: false
                })}
                data={dialogImprimeOrcamento.data}
                params={dialogParametrosOrcamento.data}
                confirm={() => setDialogImprimeOrcamento({
                    data: {},
                    open: false
                })}
                cancel={() => setDialogImprimeOrcamento({
                    data: {},
                    open: false
                })}
            />
            <ParametrosOrcamento
                open={dialogParametrosOrcamento.open}
                onClose={() => setDialogParametrosOrcamento({
                    ...dialogParametrosOrcamento,
                    open: false
                })}
                data={dialogParametrosOrcamento.data}
                handleChangeField={handleChangeParam}
                confirm={handleImprimeOrcamento}
                cancel={() => setDialogParametrosOrcamento({
                    ...dialogParametrosOrcamento,
                    open: false
                })}
            />
            <ImprimeFichaServico
                open={dialogImprimeFichaServico.open}
                onClose={() => setDialogImprimeFichaServico({
                    data: {},
                    index: 0,
                    open: false
                })}
                data={dialogImprimeFichaServico.data}
                index={dialogImprimeFichaServico.index}
                confirm={() => setDialogImprimeFichaServico({
                    data: {},
                    index: 0,
                    open: false
                })}
                cancel={() => setDialogImprimeFichaServico({
                    data: {},
                    index: 0,
                    open: false
                })}
            />
            <ImprimeRecibo
                open={dialogImprimeRecibo.open}
                onClose={() => setDialogImprimeRecibo({
                    data: {},
                    open: false
                })}
                data={dialogImprimeRecibo.data}
                confirm={() => setDialogImprimeRecibo({
                    data: {},
                    open: false
                })}
                cancel={() => setDialogImprimeRecibo({
                    data: {},
                    open: false
                })}
            />
            <Baixa
                open={dialogBaixa.open}
                onClose={() => setDialogBaixa({
                    data: {},
                    open: false
                })}
                data={dialogBaixa.data}
                confirm={handleClickConfirmDialogBaixa}
                cancel={() => setDialogBaixa({
                    data: {},
                    open: false
                })}
                handleChangeField={handleChangeFieldBaixa}
                handleChangeDateField={handleChangeDateBaixa}
            />
            <ConfirmationDialog
                title="Deseja concluir o protocolo?"
                confirmTitle="Sim"
                confirm={handleConcluiProtocolo}
                cancelTitle="Não"
                cancel={() => {
                    setConclui({
                        ...conclui,
                        open: false
                    })
                }}
                open={conclui.open}
                onClose={() => {
                    setConclui({
                        ...remove,
                        open: false
                    })
                }}
            >{conclui.name}</ConfirmationDialog>
        </Container>
        :
        (loading && (
            <Loading open={loading} />
        ))
    )
};

export default EditaOrdemServico;
