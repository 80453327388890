import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';
import MuiListItemText from '@material-ui/core/ListItemText';

export const Container = styled.div`
`;

export const DatePicker = styled(KeyboardDatePicker)`
    margin-right: 10px !important;
    
    & .MuiInputBase-input {
        width: ${props => props.width +'px'} !important
    }
`;

export const ListItemText = styled(MuiListItemText)`
    & .MuiListItemText-primary {
        font-weight: bold;
        font-size: 1.96rem;
    }
`;