import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";
import {
    TextField,
    Grid,
    Snackbar,
    Switch,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Tabs,
    Tab,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Divider,
    Select,
    MenuItem,
    FormControl
} from "@material-ui/core";

import {
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Alert
} from "@material-ui/lab";

import { useForm } from "react-hook-form";

import SaveIcon from "@material-ui/icons/Save";
// import DeleteIcon from "@material-ui/icons/Delete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Container, Paper } from "./styles";

import Loading from "../../components/Loading";

import Usuarios from '../../services/Usuarios';
import Menus from '../../services/Menus';

import { useAuth } from '../../AuthContext';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const EditaUsuario = (props) => {
    const { user } = useAuth();
    const { id } = useParams();
    const { register, handleSubmit } = useForm();
    const [tab, setTab] = useState(0);
    const [usuario, setUsuario] = useState({});
    const [menus, setMenus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [responseStatus, setResponseStatus] = useState({
        open: false,
        severity: "success"
    })
    const [openActionMenu, setOpenActionMenu] = useState(false);

    const handleChangeTab = (event, newTab) => {
        setTab(newTab);
    };

    const saveForm = () => {
        setOpenActionMenu(false);
        setLoading(true);

        if (id === 'new') {
            Usuarios.create(usuario).then(response => {
                Usuarios.retrieve(response.id).then(result => {
                    setLoading(false);
                    window.location.href = '/usuarios/' + result._id;
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao realizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        } else {
            Usuarios.update(usuario._id, usuario).then(response => {
                setResponseStatus({
                    open: true,
                    severity: "success",
                    message: "Cadastro atualizado com sucesso"
                });

                Usuarios.retrieve(response._id).then(result => {
                    window.location.href = '/usuarios/' + result._id;
                }).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
                setResponseStatus({
                    open: true,
                    severity: "error",
                    message: (error.message ? "Erro:" + error.message : "Ocorreu um problema ao atualizar o cadastro. Tente novamente")
                });
                setLoading(false);
            });
        }
    }

    const handleCloseActionMenu = () => {
        setOpenActionMenu(false);
    };

    const handleOpenActionMenu = () => {
        setOpenActionMenu(true);
    };

    const handleToggleSwitch = (event) => {
        let updateUsuario = { ...usuario };
        updateUsuario[event.target.name] = !updateUsuario[event.target.name];
        setUsuario(updateUsuario);
    };

    const handleChangeField = (event) => {
        let updateUsuario = { ...usuario };
        updateUsuario[event.target.name] = event.target.value;
        setUsuario(updateUsuario);
    };

    const handleCloseResponseStatus = () => {
        setResponseStatus({
            open: false,
            severity: "success"
        })
    };

    const handleChangePermissao = (event) => {
        const updateUsuario = { ...usuario };
        updateUsuario.permissoes[event.target.name] = event.target.checked;
        setUsuario(updateUsuario);
    }

    const handleChangeSelect = (event) => {
        const updateUsuario = { ...usuario };
        updateUsuario.permissoes[event.target.name] = event.target.value;
        setUsuario(updateUsuario);
    }

    useEffect(() => {
        sessionStorage.setItem("@stk/appTitle", "Editar usuário");

        Menus.list("?ativo=true").then(result => {
            setMenus(result.docs);
        }).catch(error => {
            console.log(error);
        })

        if (id === 'new') {
            setUsuario({
                permissoes: {}
            });
            setLoading(false);
        } else {
            Usuarios.retrieve(id).then((response) => {
                setUsuario({
                    ...response
                });
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [id, user]);

    const habilitado = (permissao, titulo, index) => {
        if (permissao.tipo === 'switch') {
            return (
                <Grid item xs={12} key={"permissao-" + titulo + "-" + index}>
                    <FormControlLabel
                        control={
                            <Switch
                                name={titulo}
                                color="primary"
                                checked={!!usuario.permissoes[titulo]}
                            />
                        }
                        label={permissao.nome}
                        onChange={handleChangePermissao}
                    />
                </Grid>
            )
        }
    }
    const permissao = (permissao, index) => {
        if (permissao.tipo === 'select') {
            return (
                <Fragment key={"permissao-" + permissao.nome + "-" + index}>
                    <ListItem>
                        <ListItemText size="small">{permissao.nome}</ListItemText>
                        <ListItemText>
                            <FormControl fullWidth size="small">
                                <Select
                                    name={permissao.nome.replace(/[" "]/gi, "-")}
                                    labelid="valores-label"
                                    size="small"
                                    value={usuario.permissoes[permissao.nome.replace(/[" "]/gi, "-")]}
                                    onChange={handleChangeSelect}
                                    fullWidth
                                >
                                    {permissao.opcoes.map(opcao => <MenuItem key={opcao.value} value={opcao.value}>{opcao.label}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                </Fragment>
            )
        }

        if (permissao.tipo === 'check') {
            return (
                <Fragment key={"permissao-" + permissao.nome + "-" + index}>
                    <ListItem>
                        <ListItemText style={{ width: '200px', paddingRight: '30px', overflowWrap: 'break-word' }}>{permissao.nome}</ListItemText>
                        <ListItemText>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox name={permissao.nome.replace(/[" "]/gi, "-")} color="primary" checked={!!usuario.permissoes[permissao.nome.replace(/[" "]/gi, "-")]} />}
                                    onChange={handleChangePermissao}
                                />
                            </FormGroup>
                        </ListItemText>
                    </ListItem>
                    <Divider />
                </Fragment>
            )
        }
    }

    const subMenu = (pai) => (
        <Grid item xs={12}>
            <List style={{ width: '100%' }}>
                {pai?.acoes?.map((acao, index) => permissao(acao))}
                {menus?.filter(x => x.pai === pai._id).map((menu, index) => (
                    <Fragment key={"submenu-" + menu.titulo + "-" + index}>
                        <ListItem>
                            <ListItemText style={{ width: '200px', paddingRight: '30px', overflowWrap: 'break-word' }}>{menu.titulo}</ListItemText>
                            <ListItemText>
                                <FormGroup row>
                                    <FormControlLabel
                                        checked={!!usuario.permissoes[menu.titulo.replace(/[" "]/gi, "-") + "-visualizar"]}
                                        control={<Checkbox name={menu.titulo.replace(/[" "]/gi, "-") + "-visualizar"} color="primary" />}
                                        label="Visualizar"
                                        onChange={handleChangePermissao}
                                    />
                                    <FormControlLabel
                                        checked={!!usuario.permissoes[menu.titulo.replace(/[" "]/gi, "-") + "-incluir"]}
                                        control={<Checkbox name={menu.titulo.replace(/[" "]/gi, "-") + "-incluir"} color="primary" />}
                                        label="Incluir"
                                        onChange={handleChangePermissao}
                                    />
                                    <FormControlLabel
                                        checked={!!usuario.permissoes[menu.titulo.replace(/[" "]/gi, "-") + "-alterar"]}
                                        control={<Checkbox name={menu.titulo.replace(/[" "]/gi, "-") + "-alterar"} color="primary" />}
                                        label="Alterar"
                                        onChange={handleChangePermissao}
                                    />
                                    <FormControlLabel
                                        checked={!!usuario.permissoes[menu.titulo.replace(/[" "]/gi, "-") + "-excluir"]}
                                        control={<Checkbox name={menu.titulo.replace(/[" "]/gi, "-") + "-excluir"} color="primary" />}
                                        label="Excluir"
                                        onChange={handleChangePermissao}
                                    />
                                    {menu?.acoes?.map((acao, index) => permissao(acao, menu.titulo.replace(/[" "]/gi, "-")))}
                                </FormGroup>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                    </Fragment>
                ))}
            </List>
        </Grid>
    )

    return !loading ? (
        <Container>
            <Paper square >
                <form autoComplete="off" id="formEdit" onSubmit={handleSubmit(saveForm)}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChangeTab}
                        aria-label="Editar usuários"
                    >
                        <Tab label="Dados Pessoais" {...a11yProps(0)} />
                        <Tab label="Acessos" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={usuario?.ativo}
                                            onChange={handleToggleSwitch}
                                            name="ativo"
                                            color="primary"
                                        />
                                    }
                                    label="Ativo"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="usuario"
                                    label="Usuário"
                                    size="small"
                                    defaultValue={usuario.usuario}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="senha"
                                    label="Senha"
                                    size="small"
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="nome"
                                    label="Nome"
                                    size="small"
                                    defaultValue={usuario.nome}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="email"
                                    label="E-Mail"
                                    size="small"
                                    defaultValue={usuario.email}
                                    fullWidth
                                    onChange={handleChangeField}
                                    inputRef={register({ required: false })}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        {menus?.filter(x => x.pai === null).map((menu, index) => (
                            <Accordion key={"menu-" + menu.titulo + "-" + index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>{menu.titulo}</AccordionSummary>
                                <AccordionDetails style={{ overflow: 'auto' }}>
                                    <Grid container spacing={1}>
                                        {menu?.acoes?.map((acao, index) => habilitado(acao, menu.titulo))}
                                        {subMenu(menu)}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </TabPanel>
                    {user?.permissoes["Usuários-alterar"] && (
                        <SpeedDial
                            ariaLabel="Menu de ações"
                            icon={<SpeedDialIcon />}
                            onClose={handleCloseActionMenu}
                            onOpen={handleOpenActionMenu}
                            open={openActionMenu}
                            direction="up"
                            style={{
                                position: 'fixed',
                                right: '20px',
                                bottom: '20px'
                            }}
                        >
                            <SpeedDialAction
                                key={1}
                                icon={<SaveIcon />}
                                tooltipTitle="Salvar"
                                FabProps={{
                                    type: "submit"
                                }}

                            />
                        </SpeedDial>
                    )}
                </form>
            </Paper>
            <Snackbar open={responseStatus.open} autoHideDuration={3000} onClose={handleCloseResponseStatus}>
                <Alert elevation={6} variant="filled" onClose={handleCloseResponseStatus} severity={responseStatus.severity}>
                    {responseStatus.message}
                </Alert>
            </Snackbar>
        </Container>
    ) :
        (loading && (
            <Loading open={loading} />
        ))
};

export default EditaUsuario;